import React, { useState } from "react";
import frame from "../../assets/images/frame-back.jpg";
import look from "../../assets/images/7718877.jpg";
import Trace from "../../assets/images/rm429-071.jpg";
import compare from "../../assets/images/compare.png";
import oval from "../../assets/images/oval/D-EZ-face-illustrations-oval.gif";
import round from "../../assets/images/round/C-EZ-face-illustrations-round.gif";
import heart from "../../assets/images/heart/F-EZ-face-illustrations-heart.gif";
import square from "../../assets/images/square/E-EZ-face-illustrations-square-1.gif";
import { FrameData } from "../FindFrameData/FrameData";

const FindYourFrame = () => {
  const [faceShape, setFaceShape] = useState("Oval");
  let filterData = FrameData.filter((frame) => frame.type === faceShape);
  return (
    <>
      <div className="banner-wrap">
        <div className="img-wrapper">
          <img src={frame} alt="Perfect Shape" title="Perfect Shape" />
        </div>
        <div className="text-overlay">
          <div className="text-wrap">
            <h1>FIND YOUR PERFECT FRAME</h1>
            <p>Choose The Best Frame According To Face Shape & Size</p>
          </div>
        </div>
      </div>
      <div className="content-wrapper-section">
        <div className="header-section">
          <h2>Don't Know Your Face Shape?</h2>
          <p>
            Let's Help You Find That Frame Which Will Take Your Style Up A Few
            Notches!
          </p>
        </div>
        <div className="main-content-wrap">
          <p>
            Often People Find Themselves In Huge Favor Of A Frame However When
            They Put Them On, It Doesn't Suit Them At All. Don't Let That Happen
            To You. Your Face Shape Plays A Very Important Role In Determining
            Which Frame Will Suit You The Most. Here Are Three Easy Steps To
            Know Your Face Shape.{" "}
          </p>
          <p>Things You'll Need - Mirror & Dry-Erase Marker</p>
        </div>

        <div className="face-block-wrapper">
          <div className="face-box">
            <div className="img-wrap">
              <img src={look} alt="Look" title="Look" />
            </div>
            <div className="text-wrap">
              <h4>LOOK</h4>
              <p>
                Tie Your Hair With A Scarf Or A Handkerchief Before This
                Experiment And Stand In Front Of A Mirror. Make Sure You Do Not
                Move Your Face.
              </p>
            </div>
          </div>

          <div className="face-box">
            <div className="img-wrap">
              <img src={Trace} alt="Trace" title="Trace" />
            </div>
            <div className="text-wrap">
              <h4>TRACE</h4>
              <p>
                Slowly Trace The Outline Of Your Reflection With The Dry-Erase
                Marker. You Can Take Re-Trace The Same Later, On A Transparent
                Paper.
              </p>
            </div>
          </div>

          <div className="face-box">
            <div className="img-wrap">
              <img src={compare} alt="Compare" title="Compare" />
            </div>
            <div className="text-wrap">
              <h4>COMPARE</h4>
              <p>
                Now Observe Closely And Compare Your Tracings To The Below
                Mentioned Face-Shapes. You Can Also Take Help Of A Friend Here.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* find-you match section tabs  */}
      <div className="match-tab-section">
        <div className="heading-wrap">
          <h2>Find Your Match</h2>
        </div>

        <div className="faq-account-action justify-content-center align-items-center frame-finder-shapes-container">
          <div className="m-0 p-0">
            <div
              className="nav flex-row nav-pills m-0 frame-finder-shapes"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <a
                className="nav-link active"
                id="q1-tab"
                data-toggle="pill"
                href="#q1"
                role="tab"
                aria-controls="q1"
                aria-selected="true"
                onClick={() => setFaceShape("Oval")}
              >
                <div className="img-tab-text">
                  <img src={oval} alt="" />
                </div>
                <h4 className="shape-text">Oval</h4>
              </a>
              <a
                className="nav-link"
                id="q2-tab"
                data-toggle="pill"
                href="#q2"
                role="tab"
                aria-controls="q2"
                aria-selected="false"
                onClick={() => setFaceShape("Round")}
              >
                <div className="img-tab-text">
                  <img src={round} alt="" />
                </div>
                <h4 className="shape-text">Round</h4>
              </a>
              <a
                className="nav-link"
                id="q3-tab"
                data-toggle="pill"
                href="#q3"
                role="tab"
                aria-controls="q3"
                aria-selected="false"
                onClick={() => setFaceShape("Heart")}
              >
                <div className="img-tab-text">
                  <img src={heart} alt="" />
                </div>
                <h4 className="shape-text">Heart</h4>
              </a>
              <a
                className="nav-link"
                id="q4-tab"
                data-toggle="pill"
                href="#q4"
                role="tab"
                aria-controls="q4"
                aria-selected="false"
                onClick={() => setFaceShape("Square")}
              >
                <div className="img-tab-text">
                  <img src={square} alt="" />
                </div>
                <h4 className="shape-text">Square</h4>
              </a>
            </div>
          </div>
        </div>
        <div className="frame-finder-content">
          <div className="tab-content" id="v-pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="q1"
              role="tabpanel"
              aria-labelledby="q1-tab"
            >
              <div className="inner-tab">
                <h4 className="shape-text">{filterData[0].type} Shape</h4>
                <p>{filterData[0].description}</p>
                <div className="sub-head-style">
                  <h4>Suggested Frames</h4>
                </div>
                <div className="suggest-list">
                  <div className="frame-finder-suggested-images-container" >
                    {filterData[0].images.map((image, index) => (
                      <div
                        key={index}
                        className="frame-finder-suggested-images"
                      >
                        <img src={image} alt="" />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FindYourFrame;
