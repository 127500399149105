import React, { Component, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import ReactGA from 'react-ga';
// Utils Import
import { isLogin } from "../../utils/index";
// Import Layout
import MainLayout from "../layouts/MainLayout";
// Components Import
import Homepage from "../HomePage/HomePage";
import AboutUs from "../pages/AboutUs";
import Profile from "../MyAccount/Profile";
import MyOrders from "../MyAccount/MyOrders";
import ManageAddress from "../MyAccount/ManageAddress";
import PaymentOption from "../MyAccount/PaymentOption";
import EliteMembership from "../helper/EliteMembership";
import FindYourFrame from "../helper/FindYourFrame";
import StoreLocator from "../helper/StoreLocator";
import ProductListContainer from "../helper/ProductListContainer";
import SingleProduct from "../helper/SingleProduct";
import Cart from "../helper/Cart";
import Career from "../helper/Career";
import Membership from "../helper/Mebership";
import SearchResult from "../helper/SearchResult";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Disclaimer from "../pages/Disclaimer";
import TermsCondition from "../pages/TermsCondition";
import NotFoundIndex from "../NotFound/NotFoundIndex";
import Faqs from "../helper/Faqs";
import Blog from "../helper/Blog";
import BlogDetails from "../helper/BlogDetails";
import UploadPrescription from "../MyAccount/UploadPrescription";
import TrackOrder from "../MyAccount/TrackOrder";
import Login from "../Login/Login";
import ApplyFranchise from "../pages/ApplyFranchise";
import OrderNowModal from "../helper/OrderNowModal";
// Styles Import
import "../../assets/css/style.css";
import "../../assets/css/font-awesome.min.css";
import ShippingPolicy from "../pages/ShippingPolicy";
import RefundPolicy from "../pages/RefundPolicy";

const queryClient = new QueryClient();
ReactGA.initialize(process.env.TRACKING_ID);
let historyListener;

const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? (
          <Layout>
            <Component {...props} {...rest} />
          </Layout>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

const TrackingRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const location = useLocation();
  const [openLogin, setOpenLogin] = useState(true);
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        isLogin() || !location.search?.split("=") === "true" ? (
          <Layout>
            <Component {...props} {...rest} />
          </Layout>
        ) : 
        !location.search?.split("=") === "false" ?
        (
          <Login openLogin={openLogin} trackUrl={location.pathname} isTracking={true} onLoginClose={() => {
            setOpenLogin(false);
          }} />
        )
        :
        (
          <OrderNowModal  />
        )
      }
    />
  );
};

const PublicRoute = ({
  component: Component,
  layout: Layout,
  restricted,
  ...rest
}) => {
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={(props) =>
        isLogin() && restricted ? (
          <Redirect to="/" />
        ) : (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }
    />
  );
};

class BaseApp extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    historyListener && historyListener();
    historyListener = props.history.listen((location, action) => {
      this.sendEventToGA(location);
    });
  }

  sendEventToGA = (location) => {
    if (process.env.NODE_ENV === "development") {
      return;
    }
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  };

  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <Router>
          <Switch>
            <PublicRoute
              restricted={false}
              component={Homepage}
              layout={MainLayout}
              path="/"
              exact
            />
            <PublicRoute
              restricted={false}
              component={AboutUs}
              path="/about-us"
              layout={MainLayout}
              exact
            />
            <PrivateRoute
              component={MyOrders}
              layout={MainLayout}
              path="/my-account"
              exact
            />
            <PrivateRoute
              component={Profile}
              layout={MainLayout}
              path="/profile"
              exact
              isTracking={true}
            />
            <PrivateRoute
              component={ManageAddress}
              layout={MainLayout}
              path="/address"
              exact
              isTracking={true}
            />
            <PrivateRoute
              component={PaymentOption}
              layout={MainLayout}
              path="/payment-option"
              exact
            />
            <PrivateRoute
              component={UploadPrescription}
              layout={MainLayout}
              // path="/upload-prescription/:id"
              path="/upload-prescription/:oid/:pid"
              exact
              isTracking={true}
            />
            <TrackingRoute
              component={TrackOrder}
              layout={MainLayout}
              path="/track-order/:id"
              exact
              isTracking={true}
            />
            <PublicRoute
              restricted={false}
              component={FindYourFrame}
              layout={MainLayout}
              path="/find-your-frame"
              exact
            />
            <PublicRoute
              restricted={false}
              component={EliteMembership}
              layout={MainLayout}
              path="/elite-membership"
              exact
            />
            <PublicRoute
              restricted={false}
              component={ProductListContainer}
              path="/product-list/:category"
              layout={MainLayout}
              exact
            />
            <PublicRoute
              restricted={false}
              component={SearchResult}
              path="/search"
              layout={MainLayout}
              exact
            />
            <PublicRoute
              restricted={false}
              component={SingleProduct}
              path="/product/:productId"
              layout={MainLayout}
              exact
            />
            <PublicRoute
              restricted={false}
              component={Cart}
              path="/cart"
              layout={MainLayout}
              exact
            />
            <PublicRoute
              restricted={false}
              component={Faqs}
              path="/faqs"
              layout={MainLayout}
              exact
            />
            <PublicRoute
              restricted={false}
              component={Blog}
              path="/blog"
              layout={MainLayout}
              exact
            />
            <PublicRoute
              restricted={false}
              component={BlogDetails}
              path="/blog/:id"
              layout={MainLayout}
              exact
            />
            <PublicRoute
              restricted={false}
              component={StoreLocator}
              layout={MainLayout}
              path="/store-locator"
              exact
            />
            <PublicRoute
              restricted={false}
              component={Career}
              layout={MainLayout}
              path="/career"
              exact
            />
            <PrivateRoute
              component={Membership}
              layout={MainLayout}
              path="/join-membership"
              exact
            />
            <PublicRoute
              restricted={false}
              component={PrivacyPolicy}
              layout={MainLayout}
              path="/privacy-policy"
              exact
            />
            <PublicRoute
              restricted={false}
              component={Disclaimer}
              layout={MainLayout}
              path="/disclaimer"
              exact
            />
            <PublicRoute
              restricted={false}
              component={TermsCondition}
              layout={MainLayout}
              path="/terms-conditions"
              exact
            />
            <PublicRoute
              restricted={false}
              component={ShippingPolicy}
              layout={MainLayout}
              path="/shipping-policy"
              exact
            />
            <PublicRoute
              restricted={false}
              component={RefundPolicy}
              layout={MainLayout}
              path="/refund-policy"
              exact
            />
            <TrackingRoute
              component={MyOrders}
              layout={MainLayout}
              path="/guest-orders"
              exact
              isTracking={true}
            />
            <PublicRoute
              restricted={false}
              component={ApplyFranchise}
              layout={MainLayout}
              path='/apply-franchise'
              exact
            />
            <Route path="*" component={NotFoundIndex} />
          </Switch>
        </Router>
      </QueryClientProvider>
    );
  }
}

export default BaseApp;
