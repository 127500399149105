import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MyAccountTab from "./MyAccountTab";
import profile from "../../assets/images/profile.svg";
import { fetchUserProfileStart } from "../../store/actions/UserAction";
import { createNotification } from "react-redux-notify";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/NotificationMessage";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ApiMethods from '../../utils/ApiMethods'

const initialState = {
  email: "",
  firstName: "",
  LastName: "",
  Gender: "",
  DOB: new Date(),
  phone: "",
};

const Profile = (props) => {
  const { isTracking = false } = props;
  const Email = sessionStorage.getItem("email");
  const dispatch = useDispatch();
  const [{ email, firstName, LastName, Gender, DOB, phone }, setUserData] =
    useState(initialState);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    props.dispatch(fetchUserProfileStart({ email: Email }));
  }, []);

  const handleProfileChange = (event) => {
    const { name, value } = event.currentTarget;
    setUserData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleDateChange = (date) => {
    if (date && !isNaN(date.getTime())) {
      const dates = moment(date).toDate();
      setUserData((prevState) => ({ ...prevState, DOB: dates }));
    } else {
      setUserData((prevState) => ({ ...prevState, DOB: null }));
    }
  };

  const handleGenderChange = (val) => {
    setUserData((prevState) => ({ ...prevState, Gender: val }))
  }

  const handleUpdateDetails = async (e) => {
    e.preventDefault();
    if (!email || !firstName || !LastName || !Gender || !DOB || !phone) {
      alert("Please Fill all Details.")
    }
    const result = await ApiMethods.postMethod('user/edit', {
      email: email,
      firstName: firstName,
      LastName: LastName,
      Gender: Gender ? Gender : '',
      DOB: DOB ? moment(DOB).format('DD-MM-YYYY') : '',
      phone: phone,
      profile: 'as'
    });
    if (result.data?.success) {
      const notificationMessage = getSuccessNotificationMessage(result.data.messgae);
      await props.dispatch(createNotification(notificationMessage));
      const fullName = firstName + " " + LastName;
      sessionStorage.setItem("name", fullName);
      window.location.reload();
    } else {
      const errorMessages = Object.values(result.data?.errors).flat() || [];
      errorMessages.forEach((error) => {
        const notificationMessage = getErrorNotificationMessage(error);
        props.dispatch(createNotification(notificationMessage));
      });
      // const notificationMessage = getErrorNotificationMessage(result.data.errors.LastName[0]);
      // props.dispatch(createNotification(notificationMessage));
    }
  }

  useEffect(() => {
    if (props.userProfile.data && props.userProfile.data.length > 0) {
      const userProfileData = props.userProfile.data[0];
      setUserData({
        email: userProfileData.email || "",
        firstName: userProfileData.firstName || "",
        LastName: userProfileData.LastName || "",
        Gender: userProfileData.Gender || "",
        DOB: userProfileData.DOB ? moment(userProfileData.DOB, 'DD-MM-YYYY').toDate() : null,
        phone: userProfileData.phone || "",
      });
      setIsLoading(false);
    }
  }, [props.userProfile.data]);
  if (isLoading) {
    return <div>Loading...</div>;
  }


  return (
    <div className="my-account page-pdding">
      <div className="top-account-bar text-center">
        <h4>My Account</h4>
      </div>
      <div className="product-listing">
        {!isTracking && <MyAccountTab />}
        <div className="product-content">
          <div className="account-header border-0">
            <div className="order-left">
              <img src={profile} alt="" />
              <h2>Profile Details</h2>
            </div>
          </div>
          <div className="profile-form">
            {!props.userProfile.loading && (
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={firstName}
                        name="firstName"
                        required
                        onChange={handleProfileChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={LastName}
                        name="LastName"
                        required
                        onChange={handleProfileChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label>Gender</label>
                    <div className="form-group gender-wrap gender-list">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="male"
                          name="men"
                          className="custom-control-input"
                          onChange={() => handleGenderChange('men')}
                          checked={Gender === 'men'}
                        />
                        <label className="custom-control-label">
                          Male
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="female"
                          name="women"
                          className="custom-control-input"
                          onChange={() => handleGenderChange('women')}
                          checked={Gender === 'women'}
                        />
                        <label className="custom-control-label">
                          Female
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="Other"
                          name="x"
                          className="custom-control-input"
                          onChange={() => handleGenderChange('x')}
                          checked={Gender === 'x'}
                        />
                        <label className="custom-control-label">
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Date of Birth</label>
                      <DatePicker
                        selected={DOB !== null ? DOB : null}
                        onChange={(date) => handleDateChange(date)}
                        dateFormat="dd-MM-yyyy"
                        placeholderText="Ex 01-02-2024"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Mobile Number</label>
                      <input
                        type="text"
                        className="form-control"
                        value={phone}
                        name="phone"
                        maxLength="10"
                        pattern="\d{10}"
                        title="Please enter exactly 10 digits"
                        required
                        onChange={handleProfileChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Email Address</label>
                      <input
                        type="text"
                        className="form-control"
                        disabled={true}
                        value={email}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 my-4">
                    <a style={{ cursor: 'pointer' }} onClick={handleUpdateDetails} className="blue-btn">
                      {" "}
                      Edit Details
                    </a>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  userProfile: state.users.userProfile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Profile);
