import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import logo from "../../assets/images/new-logo.svg";
import axios from "axios";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import whatsapp from "../../assets/images/whatsapp-icon.svg";
import { createNotification } from "react-redux-notify";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/NotificationMessage";

const OrderNowOtpModal = (props) => {
  const dispatch = useDispatch();
  const { openOtpModal, closeOtpModal } = props;
  const [otpVerficationInputData, setOtpVerificationInputData] = useState({
    otp: "",
  });

  const handleOptVerification = async (event) => {
    event && event.preventDefault();
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}user/verify-otp`,
      { phoneNumber: sessionStorage.getItem('otpNumber'), otp: otpVerficationInputData.otp  }
    );
    if (!otpVerficationInputData.otp) {
        return alert('Please Enter OTP')
    }
    if (response.data?.success) {
      const notificationMessage =
        getSuccessNotificationMessage("Otp verified");
      dispatch(createNotification(notificationMessage));
      closeOtpModal(true);
      window.location.reload();
    } else {
      return alert('OTP is not correct')
    }
  };
  return (
    <Modal
      className="custom-dialog"
      size="xl"
      centered
      backdrop="static"
      show={openOtpModal}
      onHide={closeOtpModal}
    >
      <div className="modal-content">
        <div className="modal-body">
          <div className="row m-0">
            <div className="col-md-5 p-0">
              <div className="modal-left">
                <div className="logo-wrap">
                  <a href="/">
                  <h4 style={{color:'white'}}>Chashma Planet</h4>
                    {/* <img
                      src={logo}
                      alt="Framskraft.com"
                      title="Framskraft.com"
                    /> */}
                  </a>
                </div>
                <div className="text-wrap">
                  <a href="https://api.whatsapp.com/send/?phone=919859424242&text&app_absent=0">
                    <h2>
                      Get Expert Help Now Available On{" "}
                      <span>
                        <img src={whatsapp} alt="whatsapp" />
                        Whatsapp{" "}
                      </span>
                    </h2>
                  </a>
                  <h3>Elite Membership Programme</h3>
                  <p>Get Special Offers And Benifits</p>
                  <a href="/elite-membership" alt="KNOW MORE" title="KNOW MORE">
                    KNOW MORE <i className="fas fa-chevron-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-7 p-0">
              <div className="modal-right">
                <div className="custom-form-wrapper">
                  <h4>Enter Otp</h4>
                  <form className="login-form" onSubmit={handleOptVerification}>
                    <div className="form-group otp-verfication-container">
                      <h5>Enter OTP</h5>
                      <OtpInput
                        value={otpVerficationInputData.otp}
                        onChange={(otp) => setOtpVerificationInputData({ otp })}
                        numInputs={4}
                        className="mx-2 otp-verification"
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary custom-btn"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OrderNowOtpModal;
