import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { connect, useDispatch } from "react-redux";
import axios from "axios";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
} from "../helper/NotificationMessage";
import MyAccountTab from "./MyAccountTab";
import editlocation from "../../assets/images/edit-location.svg";
import ApiMethods from "../../utils/ApiMethods";
import OrderCard from "./OrderCard";
import { useHistory, useLocation, withRouter } from "react-router-dom/cjs/react-router-dom.min";
import PrescriptionModal from "../Prescription"; // Import the new modal component

const UploadPrescription = (props) => {
  const { isTracking = false } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { oid: orderId } = props.match.params;
  const { pid: productId } = props.match.params;
  const [prescriptionInputData, setPrescriptionInputData] = useState({
    name: "",
    description: "",
  });
  const [fileName, setFileName] = useState("");
  const [prescription, setPrescription] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [presData, setPresData] = useState('') 
  const userId= sessionStorage.getItem('user_id');
  useEffect(async () => {
    // const res = await ApiMethods.getMethod(`user/get/prescriptions/${orderId}`);
    const res = await ApiMethods.getMethod(`user/get/prescriptions/${orderId}/${productId}`);
    
    setPresData(res?.data?.data?.filter((elem)=>elem?.productId===productId))

  }, [])

  const { isLoading, data } = useQuery("order", async () => {
    
    
    const res = await ApiMethods.getMethod(`user/get/order/${orderId}`);
    return res.data.data;
  });

  if (isLoading) return "loading...";

  const handlePrescriptionInputDataChange = (e) => {
    setPrescriptionInputData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFileChange = async (event) => {
    const fileType = [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
      "application/pdf",
      "image/*",
    ];
    if (event.target.files[0]) {
      if (!fileType.includes(event.target.files[0].type)) {
        alert("Only .pdf, .doc, and .docx Files are allowed.");
        return;
      }
      setFileName(event.target.files[0].name);
      let formData = new FormData();
      formData.append("images", event.target.files[0]);
      let result = await axios.post(
        `${process.env.REACT_APP_API_URL}admin/addPic`,
        formData
      );
      if (result.data.status) {
        setPrescription(result.data.data[0]);
      }
    }
  };

  const onSubmit = async (e) => {
    e && e.preventDefault();
    const res = await ApiMethods.postMethod("user/create/prescription", {
      ...prescriptionInputData,
      file: prescription,
      userId: sessionStorage.getItem("user_id"),
      orderId,
      productId
    });
    if (res.data?.success) {
      const notificationMessage = getSuccessNotificationMessage(
        "Prescription Uploaded Successfully"
      );
      dispatch(createNotification(notificationMessage));
      history.push("/my-account")

    }
    setFileName("");
    setPrescription("");
    setPrescriptionInputData({
      name: "",
      description: "",
    });
  };

  // const handleModalToggle = () => {
  //   setIsModalOpen(!isModalOpen);
  // };
  const handleModalToggle = () => {
    setIsModalOpen((prevState) => {
      if (!prevState) {
        document.body.classList.add("prescriptiondiv");
      } else {
        document.body.classList.remove("prescriptiondiv");
      }
      return !prevState;
    });
  };


  return (
    <div className="my-account page-pdding">
      <div className="top-account-bar text-center">
        <h4>My Account</h4>
      </div>
      <div className="product-listing">
        {!isTracking && <MyAccountTab />}
        <div className="product-content">
          <div className="account-header">
            <div className="order-left">
              <img src={editlocation} alt="" />
              <h2> Upload Prescription </h2>
            </div>
            {presData?.length > 0 && (
            <button onClick={handleModalToggle}>View Prescription</button>
            )}
          </div>
          
          <OrderCard order={{...data[0], 'orderedLens': data[0].orderedLens?.filter(item => item?.product?._id == productId)}} showUploadPrescription={false} presData={presData}/>
        
         
          {presData?.length === 0 && (
            <div className="prescription">
              <h4>Upload Prescription</h4>
              <form onSubmit={onSubmit}>
                <input
                  value={prescriptionInputData.name}
                  name="name"
                  onChange={handlePrescriptionInputDataChange}
                  required
                  placeholder="Full Name"
                />
                <textarea
                  value={prescriptionInputData.description}
                  onChange={handlePrescriptionInputDataChange}
                  name="description"
                  required
                  placeholder="Description"
                  rows={5}
                ></textarea>
                <div className="form-group custom-upload">
                  <div className="custom-file mb-5">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFile"
                      name="prescription"
                      onChange={handleFileChange}
                      required
                      placeholder="Upload Prescription"
                    />
                    <label
                      style={{ color: fileName.length && "#1E1E1E" }}
                      className={
                        "custom-file-label" +
                        (fileName.length ? " file-selected" : "")
                      }
                      htmlFor="customFile"
                    >
                      {fileName || "Upload Prescription"}
                    </label>
                  </div>
                </div>

                <button
                  disabled={!prescription}
                  type="submit"
                  className="btn btn-primary custom-btn"
                >
                  Send
                </button>
              </form>

            </div>
          )} 
        </div>
      </div>
      {isModalOpen && (
        <PrescriptionModal
          prescriptionData={prescriptionInputData}
          prescriptionFile={prescription}
          isModalOpen={isModalOpen}
          presData={presData}
          setIsModalOpen={setIsModalOpen}
          onClose={handleModalToggle}
        />
      )}
    </div>
  );
};

export default UploadPrescription;

