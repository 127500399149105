import "./content-page-style.css";

const RefundPolicy = () => {
  return (
    <div className="custom-paddingx c-page-content">
      <div className="c-content-wrap">
        <h1>Cancellations and Refund policy</h1>


        <p>
        We understand that sometimes things don't work out as planned. Here's a breakdown of our cancellation and refund policy to help you navigate returns and exchanges.
        </p>
        <h3>
          <strong>Cancellation Policy</strong>
        </h3>

        <p>
        Since our eyeglasses and sunglasses are custom-made to your exact specifications, we generally cannot accept returns or exchanges once your order has been processed. However, we do offer a straightforward cancellation policy:
        </p>
        <ul>
          <li><b>Before submitting a cancellation request, please ensure you've reviewed the terms and conditions of any offers applied to your order.</b></li>
          
        </ul>

        <h3>
          <strong>Return Policy</strong>
        </h3>

        <p>We want you to be happy with your Chashmaplanet purchase. If for any reason you're not satisfied with your eyeglasses or sunglasses, you may be eligible for a return or exchange under the following conditions:</p>

        <ul>
          <li>
          <b>Return/Exchange Window:</b> You have 14 days from the date of delivery to initiate a return or exchange request.
          </li>
          <li>
          <b>Condition of Returned Products:</b> Products must be returned in unused and original condition, with all tags, labels, and packaging intact.
          </li>
          <li><b>Items Not Eligible for Return/Exchange:</b> Due to hygiene reasons, we cannot accept returns or exchanges of opened or used contact lenses.</li>
        </ul>

        <h3>
          <strong>How to Initiate a Return or Exchange</strong>
        </h3>
        <ul>
          <li><b>Online:</b> Log in to your account, go to "My Orders," select the relevant order, and choose "Request Return" or "Request Exchange."</li>
          <li><b>Email:</b> Send your order details to help@chashmaplanet.com, clearly stating your request for return or exchange.</li>
        </ul>

        <h3>
          <strong>Return Process</strong>
        </h3>

<p>Once we receive your return request, we'll send you a confirmation email with instructions on how to return your order. Here's a general overview of the process:</p>

        <ul>
          <li><b>Pack the order securely:</b> Include all original accessories, invoice, and packaging materials.
          </li>
          <li><b>Schedule a pickup:</b> We'll arrange for a courier partner to pick up your return at your convenience.</li>
          <li><b>Return verification:</b> Once we receive your returned items and verify their condition, we'll initiate the refund or exchange process.</li>
        </ul>
        <h3>
          <strong>Refund Processing Time</strong>
        </h3>
        <p>
        It typically takes <b>5-7 business days</b> to process a refund after we receive your returned items. You'll receive the refund in the original payment method used for your purchase.
        </p>
        <h3>
          <strong>Damaged or Incorrect Items</strong>
        </h3>
        <p>
        If you receive a damaged or incorrect item, please contact our customer service team within <b>24 hours</b> of delivery at help@chashmaplanet.com. We'll arrange for a prompt pickup and replacement or issue a full refund, including any shipping charges.
        </p>
        <h3>
          <strong>Contact Lenses</strong>
        </h3>
        <p>Contact lenses can only be returned if:</p>
        <ul>
          <li>The packaging and lenses remain sealed and unused.</li>
          <li>All accessories, warranty cards, and instruction manuals are intact.</li>
        </ul>
        <p><b>Please note:</b> Opened or tampered contact lens packaging is not eligible for return or refund.</p>
        <h3>
          <strong>Additional Information</strong>
        </h3>
        <ul>
          <li>The entire return process typically takes <b>10-15 business days,</b> including verification, inspection, and refund initiation.</li>
          <li>Chashmaplanet reserves the right to reject any return or exchange request that does not comply with this policy.</li>
        </ul>
        <p>We encourage you to review this policy thoroughly before making a purchase. If you have any questions, please don't hesitate to contact our customer service team at help@chashmaplanet.com.</p>
      </div>
    </div>
  );
};

export default RefundPolicy;
