/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "../pages/ApplyFranchise.css";
import { fetchHomePageStart } from "../../store/actions/HomeAction";
import { clientSays, fetchBannerData, fetchVideoLink } from "../../utils/index";
import Eyeglasses from "../../assets/images/Eyeglasses.png";
import sunglasses from "../../assets/images/sunglasses.png";
import contactlens from "../../assets/images/contactlens.png";
import readingglasses from "../../assets/images/readingglasses.png";
import computerglass from "../../assets/images/Computer Glasses.png";
import accesories from "../../assets/images/accesories.png";
import whatsapp from "../../assets/images/newban2.png";
import customerSupport from "../../assets/images/newban3.png";
import member from "../../assets/images/elit-member-new.jpg";
import man from "../../assets/images/shop-man.png";
import kid from "../../assets/images/shop-kid.png";
import woman from "../../assets/images/shop-woman.png";
import framebg from "../../assets/images/frame-bg.png";
import brand1 from "../../assets/images/brand-logo/carrera.png";
import brand2 from "../../assets/images/brand-logo/crizal.png";
import brand3 from "../../assets/images/brand-logo/essilor.png";
import brand4 from "../../assets/images/brand-logo/gucci.png";
import brand5 from "../../assets/images/brand-logo/hoya.png";
import brand6 from "../../assets/images/brand-logo/mont-blanc.png";
import brand7 from "../../assets/images/brand-logo/prada.png";
import brand8 from "../../assets/images/brand-logo/rayban.png";
import brand9 from "../../assets/images/brand-logo/tedwoods.png";
import brand10 from "../../assets/images/brand-logo/varilux.png";
import brand11 from "../../assets/images/brand-logo/vogue.png";
import brand12 from "../../assets/images/brand-logo/zeiss.png";
// import banner2 from "../../assets/images/banner2.png";
import Group from "..//../assets/images/banner-bg (1).png";
import findframeimg from "../../assets/images/findframeimg.png";
import MySlider from "../helper/MySlider";
import Slider from "react-slick";
import eyewear from "../../assets/images/newban1.png";
import MediaCard from "./Framecard";
import MediaCardClient from "./Clientcards";
import avi from "../../assets/images/avi1.png";
import cute from "../../assets/images/cute.png";
import banner_img_1 from "../../assets/images/kalu.png";
import imagen1 from "../../assets/images/img-tab1.png";
import imagen2 from "../../assets/images/img-tab2.png";
import imagen3 from "../../assets/images/img-tab3.png";
import chasmaimg from "../../assets/images/banner-chashma-ns.png";

const brandImages = [
  brand1,
  brand2,
  brand3,
  brand4,
  brand5,
  brand6,
  brand7,
  brand8,
  brand9,
  brand10,
  brand11,
  brand12,
];

const sliderProps = {
  arrows: false,
  infinite: true,
  speed: 500,
  loop: true,
  autoplay: true,
  slidesToShow: 4,
  fade: false,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const testimonialProps = {
  arrows: false,
  infinite: true,
  speed: 500,
  loop: true,
  autoplay: true,
  slidesToShow: 4,
  fade: false,
  adaptiveHeight: true,
  autoplaySpeed: 4000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const bannerProps = {
  arrows: false,
  infinite: true,
  dots: false,
  speed: 500,
  loop: true,
  autoplay: true,
  slidesToShow: 1,
  fade: false,
  autoplaySpeed: 4000,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const HomePage = (props) => {
  const [clientData, setClientData] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [videoLink, setVideoLink] = useState([]);

  const fetchLinks = async () => {
    const { data, error } = await fetchVideoLink();
    setVideoLink([...data.data]);
  };

  const fetchWhatClientSays = async () => {
    const { data, error } = await clientSays();
    setClientData([...data.data]);
  };

  const getBannerData = async () => {
    const { data, error } = await fetchBannerData();
    setBannerData([...data.data]);
  };
  useEffect(() => {
    getBannerData();
    props.dispatch(fetchHomePageStart());
    fetchWhatClientSays();
    fetchLinks();
  }, []);
  return (
    <>
      <div className="hero-banner">
        <div className="banner">
          {/* {bannerData.length > 0 && (
            <Slider {...bannerProps}>
              {bannerData.map((data) => (
                <img key={data._id} src={data.image} alt="" />
              ))}
            </Slider>
          )} */}
          {/* <img src="/src/assets/images/xqvinc1y.png"/> */}
          {/* <img src="/home/shanu/ChashmaplanetFrontend-master/src/assets/images/xqvinc1y.png" alt="" /> */}
          <div className="row">
    <div className="col-lg-4 col-md-6 col-sm-9 zoom-on-hover">
        <div className="glass-wrap">
            <h4 className="hdng-fifty">
                <span>Flat 50%</span>
            </h4>
            <h5>
                On All Bluecut <br /> Glasses
            </h5>
            <div className="shop-now mt-3">
                <a href="/product-list/Computer Glasses">Shop Now</a>
            </div>
        </div>
    </div>
</div>

        </div>
      </div>
      <div className="top-cartigory-slider">
        <div className="catigory-wrap cat-bg1 banner-hover-effect-cat">
          <a href="/product-list/Eyeglasses">
            <img src={Eyeglasses} alt="" />
            <span>Eye Glasses</span>
          </a>
        </div>
        <div className="catigory-wrap cat-bg2 banner-hover-effect-cat">
          <a href="/product-list/Reading Glasses">
            <img src={readingglasses} alt="" />
            <span>Reading Glasses</span>
          </a>
        </div>
        <div className="catigory-wrap cat-bg3 banner-hover-effect-cat">
          <a href="/product-list/Sunglasses">
            <img src={sunglasses} alt="" />
            <span>Sun Glasses</span>
          </a>
        </div>
        <div className="catigory-wrap cat-bg4 banner-hover-effect-cat">
          <a href="/product-list/Computer Glasses">
            <img src={computerglass} alt="" />
            <span>Computer Glasses</span>
          </a>
        </div>
        <div className="catigory-wrap cat-bg5 banner-hover-effect-cat">
          <a href="/product-list/Contact Lens">
            <img src={contactlens} alt="" />
            <span>Contact Lens</span>
          </a>
        </div>
        <div className="catigory-wrap cat-bg6 banner-hover-effect-cat">
          <a href="/product-list/Accessories">
            <img src={accesories} alt="" />
            <span>Accessories</span>
          </a>
        </div>
      </div>

      <div className="custom-paddingx">
        <div className="row pt-4 pb-3">
          {/* <div className="col-md-6 py-2 col-lg-3">
            <div className="banner banner-hover-effect">
              <a href="tel:9859424242" target="_blank" rel="noreferrer">
                {" "}
                <img src={customerSupport} alt="" />
              </a>
            </div>
          </div>
          <div className="col-md-6 py-2 col-lg-3">
            <div className="banner banner-hover-effect">
              <a
                href="https://api.whatsapp.com/send/?phone=919859424242&text&app_absent=0"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img src={whatsapp} alt="" />
              </a>
            </div>
          </div> */}
          <div className="col-md-6  zoom-container py-2 ">
            <div className="banner banner-hover-effect banner-img2">
              {/* <img src={cute} alt="" /> */}
              <div className="row">
                <div className="col-md-6"></div>

                <div className="col-md-6">
                  <div className="sunglass-col">
                    <h4 className="sunglass-text">
                      Sunglasses <br />
                      Starting
                    </h4>
                    <p className="price"> @299</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 zoom-container py-2">
            <div className="banner banner-hover-effect banner-img1">
              <div className="row">
                <div className="col-md-7">
                  <div className="sunglass-col">
                    <h4 className="sunglass-text1">
                      No More Harm <br />
                      to your Eyes with
                    </h4>
                    <p className="price1">
                      {" "}
                      OUR <br /> Bluecut <br />
                      Glasses
                    </p>
                    <div className="shop-now ">
                      <a href="/product-list/Computer Glasses">Shop Now</a>
                    </div>
                    {/* <button className="shop-now">Shop Now</button> */}
                  </div>
                  <div className="col-md-5"></div>
                </div>
                {/* <a href="/elite-membership"> */}{" "}
                {/* <img src={banner_img_1} alt="" /> */}
                {/* </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="custom-paddingx">
        <div className="row margin-8">
          <div className="col-md-4 py-2 px-4 ">
            <div className="banner-back">
              <div className="banner banner-hover-effect person-text">
                <a href="/product-list/men">
                  {" "}
                  <img src={imagen3} alt="" />
                </a>
                <span>Shop for Men</span>
              </div>
            </div>
          </div>
          <div className="col-md-4 py-2 px-4 ">
            <div className="banner-back">
              <div className="banner banner-hover-effect person-text">
                <a href="/product-list/kids">
                  {" "}
                  <img src={imagen2} alt="" />
                </a>
                <span>Shop for Kids</span>
              </div>
            </div>
          </div>
          <div className="col-md-4 py-2 px-4 ">
            <div className="banner-back">
              <div className="banner banner-hover-effect person-text">
                <a href="/product-list/women">
                  {" "}
                  <img src={imagen1} alt="" />
                </a>
                <span>Shop for Women</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="custom-paddingx">
        <div className="section-title-wrap text-center">
          <h2>
            Classy With Glasses
            {/* Trending & <span>BestSeller</span> */}
          </h2>
          <div className="row">
            <div className="col-md-3">
              <div className="square-wrapp">
                <div className="inner-imgwrap"></div>
                <div className="content-inner">
                  <div className="stra-wrap"></div>
                  {/* <h6>Tedwoods Square Transparent Tortoise</h6> */}
                  <div className="buy-wrap"></div>
                </div>
              </div>
            </div>
          </div>

          <h4>
            <span>EYEGLASSES STARTING AT ₹499</span>
          </h4>
        </div>
        <div className="p-slider-type1 card-p">
          {/* <a className="view-all-link" href="/product-list/Eyeglasses">
            View All
          </a> */}
          <div className="row every-item">
            <MediaCard className="" />
          </div>
          {/* {props.homeData.data?.Eye_Glass_With_Trending &&
            props.homeData.data.Eye_Glass_With_Trending.length > 0 && (
              <MySlider
                Slidedata={props.homeData.data.Eye_Glass_With_Trending}
              />
            )} */}
        </div>
      </section>
      <section className="custom-paddingx mt-3 mb-5">
        {/* <div className="section-title-wrap text-center">
          <h4>
            <span>Sun Glasses</span>
          </h4>
        </div> */}
        {/* <div className="p-slider-type1">
          <a className="view-all-link" href="/product-list/Sunglasses">
            View All
          </a>
          {props.homeData.data?.Sun_Glass_With_Trending &&
            props.homeData.data.Sun_Glass_With_Trending.length > 0 && (
              <MySlider
                Slidedata={props.homeData.data.Sun_Glass_With_Trending}
              />
            )}
        </div> */}
      </section>
      <div
        className="frame-banner back"
        style={{ backgroundImage: `url(${framebg}) no-repeat` }}
      >
        {/* <div className="left-image">
          <img src={findframeimg} alt="" />
        </div> */}
        <div className="right-content d-flex">
          <div className="content-wrap">
            <h2>Trending Sun Glasses</h2>
            <p style={{ paddingBottom: "17px"}}>
              Choose The Best Frame According To Face Shape & Size
            </p>
            <a href="/find-your-frame" style={{ borderRadius: "15px", transition: "0.10s ease-in-out" }}>
              FIND YOUR FRAME
            </a>
          </div>
          {/* <div className="btn-wrap">
            <a href="/find-your-frame">FIND YOUR FRAME</a>
          </div> */}
        </div>
      </div>
      <div className="ns-banner p-0">
        {/* <a href="/elite-membership"> */}
        {/* {" "} */}
        {/* <img src={banner2} alt="" /> */}
        {/* <img src={Group} alt="" /> */}
        {/* </a> */}
        <div className="row m-0">
          <div className=" col-md-12 col-sm-12 p-0">
            {/* <div className="elite-content">
              <h2 className="program">Elite Membership <br /> <b>Program</b></h2>
              <span className="wait-span">What Are You Waiting For?</span>
              <ul className="eye-list">
                <li className="li-before"><span><img className="avi-img" src={avi} /></span>Get Extra Discount</li>
                <li className="li-before"><span><img className="avi-img" src={avi} /></span>Lifetime Service</li>
                <li><span><img className="avi-img" src={avi} /></span>Free Delivery</li>
              </ul>
              <button className="join-btn">Join Now</button>
            </div> */}
            <a href="/elite-membership">
              <img src={chasmaimg} />
            </a>
          </div>

          <div className="col-md-5"></div>
        </div>
      </div>
      <section className="custom-paddingx">
        {/* <div className="section-title-wrap text-center"> */}
        <div className="special-offer">
          <h2>
            Special <span>offers</span>
          </h2>
          <p className="happy-c">CLAIM YOUR REWARDS AND OFFERS</p>
        </div>

        <div className="row every-item">
          <MediaCard />
        </div>
        {/* <h4>
            <span>
              Sunglasses <strong>Starting At ₹499</strong>
            </span>
          </h4> */}
        {/* </div> */}
        <div className="p-slider-type1">
          {/* <a className="view-all-link" href="/product-list/Sunglasses">
            View All
          </a> */}
          <div className="product-slider product-slider-t1">
            {props.homeData.data?.Sun_Glass_special_offers &&
              props.homeData.data.Sun_Glass_special_offers.length > 0 && (
                <MySlider
                  Slidedata={props.homeData.data.Sun_Glass_special_offers}
                />
              )}
          </div>
        </div>
      </section>
      <section className="custom-paddingx">
        <div className="section-title-wrap text-center">
          <h2 className="mb-0">Shop Best Brands</h2>
        </div>
        <div className="brands-section p-5">
          <Slider {...sliderProps}>
            {brandImages.map((image) => {
              return (
                <div className="col-6">
                  <img src={image} alt="" />
                </div>
              );
            })}
          </Slider>
        </div>
      </section>
      <section className="testimonial-section custom-paddingx">
        <div className="section-title-wrap text-center">
          <h2 className="mb-2 client-say">What Client Says</h2>
          <p className="happy-p">HAPPY CUSTOMERS</p>
        </div>
        <div className="row client-fb">
          <MediaCardClient />
        </div>

        {/* <Slider {...testimonialProps}>
          {clientData.length > 0 &&
            clientData.map((data) => (
              <div key={data._id} className="px-3">
                <div className="testimonial-wrap">
                  <div className="img-wrap">
                    <img src={data.image} alt="" />
                  </div>
                  <div className="testimonail-contnet">
                    <div className="client-name">{data.name}</div>
                    <div className="client-post">{data.designation}</div>
                    <p>{data.description}</p>
                  </div>
                </div>
              </div>
            ))}
        </Slider> */}
      </section>
      {/* {videoLink && videoLink.length > 0 && (
        <section className="events-section">
          <h4>Chashma Planet Events</h4>
          <div className="video">
            <iframe
              width="100%"
              height="480"
              src={videoLink[0].link}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
        </section>
      )} */}
    </>
  );
};

const mapStateToPros = (state) => ({
  homeData: state.home.homeData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(HomePage);
