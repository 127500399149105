import ReactGA from "react-ga";
import ApiMethods from "./ApiMethods";
import uuid from 'react-uuid';
import appendSpreadsheet from "../components/helper/appendSpreadsheet";
const TOKEN_KEY = "access";

export const isLogin = () => {
  if (sessionStorage.getItem(TOKEN_KEY)) {
    return true;
  }
  return false;
};

export const isUserLogin = () => {
  if (sessionStorage.getItem("email")) {
    return true;
  }
  return false;
};

export const isMember = () => {
  if (sessionStorage.getItem('isMembership')) {
    return true;
  }
  return false;
}

export const createErrorMessage = (error) => {
  let message = "Something went wrong";
  if (error?.data?.detail) {
    message = error.data.detail;
  }
  return message;
};

export const newLetterSubmit = async (newsLetterData) => {
  let response = {};
  try {
    const { data } = await ApiMethods.postMethod(
      "user/newsletter",
      newsLetterData
    );
    response = { data, error: null };
  } catch (error) {
    return { error, data: null };
  }

  return response;
};

export const clientSays = async () => {
  let response = {};
  try {
    const { data } = await ApiMethods.getMethod("user/clients");
    response = { data, error: null };
  } catch (error) {
    return { error, data: null };
  }

  return response;
};

export const fetchVideoLink = async () => {
  let response = {};
  try {
    const { data } = await ApiMethods.getMethod("user/get/link");
    response = { data, error: null };
  } catch (error) {
    return { error, data: null };
  }

  return response;
};

export const fetchBannerData = async () => {
  let response = {};
  try {
    const { data } = await ApiMethods.getMethod("user/banners");
    response = { data, error: null };
  } catch (error) {
    return { error, data: null };
  }

  return response;
};

export const addItem = (item, powerType = "" , onlyframe = false) => {  
  
  ReactGA.event({ category: 'ITEM_ADD', action: 'ADD_TO_CART', label: 'ITEM_ADD_TO_CART' });
  if (isUserLogin()) {
    appendSpreadsheet({
      email: sessionStorage.getItem("email"),
      event: 'ADD_ITEM_TO_CART',
      createdAt: new Date().toString(),
      productId: item._id
    });
  }
  //create a copy of our cart state, avoid overwritting existing state
  let cartCopy = JSON.parse(sessionStorage.getItem("products"));

  //assuming we have an ID field in our item
  let { client_id } = item;

  //look for item in cart array
  let existingItem = cartCopy.find((cartItem) => cartItem.client_id === client_id);

  //if item already exists
  if (existingItem) {
    existingItem.qty = item.qty + 1; //update item
  } else {
    //if item doesn't exist, simply add it
    item.client_id = uuid();
    item.qty = 1;
    item.onlyFrame = onlyframe;
    item.powerType = powerType;
    cartCopy.push(item);
  }
  //make cart a string and store in local space
  let stringCart = JSON.stringify(cartCopy);
  sessionStorage.setItem("products", stringCart);
};

export const removeItem = (itemID) => {

  //create cartCopy
  let cartCopy = JSON.parse(sessionStorage.getItem("products"));

  if (isUserLogin()) {
    appendSpreadsheet({
      email: sessionStorage.getItem("email"),
      event: 'REMOVE_ITEM_FROM_CART',
      createdAt: new Date().toString(),
      productId: itemID
    });
  }

  cartCopy = cartCopy.filter(item => item.client_id !== itemID);

  let cartString = JSON.stringify(cartCopy)
  sessionStorage.setItem('products', cartString)
}

export const quantityDecrease = (item) => {
  //create a copy of our cart state, avoid overwritting existing state
  let cartCopy = JSON.parse(sessionStorage.getItem("products"));

  //assuming we have an ID field in our item
  let { client_id } = item;

  //look for item in cart array
  let existingItem = cartCopy.find((cartItem) => cartItem.client_id === client_id);

  if (existingItem) {
    existingItem.qty = item.qty - 1; //update item
  }

  //make cart a string and store in local space
  let stringCart = JSON.stringify(cartCopy);
  sessionStorage.setItem("products", stringCart);
}
