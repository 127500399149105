import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { useState } from "react";
import { useParams } from "react-router";
import Newsletter from "./Newsletter";
import ApiMethods from "../../utils/ApiMethods";
import { isLogin } from "../../utils";
import Login from "../Login/Login";

const BlogDetails = () => {
  const { id } = useParams();
  const [openLogin, setOpenLogin] = useState(false);
  const [commentInputData, setCommentInputData] = useState({
    name: "",
    comment: "",
  });
  const { isLoading, data } = useQuery("blogById", async () => {
    const res = await ApiMethods.getMethod(`user/blog/${id}`);
    return res.data.data;
  });

  if (isLoading) return 'loading...';

  const handleCommentInputData = (e) => {
    setCommentInputData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e && e.preventDefault();
    if (!isLogin()) {
      setOpenLogin(true);
    } else {
      await ApiMethods.postMethod('user/add/comment', {...commentInputData, blogId: id });
      setCommentInputData({
        name: '',
        comment: ''
      })
    }
  };

  return (
    <div className="container blog single-blog">
      <div className="row">
        <div className="col-12 col-lg-8 pr-4">
          <div className="blog-image">
            <img src={data.image} alt="thumbnail" />
          </div>
          <div className="blog-info">
            <div>
            <span>Latest And Popular</span>
            </div>
            <div className="blog-info-author">
              <span>{data.authorName}</span>
              <span className="">
                {moment(data.createdAt).format("DD MMMM, YYYY")}
              </span>
            </div>
          </div>
          <div className="blog-content">
            <h3>{data.title}</h3>
            <p>{data.description}</p>
          </div>
        </div>
        <div className="col-12 col-lg-4 blog-sidebar pl-4">
          <div className="d-none d-lg-block blog-newsletter">
            <Newsletter />
          </div>
          <div className="blog-comment">
            <h5>Comment</h5>
            <form onSubmit={onSubmit}>
              <input
                id="comment-name"
                name="name"
                placeholder="Name"
                value={commentInputData.name}
                onChange={handleCommentInputData}
              />
              <textarea
                id="comment-text"
                name="comment"
                value={commentInputData.comment}
                onChange={handleCommentInputData}
                placeholder="Enter Your Comment...."
                rows={7}
              ></textarea>
              <button type="submit" className="btn btn-primary custom-btn"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <Login openLogin={openLogin} onLoginClose={() => setOpenLogin(false)} />
    </div>
  );
};

export default BlogDetails;
