import axios from "axios";
import { createErrorMessage } from "./index";

const AxiosInstance = () => {
  let headers = {};
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.headers = headers;

  const accessToken = sessionStorage.getItem("access");
  axios.interceptors.request.use((config) => {
    if(accessToken) {
      config.headers['Authorization'] = "Bearer " + accessToken;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (
        error.response?.data.code === "token_not_valid" ||
        error.response?.status === 401
      ) {
        const refreshToken = sessionStorage.getItem("refresh_token");
        if (refreshToken) {
          try {
            const response = await axios.post(
              process.env.REACT_APP_API_URL + "user/generateRefreshToken",
              { refreshToken: refreshToken }
            );
            sessionStorage.setItem("access", response.data.token);
            return originalRequest;
          } catch (err) {
            console.log(err);
          }
        } else {
          console.log("Refresh token not available.");
        }
      }
      if (error.message === "Network Error") {
          error.response = {
              message: error.message
          }
      } else {
            error.response.message = createErrorMessage(error.response);
      }
      // specific error handling done elsewhere
      return Promise.reject(error.response);
    }
  );
  return axios;
};

const axiosInstance = AxiosInstance();

export default axiosInstance;
