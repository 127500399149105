import React from "react";
import "./Marker.css";
import locationIcon from '../../assets/images/location.png'

const Marker = (props) => {
  const { key } = props;
  return (
    <div key={key}>
      <img src={locationIcon} alt=" " />
    </div>
  );
};

export default Marker;
