import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import AddCoatingModal from "./AddCoatingModal";
import LensBuyInstructionModal from "./LensBuyInstructionModal";

const BifocalModal = (props) => {
  const { showLensModal, setShowlensModal, powerType } = props;
  const [showCoating, setShowCoating] = useState(false);
  const [showLensIntsModal, setShowLensIntsModal] = useState(false);
  const [lensTypePrice, setLensTypePrice] = useState(0);
  return (
    <>
      <Modal
        className="custom-dialog"
        size="xl"
        centered
        show={showLensModal}
        onHide={setShowlensModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4>Select Lenses for {powerType}</h4>
          </div>
          <div className="modal-body">
            <div className="row m-0">
              <div className="col-md-4 p-0">
                <div className="frame-box-wrapper">
                  <div className="top-box-wrap">
                    <h4>Wide Corridor Progressive</h4>
                    <h4>Frame + Lens</h4>
                    <h2>₹ 5000</h2>
                    <p>1 Year Warranty</p>
                  </div>
                  <a
                    href="#"
                    className="blue-btn"
                    data-toggle="modal"
                    data-target="#add-coating"
                    onClick={() => {
                      setShowlensModal();
                      setShowLensIntsModal(true);
                      if (powerType === "Bifocal/Progressive") {
                        setShowCoating(true);
                      }
                      setLensTypePrice(5000);
                    }}
                  >
                    Add to cart
                  </a>
                  <div className="bottom-list text-center">
                    <ul>
                      <li>Near To Far Progression</li>
                      <li>Thinnest Lens As Per Your Power</li>
                      <li>Anti-Glare Lens</li>
                      <li>Scratch Resistant</li>
                      <li>Water & Dust Repellent</li>
                      <li>UV 400 Protection</li>
                      <li>X</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-4 p-0">
                <div className="frame-box-wrapper">
                  <div className="top-box-wrap">
                    <h4>Normal Corridor Progressive</h4>
                    <h4>Frame + Lens</h4>
                    <h2>₹ 2500</h2>
                    <p>1 Year Warranty</p>
                  </div>
                  <a
                    href=""
                    className="blue-btn"
                    data-toggle="modal"
                    data-target="#add-coating"
                    onClick={() => {
                      setShowlensModal();
                      setShowLensIntsModal(true);
                      if (powerType === "Bifocal/Progressive") {
                        setShowCoating(true);
                      }
                      setLensTypePrice(2500);
                    }}
                  >
                    Add to cart
                  </a>
                  <div className="bottom-list text-center">
                    <ul>
                      <li>Near To Far Progression</li>
                      <li>X</li>
                      <li>Anti-Glare Lens</li>
                      <li>Scratch & Crack Resistant</li>
                      <li>Water & Dust Repellent</li>
                      <li>UV Protection</li>
                      <li>X</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-4 p-0">
                <div className="frame-box-wrapper">
                  <div className="top-box-wrap">
                    <h4>Premium Bi-Focal</h4>
                    <h4>Frame + Lens</h4>
                    <h2>₹ 1500</h2>
                    <p>1 Year Warranty</p>
                  </div>
                  <a
                    href=""
                    className="blue-btn"
                    data-toggle="modal"
                    data-target="#add-coating"
                    onClick={() => {
                      setShowlensModal();
                      setShowLensIntsModal(true);
                      if (powerType === "Bifocal/Progressive") {
                        setShowCoating(true);
                      }
                      setLensTypePrice(1500);
                    }}
                  >
                    Add to cart
                  </a>
                  <div className="bottom-list text-center">
                    <ul>
                      <li>Circular reading area in lower part</li>
                      <li>X</li>
                      <li>Anti-Glare Lens</li>
                      <li>Crack Resistant</li>
                      <li>Water & Dust Repellent</li>
                      <li>UV 400 Protection</li>
                      <li>
                        Power Range: SPH (+/-3 to +/-6] & CYL (+/-4) For any
                        other combination, opt either Bifocal-D or Progressives
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {powerType === "Bifocal/Progressive" ? (
        <AddCoatingModal
          lensPrice={lensTypePrice}
          openCoatingModal={showCoating}
          onOpenCoatingModal={() => setShowCoating(false)}
        />
      ) : (
        <LensBuyInstructionModal
          lensPrice={lensTypePrice}
          openLensModal={showLensIntsModal}
          onOpenLensModal={() => setShowLensIntsModal(false)}
        />
      )}
    </>
  );
};

export default BifocalModal;
