import { all, takeLatest, put } from "redux-saga/effects";
import { FETCH_HOME_PAGE_START } from '../actions/ActionConstants';
import ApiMethods from "../../utils/ApiMethods";
import { createNotification } from "react-redux-notify";
import {
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
    fetchHomePageSuccess,
    fetchHomePageFailure,
} from '../actions/HomeAction';


function* fetchHomePageAPI() {
    try {
        const response = yield ApiMethods.getMethod("user/home");
        if (response.data.success) {
            yield put(fetchHomePageSuccess(response.data.data));
        } else {
            yield put(fetchHomePageFailure(response.data));
            const notificationMessage = getErrorNotificationMessage('Something went wrong');
            yield put(createNotification(notificationMessage));
        }
    } catch {}
}


export default function* pageSaga() {
    yield all([
      yield takeLatest(FETCH_HOME_PAGE_START, fetchHomePageAPI),
    ]);
}