import {
    STORE_LOCATOR_START,
    STORE_LOCATOR_SUCCESS,
    STORE_LOCATOR_FAILURE,
    CONTACT_US_START,
    CONTACT_US_SUCCESS,
    CONTACT_US_FAILURE,
} from './ActionConstants';

// get all store locator
export function fetchStoreLocatorStart(data) {
    return {
        type: STORE_LOCATOR_START,
        data
    }
}

export function fetchStoreLocatorSuccess(data) {
    return {
        type: STORE_LOCATOR_SUCCESS,
        data
    }
}

export function fetchStoreLocatorFailure(data) {
    return {
        type: STORE_LOCATOR_FAILURE,
        data
    }
}

// contact us 
export function saveContactUsStart(data) {
    return {
        type: CONTACT_US_START,
        data
    }
}

export function saveContactUsSuccess(data) {
    return {
        type: CONTACT_US_SUCCESS,
        data
    }
}

export function saveContactUsFailure(data) {
    return {
        type: CONTACT_US_FAILURE,
        data
    }
}