/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import logo from "../../assets/images/new-logo.svg";
import axios from "axios";
import { useDispatch } from "react-redux";
import whatsapp from "../../assets/images/whatsapp-icon.svg";
import { createNotification } from "react-redux-notify";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/NotificationMessage";
import OrderNowOtpModal from "./OrderNowOtpModal";
import { useEffect } from "react";

const OrderNowModal = (props) => {
  const dispatch = useDispatch();
  const [openOrderNow, setOpenOrderNow] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [orderWlInputData, setOrderWlInputData] = useState({ phoneNumber: "" });

  useEffect(() => {
    setOpenOrderNow(true);
  }, []);

  const handleOrderWlIInputChange = (event) => {
    const { value, name } = event.currentTarget;
    setOrderWlInputData((prevState) => ({ ...prevState, [name]: value }));
  };

  const closeOrderChange = () => {
    setOpenOrderNow(false);
  };

  const handleOrderWithoutLogin = async (event) => {
    event && event.preventDefault();
    sessionStorage.setItem("otpNumber", orderWlInputData.phoneNumber);
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}user/create/guest`,
      { ...orderWlInputData, isTracking: true }
    );
    if (!orderWlInputData.phoneNumber) {
      return alert("Please Enter Phone Number");
    }
    if (response.data?.success) {
      const notificationMessage =
        getSuccessNotificationMessage("Login verified");
      dispatch(createNotification(notificationMessage));
      sessionStorage.setItem("access", response.data.token);
      sessionStorage.setItem("refresh_token", response.data.refreshToken);
      sessionStorage.setItem("user_id", response.data.data._id);
      closeOrderChange();
      setOpenOtpModal(true);
      //   window.location.reload();
    } else {
      const notificationMessage = getErrorNotificationMessage(
        "Something went wrong"
      );
      dispatch(createNotification(notificationMessage));
    }
  };
  return (
    <>
      <Modal
        className="custom-dialog"
        size="xl"
        centered
        backdrop="static"
        show={openOrderNow}
        onHide={closeOrderChange}
      >
        <div className="modal-content">
          <div className="modal-body">
            <div className="row m-0">
              <div className="col-md-5 p-0">
                <div className="modal-left">
                  <div className="logo-wrap">
                    <a href="/">
                    <h4 style={{color:'white'}}>Chashma Planet</h4>
                      {/* <img
                        src={logo}
                        alt="Framskraft.com"
                        title="Chashmaplanet.com"
                      /> */}
                    </a>
                  </div>
                  <div className="text-wrap">
                    <a href="https://api.whatsapp.com/send/?phone=919859424242&text&app_absent=0">
                      <h2>
                        Get Expert Help Now Available On{" "}
                        <span>
                          <img src={whatsapp} alt="whatsapp" />
                          Whatsapp{" "}
                        </span>
                      </h2>
                    </a>
                    <h3>Elite Membership Programme</h3>
                    <p>Get Special Offers And Benifits</p>
                    <a
                      href="/elite-membership"
                      alt="KNOW MORE"
                      title="KNOW MORE"
                    >
                      KNOW MORE <i className="fas fa-chevron-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-7 p-0">
                <div className="modal-right">
                  <div className="custom-form-wrapper">
                    <h4>Track Order</h4>
                    <form className="login-form">
                      <div className="form-group order-without-number">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Mobile Number"
                          maxLength="10"
                          pattern="\d{10}"
                          title="Please enter exactly 10 digits"
                          name="phoneNumber"
                          value={orderWlInputData.phoneNumber}
                          onChange={handleOrderWlIInputChange}
                          required
                        />
                      </div>
                      <>
                        <a
                          href="#"
                          onClick={() => {
                            handleOrderWithoutLogin();
                          }}
                          className="btn btn-primary custom-btn"
                        >
                          Submit
                        </a>
                        <a
                          href="/"
                          onClick={closeOrderChange}
                          className="btn btn-secondary btn-lg btn-block mt-4"
                        >
                          Cancel
                        </a>
                      </>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <OrderNowOtpModal
        openOtpModal={openOtpModal}
        closeOtpModal={() => setOpenOtpModal(false)}
      />
    </>
  );
};

export default OrderNowModal;
