import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import logo from "../../assets/images/new-logo.svg";
import axios from 'axios';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import whatsapp from "../../assets/images/whatsapp-icon.svg";
import { createNotification } from "react-redux-notify";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/NotificationMessage";

const PasswordChangeModal = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { openPasswordChange, closePasswordChange } = props;
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handlePasswordChange = (event) => {
    const { name, value } = event.currentTarget;
    if (name === 'password') {
      setPassword(value)
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value)
    }
    setErrorMessage('');
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      // alert('password not matched.')
      setErrorMessage('Password does not match.');
    return; 
    
    } else {
      const result = await axios.post(`${process.env.REACT_APP_API_URL}user/update/Password`, {
        otp: sessionStorage.getItem('otp'),
        phone: sessionStorage.getItem('ResetMobile'),
        password: password
      });      
      if (result.status === 200) {
        const notificationMessage = getSuccessNotificationMessage(
          result.data?.msg
        );
        dispatch(createNotification(notificationMessage));
        sessionStorage.removeItem('otp')
        sessionStorage.removeItem('ResetMobile')
        closePasswordChange();
        history.push('/');
        // window.location.href = '/';
      } else {
        const notificationMessage = getErrorNotificationMessage(
          result.data?.msg
        );
        dispatch(createNotification(notificationMessage));
      }
    }
  }  
  return (
    <Modal
      className="custom-dialog"
      size="xl"
      centered
      show={openPasswordChange}
      onHide={closePasswordChange}
    >
      <div className="modal-content">
        <div className="modal-body">
          <div className="row m-0">
            <div className="col-md-5 p-0">
              <div className="modal-left">
                <div className="logo-wrap">
                  <a href="/">
                  <h4 style={{color:'white'}}>Chashma Planet</h4>
                    {/* <img
                      src={logo}
                      alt="Framskraft.com"
                      title="Framskraft.com"
                    /> */}
                  </a>
                </div>
                <div className="text-wrap">
                  <a href="https://api.whatsapp.com/send/?phone=919859424242&text&app_absent=0">
                    <h2>
                      Get Expert Help Now Available On{" "}
                      <span>
                        <img src={whatsapp} alt="whatsapp" />
                        Whatsapp{" "}
                      </span>
                    </h2>
                  </a>
                  <h3>Elite Membership Programme</h3>
                  <p>Get Special Offers And Benifits</p>
                  <a href="/elite-membership" alt="KNOW MORE" title="KNOW MORE">
                    KNOW MORE <i className="fas fa-chevron-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-7 p-0">
              <i
                onClick={closePasswordChange}
                style={{ float: "right", cursor: "pointer" }}
                className="fas fa-times-circle"
              ></i>
              <div className="modal-right">
                <div className="custom-form-wrapper">
                  <h4>Reset Password</h4>
                  <form onSubmit={handleSubmit} className="reset-form">
                    <div className="form-group" id="show_hide_password">
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="New Password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        name="confirmPassword"
                        className="form-control"
                        placeholder="Confirm New Password"
                        onChange={handlePasswordChange}
                        value={confirmPassword}
                        required
                      />
                    </div>
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    <button
                      type="submit"
                      className="btn btn-primary custom-btn"
                    >
                      Save Password
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PasswordChangeModal;
