import React, { useContext } from "react";
import { useQuery } from "react-query";
import Accordion from "react-bootstrap/Accordion";
import faqQuestion from "../../assets/images/nk.png";
import AccordionContext from "react-bootstrap/AccordionContext";
import ApiMethods from "../../utils/ApiMethods";

function Accordionicon({ eventKey }) {
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div className="accordion-icon" >
      {!isCurrentEventKey ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          className="bi bi-plus"
          viewBox="0 0 16 16"
        >
          <path strokeWidth="3px" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          className="bi bi-dash"
          viewBox="0 0 16 16"
          strokeWidth="3px"
        >
          <path   d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
        </svg>
      )}
    </div>
  );
}

const Faqs = () => {
  const { isLoading, data } = useQuery('faq', async () => {
    const response = await ApiMethods.getMethod('user/get/faqs');
    return response.data.data;
  });

  if (isLoading) return 'loading...';

  return (
    <div className="faq-section container py-4">
      <div className="row">
    
        <div className="faq-question col-12 col-lg-8">
          <Accordion>
            {data.map((item, i) => (
              <div className="accordion-item">
                <Accordion.Toggle className="accordion-title" eventKey={i + 1}>
                  <div className="accordion-title-inner-container" >
                    {item.question}
                    <Accordionicon eventKey={i + 1} />
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse className="accordion-body" eventKey={i + 1}>
                  <p>{item.answer}</p>
                </Accordion.Collapse>
              </div>
            ))}
          </Accordion>
        </div>
        <div className="faq-image col-12 col-lg-4">
          <h3>
            Have Any <br />
            Questions?
          </h3>
          <h5>We're Here To Help</h5>
          <div className="faq-image-container">
            <img
              className="d-none d-lg-block w-100"
              src={faqQuestion}
              alt="question"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
