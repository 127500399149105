import React, { useState } from "react";
import { newLetterSubmit } from "../../utils/index";
import { useDispatch } from "react-redux";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import SecureTansaction from "../../assets/images/transaction.png";
import SimpleCheckout from "../../assets/images/checkout.png";
import GetInTouch from "../../assets/images/contact.png";
import Send from "../../assets/images/send.svg";
import visa from "../../assets/images/visa.png";
import amerikan from "../../assets/images/american-express.png";
import discover from "../../assets/images/discover.png";
import mastercard from "../../assets/images/master-card.png";
import facebook from "../../assets/images/facebook-footer.svg";
import instagram from "../../assets/images/instagram-footer.svg";
import twitter from "../../assets/images/twitter-footer.svg";
import pinterst from "../../assets/images/pinterst-footer.svg";

import "./footer-style.css";
import '../../assets/css/style.css'
import { Link } from "react-router-dom";


const Footer = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [isDisable, setIsDisable] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSend = async (event) => {
    event && event.preventDefault();
    setIsDisable(true);
    let message;
    if (email) {
      const { data, error } = await newLetterSubmit({ email: email });
      if (data && data.success) {
        setIsDisable(false);
        message = getSuccessNotificationMessage(data.msg);
        dispatch(createNotification(message));
      } else {
        message = getErrorNotificationMessage(data?.errors.email[0] || error?.errors.email[0]);
        dispatch(createNotification(message));
      }
      setEmail("");
    }
  };
  return (
    <footer>
      <div className="footer-feachers custom-paddingx text-center">
        <div className="row">
          <div className="col-lg-4 py-3">
            <img src={SecureTansaction} alt="SecureTansaction" />
            <h6>Secure transactons</h6>
            <p>Transactions are handled with bank-grade security</p>
          </div>
          <div className="col-lg-4 py-3">
            <img src={SimpleCheckout} alt="SimpleCheckout" />
            <h6>Simple checkout</h6>
            <p>Our secure checkout is quick and easy to use.</p>
          </div>
          <div className="col-lg-4 py-3">
            <img src={GetInTouch} alt="GetInTouch" />
            <h6>Get in touch</h6>
            <p>Have questions? Get in touch with us at any time.</p>
          </div>
        </div>
      </div>
      <div
        className="frame-banner back"
      >
        {/* <div className="right-content  d-flex" id="vvv"> */}
        <div className="content-wrap" style={{ width: '500px' }}>
          <h2>Subscribe For New Offers And Exclusive Deals</h2>
          <p style={{ paddingBottom: '15px' }}>Subscribe to get your eyes on our details and offers</p>
          {/* <a href="/find-your-frame" style={{ borderRadius: '10px' }}>FIND YOUR FRAME</a> */}

          <div className="form-wrap footer-input">
            <form
              onSubmit={handleSend}
              className="form-inline mb-0 mx-auto"
            >
              <input
                className="form-control field-input"
                type="email"
                name="email"
                onChange={handleEmailChange}
                value={email}
                placeholder="Enter Email"
                required
                aria-label="email"
              />
              <button
                className="btn btn-orange form-button"
                type="submit"
                disabled={isDisable}
              >
                Send
              </button>
            </form>
          </div>
        </div>

        {/* </div> */}
      </div>
      <div className="footer-top footer-top-new custom-paddingx py-4">
        {/* <div className="footer-newsletter">
          <div className="row align-items-center">
            <div className="col-lg-6 col-12 footer-about">
              <h4>Buy The Best Eyewear From Chashmaplanet</h4>
              <p>
                Chashmaplanet.Com Is An Online Eyewear Brand Along With Offline
                Stores Specializing In Accurate Eye Testing, Fashion-Forward
                Range Of Spectacles, Sunglasses, Prescription Sunglasses,
                Digitally Safe Glasses, Frames & Specialized Contact Lenses.
                Driven Towards Helping Millions Improve Their Vision & Lead
                Better Lives. We As A Brand Try To Create Value And Constantly
                Strive To Innovate Our Products & Better Our Services Basis Our
                Customer Requirement By Being On Ground With Our Staff.
              </p>
            </div>
            <div className="col-lg-6 col-12">
              <div className="f-newsletter-wrap">
                <h4>
                  Subscribe For New Offers And <br /> Exclusive Deals
                </h4>
                <br />
                <div className="form-wrap">
                  <form
                    onSubmit={handleSend}
                    className="form-inline mb-0 mx-auto"
                  >
                    <input
                      className="form-control input-field"
                      // style={{paddingTop:'27px', paddingBottom:'27px'}}
                      type="email"
                      name="email"
                      onChange={handleEmailChange}
                      value={email}
                      placeholder="Enter Email"
                      required
                      aria-label="email"
                    />
                    <button
                      className="btn btn-orange"
                      type="submit"
                      disabled={isDisable}
                    >
                      Send <img src={Send} alt="send" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="footer-links">
          <div className="row">
            <div className="footer-col fcol-1 pt-0 pt-lg-0">
              <h5 className="footer-link-hero">Quick Links</h5>
              <ul className="f-imp-linksnew">
                <li>
                  <a href="/about-us">About Us</a>
                </li>
                <li>
                  <a href="/store-locator">Contact Us</a>
                </li>
                <li>
                  <a href="/career">Careers</a>
                </li>
                <li>
                  <a href="/store-locator">Franchise Enquiry</a>
                </li>
                <li>
                  <a href="/shipping-policy">Shipping Policy</a>
                </li>
                <li>
                  <a href="/find-your-frame">Find your frame </a>
                </li>
                <li className="blog-store">
                  <a href={`mailto:Help@Chasmaplanet.Com`}>Help@Chasmaplanet.Com</a>
                </li>
              </ul>
            </div>
            <div className="footer-col fcol-2 pt-0 pt-lg-0">
              <h5 style={{ opacity: 0 }} className="footer-link-hero">
                Terms
              </h5>
              <ul className="f-imp-linksnew pt-8">
                <li>
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/refund-policy">Refund Policy</a>
                </li>
                <li>
                  <a href="/disclaimer">Disclaimer</a>
                </li>
                <li>
                  <a href="/terms-conditions">Terms & Conditions</a>
                </li>
                <li>
                  <a href="/faqs">FAQ</a>
                </li>
                <li className="blog-store">
                  <a href="tel:123456789">Call Us At : 123456789</a>
                </li>
              </ul>
            </div>
            <div className="footer-col fcol-3 pt-0 pt-lg-0">
              <h5 className="footer-link-hero">Contact Us</h5>
              <p>
              GROUND FLOOR, 131/043, KAISERBAGH, LUCKNOW, Lucknow, Uttar Pradesh, 22600 
                <br />
                {/* Colorado, 80301 */}
                {/* <br /> */}
                help@chasmaplanet.com
                <br />
                +91 123 (456) 789
              </p>
            </div>
            <div className="footer-col fcol-4 pt-0 pt-lg-0">
              <div>
                <h5 className="footer-link-hero">Safe & Secure Payments</h5>
                <img src={visa} alt="" />
                <img src={mastercard} alt="" />
                <img src={discover} alt="" />
                <img src={amerikan} alt="" />
              </div>

              <div className=" align-items-center footer-payment-logos">
                <span>Keep In Touch</span>
                <div className="footer-social">
                  <a
                    href="https://www.facebook.com/tulipwholesaleopticals"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={facebook} alt="facebook" />
                  </a>
                  <a
                    href="https://www.instagram.com/tulip_eyewear/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={instagram} alt="instagram" />
                  </a>
                  {/* <a
                    // href="https://in.pinterest.com/frameskrafts"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={pinterst} alt="pinterst" />
                  </a>
                  <a
                    // href="https://twitter.com/frameskraft_com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={twitter} alt="twitter" />
                  </a> */}
                </div>

              </div>

            </div>

          </div>
        </div>
        <div className="copyright copyright-new custom-paddingx">
          <div className="row align-items-center  justify-content-between center">
            <div className="col-lg-12 ">
              <span className="mb-0">
                © {new Date().getFullYear()} Chasmaplanet. All rights reserved.
              </span>
            </div>

          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
