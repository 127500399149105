import "./content-page-style.css";

const ShippingPolicy = () => {
  return (
    <div className="custom-paddingx c-page-content">
      <div className="c-content-wrap">
        <h1>Shipping Policy</h1>

        <p>At Chashmaplanet, we work hard to make sure your custom-made products get to you as soon soon as possible, while undergoing our multi-step quality and sanitization checks. We understand you're eager to receive your order, so here's a breakdown of our shipping process to set expectations:</p>


        <h3>
          <strong>Order Processing and Shipping Time</strong>
        </h3>
        <ul>
          <li>Orders are typically processed and shipped within 1-2 business days of confirmation. </li>
          <li>Once shipped, estimated delivery timelines are:</li>
          <ul>
            <li>Metro cities: 3-5 business days</li>
            <li>Other locations: 5-7 business days</li>
          </ul>
        </ul>

        <h3>
          <strong>Shipping Costs</strong>
        </h3>

        <p>We offer different shipping options to suit your needs:</p>

        <ul>
          <li><b>Free Shipping:</b> Enjoy free shipping on all orders above ₹500.</li>
          <li><b>Flat Rate Shipping:</b> A flat rate of ₹100 applies to orders below ₹500.</li>
        </ul>

        <h3>
          <strong>International Shipping (if applicable)</strong>
        </h3>

        <p>We currently ship to select countries internationally. Here's what to expect:</p>

        <ul>
          <li><b>Delivery Timeframes: </b> Please expect delivery within 7-10 business days after processing your order.</li>
          <li><b>Costs and Taxes:</b> Customers are responsible for any customs duties, import taxes, or other fees levied by the destination country.</li>
        </ul>


        <h3>
          <strong>Delivery and Shipping Terms</strong>
        </h3>

        <p>We currently ship to select countries internationally. Here's what to expect:</p>

        <ul>
          <li><b>Mode of Shipping:</b> We collaborate with reliable external logistic service providers to deliver your order. However, their reach might be limited in certain pin codes or locations.</li>
          <li><b>Serviceable Pin Codes:</b>To ensure a smooth delivery, please double-check if your pin code is serviceable at checkout. Provide an accurate, complete, and proper shipping address with a recognizable landmark for easy identification by the delivery personnel. Incorrect or incomplete information may lead to delays.</li>
          <li><b>Delivery Delays: </b>Unforeseen circumstances like bad weather, political disruptions, logistical issues, or technicalities beyond our control can cause delays. We'll do our best to notify you via email or SMS about any expected delays. </li>
          <li><b>Tracking Your Order:</b>  After dispatch, you'll receive a valid tracking number to check the progress of your shipment on the logistic partner's website. Please note that there might be variations in delivery timelines depending on the logistic partner's processes.</li>
        </ul>
        <h3>
          <strong>Conditions of Shipment for Non-Serviceable Pin Codes</strong>
        </h3>

        <p>For orders placed with non-serviceable pin codes, we'll use Speed Post for prepaid deliveries. However, please be aware of the following:</p>
        <ul>
          <li><b>Delivery Timeframes:</b> Deliveries via Speed Post to rural pin codes may take up to 30 days. </li>
          <li><b>Shipping Costs:</b>You'll be responsible for initiating a return for prepaid or COD orders with non-serviceable pin codes. In such cases, Chashmaplanet will compensate you for up to ₹50 in courier charges via bank transfer. </li>
        </ul>
        <h3>
          <strong>Contact Us</strong>
        </h3>

        <p>If you have any further questions about our Shipping Policy or your order, please don't hesitate to contact our customer service team at help@chashmaplanet.com.</p>
      </div>
    </div>
  );
};

export default ShippingPolicy;
