import React,{useEffect,useState} from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import reviewstar from "../../assets/images/review-star.svg";
import ApiMethods from "../../utils/ApiMethods";

const OrderCard = ({ order, index = 0, showUploadPrescription = true }) => {  
  const history = useHistory();
  
  const userId= sessionStorage.getItem('user_id');
  const productID= order?.orderedLens[0]?.product?.productId
  
  return (
    <div key={index} className="order-row-wrap">
      <div className="order-header row">
        <div className="col-md-7 p-0">
          <h4>
            {" "}
            <span>
              {" "}
              Order Placed :{" "}
              {moment(order.Order_Placed_On).format("D MMMM YYYY")}
            </span>{" "}
            <span>Amount : ₹{order.Amount}</span>{" "}
          </h4>
        </div>
        <div className="col-md-5">
          <h4>
            Order ID : # {order?.fkOrderId ? order.fkOrderId : order.Order_id}
          </h4>
        </div>
      </div>
      
      <div className="order-body-wrap">
        <div className="col-md-7 p-0">
          <div className="product-textDetal">
          {order.orderedLens.map((lens, lensIndex) => (
            <div className="product-textDetal" key={lensIndex}>
              <div className="img-wrap">
                <img src={lens.product?.imgCollection[0]} alt={lens.product?.name || "Product Image"} />
              </div>

              <h2>{lens?.product?.name}</h2>
              <h6>
                {lens?.product?.material +
                  " " +
                  lens?.product?.type +
                  " " +
                  lens?.product?.shape +
                  " " +
                  lens?.product?.category}
              </h6>
              <p className="rating-text">
                <span> 5.0 Rating </span>
                <img src={reviewstar} alt="Review Star" />
              </p>
              {showUploadPrescription && (
              <div className="order-prescription">
                {(!(lens?.lensType || lens?.powerType === 'Zero Power')) &&
                <button
                  onClick={() =>
                    history.push(`/upload-prescription/${order._id}/${lens?.product._id}`)
                  }
                  className="upload-prescription"
                >
                  
                    Upload Prescription
             
                </button>
                    } 
                <button
                  onClick={() => history.push(`/track-order/${order._id}`)}
                  className="track-order"
                >
                  Track My Order
                </button>
              </div>
            )}
            </div>
          
          ))}
            {/* <div className="img-wrap">
              <img
                src={order.orderedLens[0].product?.imgCollection[0]}
                alt=""
              />
            </div>

            <h2>{order.orderedLens[0].product.name}</h2>
            <h6>
              {order.orderedLens[0].product.material +
                " " +
                order.orderedLens[0].product.type +
                " " +
                order.orderedLens[0].product.shape +
                " " +
                order.orderedLens[0].product.category}
            </h6> */}
            {/* <p className="rating-text">
              <span> 5.0 Rating </span>
              <img src={reviewstar} alt="" />
            </p> */}
            {/* <div className="size-block">
                        <p className="color-wrap">
                          Color <span>Blue</span>{" "}
                          <a className="dot-crcle blue"></a>
                        </p>
                      </div> */}
            {/* {showUploadPrescription && (
              <div className="order-prescription">
                <button
                  onClick={() =>
                    history.push(`/upload-prescription/${order._id}`)
                  }
                  className="upload-prescription"
                >
                  {presData && presData.length > 0
                    ? "View Prescription"
                    : "Upload Prescription"}
                </button>
                <button
                  onClick={() => history.push(`/track-order/${order._id}`)}
                  className="track-order"
                >
                  Track My Order
                </button>
              </div>
            )} */}
          </div>
        </div>
        <div className="col-md-5 p-0">
          <div className="shipping-text">
            <h4 className="mb-2">Shipping Address :</h4>
            {/* <h4>Drake Wilsone</h4> */}
            <p>
              {order.ShippingAddress[0].Address +
                ", " +
                order.ShippingAddress[0].Town +
                ", " +
                order.ShippingAddress[0].City}{" "}
              <br />
              {order.ShippingAddress[0].state +
                ", " +
                order.ShippingAddress[0].Pincode}
            </p>
            {/* <p>
                        Mobile number -{" "}
                        <a href="tel:9849598985"> 9849598985 </a>
                      </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
