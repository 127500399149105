import React from "react";
import whoweare from "../../assets/images/our-mission.jpg";
import ourvision from "../../assets/images/our-vision.jpg";
import fromus from "../../assets/images/nks.jpg";
import mukesh1 from "../../assets/images/mukesh1.jpeg"
import mukesh2 from "../../assets/images/mukesh2.jpeg"

const AboutUs = () => {
  return (
    <>
      <div className="content-img-section">
        <div className="custom-container">
          <div className="team-section">
            <h3 className="meet-team">Meet <span>Our Team</span></h3>
            <div className="team-members row">
              <div className="team-wrap col-lg-4">
                <img src={mukesh1} alt="" />
              </div>
              <div className="team-wrap left-text  col-lg-8">
                <div className="text-wrap">
                  <div className="team-name">
                    <div className="individual-wrap">
                      <h4>Mukesh Maharaj</h4>
                      <p>Founder</p>
                    </div>
                  </div>
                  <p>
                    Born into a humble family, his means were meagre,
                    but his dreams were vast. From a young age, Mukesh
                    witnessed the hardships his parents endured to
                    make ends meet, yet they never let their spirits falter.
                    Their unwavering determination became the cornerstone
                    of Mukesh's ambition.
                  </p>
                  <p>
                    Driven by a desire to change his family's fortunes, Mukesh
                    set his sights on education. Despite financial constraints,
                    he poured himself into his studies, excelling at every opportunity.
                    His intellect shone bright, catching the attention of mentors
                    who recognized his potential. With their support, Mukesh
                    embarked on a journey beyond the borders of Jharkhand,
                    landing in the bustling city of Jaipur to pursue higher education.
                  </p>
                </div>
              </div>
            </div>
            <div className="team-members change-order row">
              <div className="team-wrap right-text col-12">
                <div className="text-wrap">
                  <p>
                    Armed with resilience and a thirst for knowledge,
                    Mukesh pursued an MBA from Udaipur, honing his skills
                    in the intricacies of business management.
                    But it wasn't just academic prowess that set Mukesh apart;
                    it was his unwavering determination to succeed, fuelled by
                    a deep-seated love for his parents and a burning desire to
                    lift them out of poverty.
                  </p>
                  <p>Upon completing his education, Mukesh returned to Lucknow,
                    determined to carve his own path in the world of business.
                    Drawing from his experiences and armed with a vision,
                    he founded his own company specializing in eyewear – a venture
                    he knew held immense potential. With grit and determination,
                    he opened his first store in 2004, offering quality spectacles
                    and lenses at affordable prices.
                  </p>
                  <p>
                    Mukesh's dedication to his craft and commitment to customer
                    satisfaction soon earned him a loyal clientele. Word of mouth
                    spread like wildfire, and before long, Mukesh's business flourished.
                    One store turned into two, then three, until he had established a
                    network of seven successful outlets across Lucknow.
                  </p>
                </div>

              </div>
            </div>
            <div className="team-members row">
              <div className="team-wrap col-lg-4">
              <img src={mukesh2} alt="" />
              </div>
              <div className="team-wrap left-text  col-lg-8">
                <div className="text-wrap">
                  <div className="team-name">
                  </div>
                  <p>
                  But Mukesh's ambitions didn't stop there. With each milestone,
                    he pushed the boundaries of his success further, expanding his
                    reach beyond the confines of his city. His entrepreneurial
                    spirit and innovative approach to business caught the attention
                    of industry leaders, earning him international acclaim and
                    prestigious awards in the world of eyewear.
                  </p>
                  <p>
                  Through it all, Mukesh remained grounded, never
                    forgetting his roots or the sacrifices his parents
                    made to pave the way for his success.
                  </p>
                  <p>
                    Mukesh's journey from Jamshedpur to Lucknow was more than
                    just a tale of rags to riches; it was a testament to the
                    power of perseverance, passion, and the unwavering love of family.
                    And as he stood atop the pinnacle of his success,
                    Mukesh knew that the greatest reward of all was not
                    the accolades or wealth he had amassed, but the knowledge
                    that he had made his parents proud.
                    </p>
                </div>
              </div>
            </div>
            {/* <div className="team-members row">
              <div className="team-wrap1 mb-0 col-5">
                <img src={mukesh2} alt="" />
              </div>
              <div className="team-wrap left-text mb-0 col-7">
                <div className="text-wrap">
                  <p>
                    But Mukesh's ambitions didn't stop there. With each milestone,
                    he pushed the boundaries of his success further, expanding his
                    reach beyond the confines of his city. His entrepreneurial
                    spirit and innovative approach to business caught the attention
                    of industry leaders, earning him international acclaim and
                    prestigious awards in the world of eyewear.
                  </p>
                  <p>
                    Through it all, Mukesh remained grounded, never
                    forgetting his roots or the sacrifices his parents
                    made to pave the way for his success.
                  </p>
                  <p>
                    Mukesh's journey from Jamshedpur to Lucknow was more than
                    just a tale of rags to riches; it was a testament to the
                    power of perseverance, passion, and the unwavering love of family.
                    And as he stood atop the pinnacle of his success,
                    Mukesh knew that the greatest reward of all was not
                    the accolades or wealth he had amassed, but the knowledge
                    that he had made his parents proud.
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="custom-container">
          <div className="row align-items-start">
            <div className="col-md-6 text-left">
              <div className="content-wrap orange">
                <h1>
                  Our <span>Mission-</span>
                </h1>
                <p>
                  To provide high-quality eyewear solutions that cater to every
                  individual's needs, from affordable options for budget-conscious
                  customers to premium luxury spectacles. With our offline branches
                  and online platform, we strive to ensure everyone has access to
                  clear vision and stylish frames, offering exceptional service
                  and value across all price ranges.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-wrap">
                <img src={whoweare} alt="" title="" />
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="img-wrap">
                <img src={ourvision} alt="" title="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="content-wrap blue">
                <h1>
                  Our Vision  <span>Statement:</span>
                </h1>
                <p>
                  "To become the leading eyewear retailer nationwide known for our
                  inclusive approach, innovative products, and exceptional customer
                  service. We envision a world where everyone, regardless of their
                  budget, can experience optimal vision and fashionable eyewear.
                  Through our commitment to accessibility, quality, and community
                  engagement, we aim to enhance the lives of our customers and set
                  a new standard in the optical industry."
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 text-left">
              <div className="content-wrap green">
                <h1>
                  Our Core <span>Values:</span>
                </h1>
                <ul>
                  <li>
                    <b>Inclusivity:</b>We believe in providing eyewear solutions for everyone,
                    regardless of their financial situation, ensuring access to clear
                    vision and style for all.
                  </li>
                  <li>
                  <b>Quality:</b> We are committed to offering high-quality products across all price ranges, ensuring durability, comfort, and satisfaction for our customers.
                  </li>
                  <li> <b>Customer Service:</b> Our customers are at the heart of our business. We strive to deliver exceptional service, personalized care, and a seamless shopping experience in all our branches.</li>
                  <li><b>Innovation: </b>We embrace innovation in our products and services, continuously seeking new ways to improve our offerings and stay ahead in the optical industry.</li>
                  <li>
                  <b>Integrity:</b>   We operate with honesty and transparency, building trust with our customers, employees, and partners through ethical business practices.
                  </li>
                  <li>
                <b>Community Engagement:</b>  We actively contribute to the communities we serve, supporting initiatives that promote eye health and well-being.
                  </li>
                  <li>
                   <b>Sustainability:</b>  We are dedicated to sustainable practices, from sourcing materials to minimizing our environmental footprint, ensuring a better future for our planet.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-wrap">
                <img src={fromus} alt="" title="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
