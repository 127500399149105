import {
    STORE_LOCATOR_START,
    STORE_LOCATOR_SUCCESS,
    STORE_LOCATOR_FAILURE,
    CONTACT_US_START,
    CONTACT_US_SUCCESS,
    CONTACT_US_FAILURE,
} from '../actions/ActionConstants';

const initialState = {
    storeLocatorData: {
        data: {},
        inputData: {},
        loading: true,
        error: false
    },
    contactUs: {
        data: {},
        inputData: {},
        loading: false,
        error: false
    }
}

const StoreLocatorReducer = (state = initialState, action) => {
    switch(action.type) {
        case STORE_LOCATOR_START:
            return {
                ...state,
                storeLocatorData: {
                    inputData: action.data,
                    data: {},
                    loading: true,
                    error: false
                }
            };
        case STORE_LOCATOR_SUCCESS:
            return {
                ...state,
                storeLocatorData: {
                    data: action.data,
                    inputData: {},
                    loading: false,
                    error: false
                }
            };
        case STORE_LOCATOR_FAILURE:
            return {
                ...state,
                storeLocatorData: {
                    data: {},
                    inputData: {},
                    loading: false,
                    error: action.error
                }
            };
        case CONTACT_US_START:
            return {
                ...state,
                contactUs: {
                    data: {},
                    inputData: action.data,
                    loading: true,
                    error: false
                }
            };
        case CONTACT_US_SUCCESS:
            return {
                ...state,
                contactUs: {
                    data: action.data,
                    inputData: {},
                    loading: false,
                    error: false
                }
            };
        case CONTACT_US_FAILURE:
            return {
                ...state,
                contactUs: {
                    data: {},
                    inputData: {},
                    loading: false,
                    error: action.error
                }
            };
        default:
            return state;
    }
};

export default StoreLocatorReducer;