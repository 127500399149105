// all reducers will be added and combine it to become a rootreducers
import { combineReducers } from "redux";

import userReducer from "./UserReducer";
import StoreLocatorReducer from "./StoreLocatorReducer";
import ProductReducer from "./ProductReducer";
import HomeReducer from "./HomeReducer";
import CareerReducer from "./CareerReducer";
import notifyReducer from "react-redux-notify";

export default combineReducers({
    users: userReducer,
    notifications: notifyReducer,
    storeLocator: StoreLocatorReducer,
    products: ProductReducer,
    home: HomeReducer,
    career: CareerReducer,
})