import React, { useEffect, useState } from "react";
import ApiMethods from "../utils/ApiMethods";
import "../assets/css/prescription.css"; // Add appropriate styles for the modal

const PrescriptionModal = ({ prescriptionData, prescriptionFile, onClose,presData }) => {
    
   
  return (
    <div className="prescr-overlay">
      <div className="prescr-content">
        <button className="prescr-close" onClick={onClose}>
          &times;
        </button>
        <h4>Prescription Details</h4>
        <p><strong>Name:</strong> {presData[0]?.name}</p>
        <p><strong>Description:</strong> {presData[0]?.description}</p>
        <a href={presData[0]?.file}><strong>File:</strong> view pdf</a>
        {/* {prescriptionFile && (
          <div>
            <p><strong>File:</strong></p>
            <a href={prescriptionFile} target="_blank" rel="noopener noreferrer">
              View File
            </a>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default PrescriptionModal;
