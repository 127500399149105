import React from "react";
import MyAccountTab from "./MyAccountTab";
import paymenticon from "../../assets/images/payment-icon.svg";
import hdfccard from "../../assets/images/hdfc.png";

const PaymentOption = () => {
  return (
    <div className="my-account page-pdding">
      <div className="top-account-bar text-center">
        <h4>My Account</h4>
      </div>
      <div className="product-listing">
        <MyAccountTab />
        <div className="product-content">
          <div className="account-header">
            <div className="order-left">
              <img src={paymenticon} alt="" />
              <h2> Payment Options </h2>
            </div>
          </div>

          <div className="order-row-wrap">
            <div className="order-header row">
              <div className="col-md-4 p-0">
                <h4>
                  {" "}
                  <img src={hdfccard} alt="" /> HDFC Bank Credit Card
                </h4>
              </div>
              <div className="col-md-4 p-0">
                <h4>Expires On - 02 May, 2023 </h4>
              </div>
            </div>
            <div className="order-body-wrap">
              <div className="shipping-text w-100 p-0">
                <div className="row">
                  <div className="col-md-4 p-0  mb-md-4 mb-sm-2">
                    <h4>Name on card</h4>
                    <h4>Drake wilson </h4>
                  </div>
                  <div className="col-md-8 p-0 mb-md-4 mb-sm-2">
                    <h4>Billing Address</h4>
                    <h4>
                      984 Grasselli Street, Amesbury NH, New Hampshire - 01913
                    </h4>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <a href="" className="nxt-btn ml-3">
                    Remove
                  </a>
                  <a href="" className="nxt-btn">
                    Edit
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="order-row-wrap">
            <div className="order-header row">
              <div className="col-md-4 p-0">
                <h4>
                  {" "}
                  <img src={hdfccard} alt="" /> HDFC Bank Credit Card
                </h4>
              </div>
              <div className="col-md-4 p-0">
                <h4>Expires On - 02 May, 2023 </h4>
              </div>
            </div>
            <div className="order-body-wrap">
              <div className="shipping-text w-100 p-0">
                <div className="row">
                  <div className="col-md-4 p-0 mb-md-4 mb-sm-2">
                    <h4>Name on card</h4>
                    <h4>Drake wilson </h4>
                  </div>
                  <div className="col-md-8 p-0  mb-md-4 mb-sm-2">
                    <h4>Billing Address</h4>
                    <h4>
                      984 Grasselli Street, Amesbury NH, New Hampshire - 01913
                    </h4>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <a href="" className="nxt-btn ml-3">
                    Remove
                  </a>
                  <a href="" className="nxt-btn">
                    Edit
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="add-address">
            <a href="" className="new-add">
              Add New Card <i className="fas fa-plus"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentOption;
