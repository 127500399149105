import React from "react";
import { useQuery } from 'react-query';
// import moment from "moment";
import MyAccountTab from "./MyAccountTab";
import editlocation from "../../assets/images/edit-location.svg";
import orderPlaced from "../../assets/images/order-placed.svg";
import qualityCheck from "../../assets/images/quality-check.svg";
import lensFitted from "../../assets/images/lens-fitted.svg";
import dispatched from "../../assets/images/dispatched.svg";
import delivered from "../../assets/images/delivered.svg";
import ApiMethods from "../../utils/ApiMethods";
import OrderCard from "./OrderCard";

const TrackOrder = (props) => {
  const { isTracking = false } = props;
  const { id: orderId } = props.match.params;
  const { isLoading, data } = useQuery("trackorder", async () => {
    const res = await ApiMethods.getMethod(`user/get/order/${orderId}`);
    return res.data.data;
  });

  if (isLoading) return 'loading...';


  const trackOrder = [
    {
      id: 0,
      iconUrl: orderPlaced,
      title: "Order Placed",
    },
    {
      id: 1,
      iconUrl: qualityCheck,
      title: "Lens Fitted",
    },
    {
      id: 2,
      iconUrl: lensFitted,
      title: "Quality Checked",
    },
    {
      id: 3,
      iconUrl: dispatched,
      title: "Dispatched",
    },
    {
      id: 4,
      iconUrl: delivered,
      title: "Delivered",
    },
  ];

  const trackOrderActiveStep = trackOrder.filter(order => order.title === data[0]?.orderStatus);

  return (
    <div className="my-account page-pdding">
      <div className="top-account-bar text-center">
        <h4>My Account</h4>
      </div>
      <div className="product-listing">
      {!isTracking && <MyAccountTab />}
        <div className="product-content">
          <div className="account-header">
            <div className="order-left">
              <img src={editlocation} alt="" />
              <h2> Track Order </h2>
            </div>
          </div>
          <OrderCard order={data[0]} index={0} showUploadPrescription={false} />
          <div className="track-order pb-4 pt-2">
            <h5>Track My Order</h5>
            <div className="track-order-details">
              {trackOrder.map((track, i) => (
                <div
                  key={track.title}
                  className={
                    "track-order-item" +
                    (trackOrderActiveStep[0]?.id >= i ? " active" : "")
                  }
                >
                  <div className="track-order-item-icon">
                    <img src={track.iconUrl} alt="icon" />
                  </div>
                  <div className="track-order-item-text">
                    <h6>{track.title}</h6>
                    {/* <span>{moment().format("DD MMMM YYYY")}</span>
                    <span>{moment().format("hh:MM A")}</span> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackOrder;
