import moment from "moment";
import React, { useState } from "react";
import { useQuery } from 'react-query';
import { Link } from "react-router-dom";
import Newsletter from "./Newsletter";
import ApiMethods from "../../utils/ApiMethods";

const Blog = () => {
  const { isLoading, data = [] } = useQuery("blog", async () => {
    const res = await ApiMethods.getMethod('user/get/blogs');
    return res.data.data;
  });

  if (isLoading) return 'loading...';

  if (data.length === 0) return <h1>No Data Found</h1>;

  return (
    <div className="container blog main-blog">
      <div className="row pb-5">
        <div className="col-12 col-xl-8 hero-blog">
          <div className="row">
            <div className="hero-blog-image col-12 col-lg-5">
              <img src={data[0].image} alt="thumbnail" />
            </div>
            <div className="hero-blog-content text-break col-12 col-lg-7 py-3 py-lg-0">
              <span>Latest And Popular</span>
              <h3>{data[0].title}</h3>
              <p>{data[0].description}</p>
              <div className="hero-blog-author py-4">
                <span>{data[0].authorName}</span>
                <span className="pl-4">
                  {moment(data[0].createdAt).format("DD MMMM, YYYY")}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 main-blog-newsletter">
          <Newsletter />
        </div>
      </div>
      <div className="row blog-list mt-4 text-break">
        {data.map((blog, i) => (
          <div key={i} className="blog-item-container col-12 col-md-6 col-lg-3">
            <Link to={`/blog/${blog._id}`}>
              <BlogItem blog={blog} />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

const BlogItem = ({ blog }) => {
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <div className="blog-item">
      <div className="blog-item-image">
        <img src={blog.image} alt="thumbnail" />
      </div>
      <div className="blog-item-content">
        <h5>{blog.title}</h5>
        <div className="read-more">
          <p>
            {isReadMore ? `${blog.description.slice(0, 50)}...` : blog.description}
          </p>
          <button onClick={toggleReadMore} className="read-more-btn">
            {isReadMore ? "Read More" : "Show Less"}
          </button>
        </div>
        <div className="blog-item-author py-4">
          <div>{blog.authorName}</div>
          <div className="pl-4">
            {moment(blog.createdAt).format("DD MMMM, YYYY")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
