import { useState } from "react";
import fullRim from '../../assets/images/fullrim.png';
import halfRim from '../../assets/images/halfrim.png';
import rimless from '../../assets/images/rimless.png';
import aviator from '../../assets/images/aviator.png';
import cateye from '../../assets/images/cateye.png';
import rectangle from '../../assets/images/rectangle.png';
import square from '../../assets/images/square.png';
import wayfar from '../../assets/images/wayfar.png';
import round from '../../assets/images/roundfilter.png';

const genderArr = [
  { text: "Female", value: "Female" },
  { text: "Male", value: "Male" },
  { text: "Unisex", value: "Unisex" },
  { text: "Kids", value: "Kids" },
  { text: "Others", value: "Others" },
];

const disposables = [
  { text: "Daily", value: "Daily" },
  { text: "Monthly", value: "Monthly" },
  { text: "Yearly", value: "Yearly" },
];

const colorOption = [
  { text: "Black", value: "black" },
  { text: "Brown", value: "brown" },
  { text: "Blue", value: "blue" },
  { text: "Grey", value: "grey" },
  { text: "Red", value: "red" },
  { text: "Orange", value: "orange" },
  { text: "Yellow", value: "yellow" },
  { text: "Pink", value: "pink" },
  { text: "Green", value: "green" },
  { text: "White", value: "white" },
  { text: "Purple", value: "purple" },
  { text: "Gun Metal", value: "gunmetal" },
  { text: "Golden", value: "golden" },
  { text: "Silver", value: "silver" },
  { text: "Rosegold", value: "rosegold" },
  { text: "Maroon", value: "maroon" },
  { text: "Transparent", value: "transparent" },
  { text: "Tortoise", value: "tortoise" },
];

const priceOption = [
  { text: "Rs. 0 - Rs. 999", value: "0,999" },
  { text: "Rs. 1000 - Rs. 1999", value: "1000,1999" },
  { text: "Rs. 2000 - Rs. 3999", value: "2000,3999" },
];

const brandOption = [
  { text: "Rayban", value: "Rayban" },
  { text: "Vogue", value: "Vogue" },
  { text: "Carrera", value: "Carrera" },
  { text: "Oakley", value: "Oakley" },
  { text: "French-Connection", value: "French-Connection" },
  { text: "Tommy Hilfiger", value: "Tommy Hilfiger" },
  { text: "Pierre Cardin", value: "Pierre Cardin" },
  { text: "Hugo Boss", value: "Hugo Boss" },
  { text: "David John", value: "David John" },
  { text: "Sandpiper", value: "Sandpiper" },
  { text: "Velocity", value: "Velocity" },
  { text: "Stepper", value: "Stepper" },
  { text: "Femina Flaunt", value: "Femina Flaunt" },
  { text: "Tedwoods", value: "Tedwoods" },
  { text: "FK frames", value: "FK frames" },
];

const materialOption = [
  { text: "Acetate", value: "Acetate" },
  { text: "TR90", value: "TR90" },
  { text: "Nylon", value: "Nylon" },
  { text: "Metal", value: "Metal" },
  { text: "Stainless-steel", value: "Stainless-steel" },
  { text: "Titanium", value: "Titanium" },
];

const ProductFilter = ({
  onSelectFrameType,
  onSelectFrameShape,
  onSelectGender,
  onSelectColor,
  onSelectPrice,
  onSelectBrand,
  onSelectMaterial,
  path,
  onSelectDisposable
}) => {
  const [frameType, setFrameType] = useState("");
  const [frameShape, setFrameSahpe] = useState("");

  const handleOnChange = (e) => {
    onSelectGender(e.target.value, e.target.checked);
  };

  const handleOnColorChange = (e) => {
    onSelectColor(e.target.value, e.target.checked);
  };

  const handleOnPriceChange = (e) => {
    onSelectPrice(e.target.value, e.target.checked);
  };

  const handleOnBrandSelect = (e) => {
    onSelectBrand(e.target.value, e.target.checked);
  };

  const handleOnMaterialSelect = (e) => {
    onSelectMaterial(e.target.value, e.target.checked);
  };

  const handleOnDisposableSelect = (e) => {
    onSelectDisposable(e.target.value, e.target.checked);
  }
  return (
    <div className="product-sidebar">
      {path === "Contact Lens" ? (
        <div id="attribute-accordion" className="accordion">
          <div className="card">
            <div className="card-header p-0" id="headingThree">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  DISPOSABILITY 
                </button>
              </h5>
            </div>
            <div
              id="collapseThree"
              className="collapse show"
              aria-labelledby="headingThree"
            >
              <div className="card-body color-list">
                {disposables.map((data, index) => (
                  <div key={index} className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      value={data.value}
                      onClick={(e) => handleOnDisposableSelect(e)}
                    />
                    <label className="custom-control-label">{data.text}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header p-0" id="headingThree">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  COLOR
                </button>
              </h5>
            </div>
            <div
              id="collapseThree"
              className="collapse show"
              aria-labelledby="headingThree"
              style={{ height: '200px', overflowY: 'scroll' }}
            >
              <div className="card-body color-list">
                {colorOption.map((color, index) => (
                  <div key={index} className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      value={color.value}
                      onClick={(e) => handleOnColorChange(e)}
                    />
                    <label className="custom-control-label">{color.text}</label>
                    <span className={`colordot ${color.value}`}></span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div id="attribute-accordion" className="accordion">
          <div className="card">
            <div className="card-header p-0" id="headingOne">
              <h5 className="mb-0">
                <button
                  className="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  FRAME TYPE
                </button>
              </h5>
            </div>

            <div
              id="collapseOne"
              className="collapse show"
              aria-labelledby="headingOne"
            >
              <div className="card-body">
                <div
                  onClick={() => {
                    if (frameType === "Full Rim") {
                      setFrameType();
                      onSelectFrameType();
                    } else {
                      setFrameType("Full Rim");
                      onSelectFrameType("Full Rim");
                    }
                  }}
                  className={
                    frameType === "Full Rim" ? "small-pro active" : "small-pro"
                  }
                >
                  <img src={fullRim} alt="" />
                  <span>Full Rim</span>
                </div>
                <div
                  onClick={() => {
                    if (frameType === "Half Rim") {
                      setFrameType();
                      onSelectFrameType();
                    } else {
                      setFrameType("Half Rim");
                      onSelectFrameType("Half Rim");
                    }
                  }}
                  className={
                    frameType === "Half Rim" ? "small-pro active" : "small-pro"
                  }
                >
                  <img src={halfRim} alt="" />
                  <span>Half Rim</span>
                </div>
                <div
                  onClick={() => {
                    if (frameType === "Rimless") {
                      setFrameType();
                      onSelectFrameType();
                    } else {
                      setFrameType("Rimless");
                      onSelectFrameType("Rimless");
                    }
                  }}
                  className={
                    frameType === "Rimless" ? "small-pro active" : "small-pro"
                  }
                >
                  <img src={rimless} alt="" />
                  <span>Rim Less</span>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header p-0" id="headingTwo">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  FRAME SHAPE
                </button>
              </h5>
            </div>
            <div
              id="collapseTwo"
              className="collapse show"
              aria-labelledby="headingTwo"
            >
              <div className="card-body">
                <div
                  onClick={() => {
                    if (frameShape === "Rectangle") {
                      setFrameSahpe();
                      onSelectFrameShape();
                    } else {
                      setFrameSahpe("Rectangle");
                      onSelectFrameShape("Rectangle");
                    }
                  }}
                  className={
                    frameShape === "Rectangle"
                      ? "small-pro active"
                      : "small-pro"
                  }
                >
                  <img src={rectangle} alt="" />
                  <span>Rectangle</span>
                </div>
                <div
                  onClick={() => {
                    if (frameShape === "Round") {
                      setFrameSahpe();
                      onSelectFrameShape();
                    } else {
                      setFrameSahpe("Round");
                      onSelectFrameShape("Round");
                    }
                  }}
                  className={
                    frameShape === "Round" ? "small-pro active" : "small-pro"
                  }
                >
                  <img src={round} alt="" />
                  <span>Round</span>
                </div>
                <div
                  onClick={() => {
                    if (frameShape === "Cat Eye") {
                      setFrameSahpe();
                      onSelectFrameShape();
                    } else {
                      setFrameSahpe("Cat Eye");
                      onSelectFrameShape("Cat Eye");
                    }
                  }}
                  className={
                    frameShape === "Cat Eye" ? "small-pro active" : "small-pro"
                  }
                >
                  <img src={cateye} alt="" />
                  <span>Cat Eye</span>
                </div>
                <div
                  onClick={() => {
                    if (frameShape === "Square") {
                      setFrameSahpe();
                      onSelectFrameShape();
                    } else {
                      setFrameSahpe("Square");
                      onSelectFrameShape("Square");
                    }
                  }}
                  className={
                    frameShape === "Square" ? "small-pro active" : "small-pro"
                  }
                >
                  <img src={square} alt="" />
                  <span>Square</span>
                </div>
                <div
                  onClick={() => {
                    if (frameShape === "Aviator") {
                      setFrameSahpe();
                      onSelectFrameShape();
                    } else {
                      setFrameSahpe("Aviator");
                      onSelectFrameShape("Aviator");
                    }
                  }}
                  className={
                    frameShape === "Aviator" ? "small-pro active" : "small-pro"
                  }
                >
                  <img src={aviator} alt="" />
                  <span>Aviator</span>
                </div>
                <div
                  onClick={() => {
                    if (frameShape === "Wayfarer") {
                      setFrameSahpe();
                      onSelectFrameShape();
                    } else {
                      setFrameSahpe("Wayfarer");
                      onSelectFrameShape("Wayfarer");
                    }
                  }}
                  className={
                    frameShape === "Wayfarer" ? "small-pro active" : "small-pro"
                  }
                >
                  <img src={wayfar} alt="" />
                  <span>Wayfarer</span>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header p-0" id="headingThree">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  FRAME COLOR
                </button>
              </h5>
            </div>
            <div
              id="collapseThree"
              className="collapse show"
              aria-labelledby="headingThree"
              style={{ height: '200px', overflowY: 'scroll' }}
            >
              <div className="card-body color-list">
                {colorOption.map((color, index) => (
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      value={color.value}
                      onClick={(e) => handleOnColorChange(e)}
                    />
                    <label className="custom-control-label">{color.text}</label>
                    <span className={`colordot ${color.value}`}></span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* brand  */}
          <div className="card">
            <div className="card-header p-0" id="headingFour">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Brand
                </button>
              </h5>
            </div>
            <div
              id="collapseFour"
              className="collapse"
              aria-labelledby="headingFour"
            >
              <div className="card-body color-list">
                {brandOption.map((brand, index) => (
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      value={brand.value}
                      onClick={(e) => handleOnBrandSelect(e)}
                    />
                    <label className="custom-control-label">{brand.text}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* material  */}
          <div className="card">
            <div className="card-header p-0" id="headingFive">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Material
                </button>
              </h5>
            </div>
            <div
              id="collapseFive"
              className="collapse"
              aria-labelledby="headingFive"
            >
              <div className="card-body color-list">
                {materialOption.map((material, index) => (
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      value={material.value}
                      onClick={(e) => handleOnMaterialSelect(e)}
                    />
                    <label className="custom-control-label">
                      {material.text}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* price  */}
          <div className="card">
            <div className="card-header p-0" id="headingSix">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  Price
                </button>
              </h5>
            </div>
            <div
              id="collapseSix"
              className="collapse"
              aria-labelledby="headingSix"
            >
              <div className="card-body color-list">
                {priceOption.map((price, index) => (
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      value={price.value}
                      onClick={(e) => handleOnPriceChange(e)}
                    />
                    <label className="custom-control-label">{price.text}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Gender */}
          <div className="card">
            <div className="card-header p-0" id="headingGender">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseGender"
                  aria-expanded="false"
                  aria-controls="collapseGender"
                >
                  Gender
                </button>
              </h5>
            </div>
            <div
              id="collapseGender"
              className="collapse"
              aria-labelledby="headingGender"
            >
              <div className="card-body color-list">
                {genderArr.map((data, index) => (
                  <div key={index} className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      name="customRadio"
                      className="custom-control-input"
                      value={data.value}
                      onClick={(e) => handleOnChange(e)}
                    />
                    <label className="custom-control-label">{data.text}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductFilter;
