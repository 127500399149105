const ProductList = ({ lens }) => {
  return (
    <div className="listing-block p-slider-type1">
      <div className="row">
        {lens && lens.length > 0 ? (
          lens.map((data) => (
            <div key={data._id} className="col-md-3">
              <div
                className="product-wrap"
                style={{ width: "100%", display: "inline-block" }}
              >
                <a
                  href={`/product/${data._id}`}
                  className="img-wrap"
                  tabIndex="0"
                >
                  <img src={data.imgCollection[0]} alt="" />
                </a>

                <div className="product-info-wrap">
                  <h2>{data.name}</h2>
                  <div className="ratting star4"></div>
                  <div className="price">
                    <span>{data.price > 0 && data.price !== null ? `₹${data.price}` : ''}</span>
                    ₹{data?.discount_price}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div style={{ fontSize: '25px', marginLeft: '15px' }}>No Product Available</div>
        )}
      </div>
    </div>
  );
};

export default ProductList;
