import {
    FETCH_PRODUCT_START,
    FETCH_PRODUCT_SUCCESS,
    FETCH_PRODUCT_FAILURE,
    FETCH_PRODUCT_SINGLE_START,
    FETCH_PRODUCT_SINGLE_SUCCESS,
    FETCH_PRODUCT_SINGLE_FAILURE,
    PRODUCT_FILTER_START,
    PRODUCT_FILTER_SUCCESS,
    PRODUCT_FILTER_FAILURE,
} from '../actions/ActionConstants';


const initialState = {
    productData: {
        data: [],
        inputData: {},
        error: false,
        loading: false,
    },
    singleProduct: {
        data: {},
        inputData: {},
        error: false,
        loading: false,
    },
    filterProduct: {
        data: [],
        inputData: {},
        error: false,
        loading: false,
    }
}

const ProductReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_PRODUCT_START:
            return {
                ...state,
                productData: {
                    data: [],
                    inputData: action.data,
                    error: false,
                    loading: true,
                }
            };
        case FETCH_PRODUCT_SUCCESS:
            return {
                ...state,
                productData: {
                    data: action.data,
                    inputData: {},
                    error: false,
                    loading: false,
                }
            };
        case FETCH_PRODUCT_FAILURE:
            return {
                ...state,
                productData: {
                    data: [],
                    inputData: {},
                    error: action.data,
                    loading: false,
                }
            };
        case FETCH_PRODUCT_SINGLE_START:
            return {
                ...state,
                singleProduct: {
                    data: {},
                    inputData: action.data,
                    error: false,
                    loading: true,
                }
            };
        case FETCH_PRODUCT_SINGLE_SUCCESS:
            return {
                ...state,
                singleProduct: {
                    data: action.data,
                    inputData: {},
                    error: false,
                    loading: false,
                }
            };
        case FETCH_PRODUCT_SINGLE_FAILURE:
            return {
                ...state,
                singleProduct: {
                    data: {},
                    inputData: {},
                    error: action.data,
                    loading: false,
                }
            };
        case PRODUCT_FILTER_START:
            return {
                ...state,
                filterProduct: {
                    data: [],
                    inputData: action.data,
                    error: false,
                    loading: true,
                }
            };
        case PRODUCT_FILTER_SUCCESS:
            return {
                ...state,
                filterProduct: {
                    data: action.data,
                    inputData: {},
                    error: false,
                    loading: false,
                }
            };
        case PRODUCT_FILTER_FAILURE:
            return {
                ...state,
                filterProduct: {
                    data: [],
                    inputData: {},
                    error: action.data,
                    loading: false,
                }
            };
        default:
            return state;
    }
};

export default ProductReducer;
