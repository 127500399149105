/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Logo from "../../assets/images/new-logo.svg";
import UserSolid from "../../assets/images/user-solid.svg";
import Cart from "../../assets/images/cart.svg";
import Login from "../Login/Login";
import { isLogin } from "../../utils/index";
import ApiMethods from "../../utils/ApiMethods";
import "./Header.css";
import { Link } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import carts from "../../assets/images/carts.svg"
import login from "../../assets/images/login.svg"
import DehazeIcon from '@mui/icons-material/Dehaze';
import CloseIcon from '@mui/icons-material/Close';
import chasmaglass from "../../assets/images/glasses-chasma.png"

const Header = (props) => {
  let cartItems = 0;
  const cartData = JSON.parse(sessionStorage.getItem("products")) || [];
  const [openLogin, setOpenLogin] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState('');
  const clickRef = useRef();


  if (cartData.length > 0) {
    cartItems = cartData.reduce((acc, curr) => {
      return acc = acc + curr.qty;
    }, 0)
  }

  useEffect(() => {
    async function fetchData() {
      const response = await ApiMethods.getMethod("user/home/products");
      if (response?.data?.success) {
        setProducts([...response.data.data]);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    document.querySelectorAll('.nav-item').forEach((item) => {
      item.classList.remove('show');
    })
    if (selected) {
      selected.classList.add('show');
    }
  }, [selected])

  const handleCart = () => {
    props.history.push("/cart");
  };

  const filteredProducts = products.filter((product) => {
    if (search) {
      if (
        product.type.toLowerCase().includes(search) ||
        product.brand.toLowerCase().includes(search) ||
        product.category.toLowerCase().includes(search) ||
        (product?.name && product.name.toLowerCase().includes(search)) ||
        (product?.materials && product.materials.toLowerCase().includes(search)) ||
        (product?.technical_infornation && product.technical_infornation.toLowerCase().includes(search))
      ) {
        return product;
      }
    }
  });

  const handleSearchChange = (value) => {
    setSearch(value);
    props.history.push(`/search`, filteredProducts);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    props.history.push(`/search`, filteredProducts);
    setIsClick(true);
  };

  const handleAddClass = (e) => {
    e.preventDefault();
    if (selected === e.target.parentNode) {
      setSelected('');
    } else {
      setSelected(e.target.parentNode);
    }
  }

  return (
    <>
      <header>
        <div className="navigation-wrap bg-light start-header start-style">
          <div className="container">
            <div className="row align-items-center chip-nav-container bg-header pa-header ">
              <div className="chip-nav col-sm-9 col-md-9 col-lg-6 blog-none">
                <ul className="text-gap">
                  <li>
                    <a href={`mailto:Help@Chasmaplanet.Com`}>Help@Chasmaplanet.Com</a>
                  </li>
                  <li>
                    <a href="tel:123456789">Call Us At : 123456789</a>
                  </li>
                </ul>
              </div>
              <div className=" d-flex chip-nav col-sm-3 col-md-3 col-lg-6 justify-content-end ">
                <ul className="text-gap">
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/store-locator">Store Locator</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="nav-top px-4 px-xl-4">
            <div className="container h-container">
              <div className="row justify-content-start align-items-center m-0 user-space">
                <div className="nav-brand pr-xl-2 col-3">
                  <a className="d-flex navbar-brand p-0 m-0 chasma-imgs" href="/">
                    <img src={chasmaglass} />
                    <h4 className="mb-0 chasma-h"><a>Chasmaplanet</a></h4>
                  </a>
                </div>
                <div className={`nav-search d-lg-block ${isOpen ? 'show-menu' : 'd-none'}  col-lg-5 col-sm-2 `}>
                  <ul className="d-flex navbar-nav ml-auto py-4 py-md-0 mob-dev-slide">
                    <li className="cross-icon d-lg-none">
                      <a>
                        <CloseIcon onClick={() => setOpen(false)} />
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/" className="nav-link">
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        // data-toggle="dropdown"
                        onClick={(e) => {
                          handleAddClass(e)
                        }}
                        href="#"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        GLASSES
                      </a>
                      <div className="glass-dropdown">
                        <a
                          // className="dropdown-item"
                          href="/product-list/Eyeglasses"
                        >
                          Eye Glasses
                        </a>
                        <a
                          // className="dropdown-item"
                          href="/product-list/Sunglasses"
                        >
                          Sun Glasses
                        </a>
                        <a
                          // className="dropdown-item"
                          href="/product-list/Computer Glasses"
                        >
                          Computer Glasses
                        </a>
                        <a
                          // className="dropdown-item"
                          href="/product-list/Reading Glasses"
                        >
                          Reading Glasses
                        </a>
                      </div>
                    </li>

                    <li className="nav-item">
                      <a
                        className="nav-link "
                        // data-toggle="dropdown"
                        href="#"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onClick={(e) => {
                          handleAddClass(e)
                        }}
                      >
                        Contact Lens
                      </a>
                      <div className="glass-dropdown">
                        <a
                          // className="dropdown-item"
                          href="/product-list/Contact Lens?disposable=Daily"
                        >
                          Daily Disposable
                        </a>
                        <a
                          // className="dropdown-item"
                          href="/product-list/Contact Lens?disposable=Monthly"
                        >
                          Monthly Disposable
                        </a>
                        <a
                          // className="dropdown-item"
                          href="/product-list/Contact Lens?disposable=Yearly"
                        >
                          Yearly Disposable
                        </a>
                      </div>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        // data-toggle="dropdown"
                        href="#"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onClick={(e) => {
                          handleAddClass(e)
                        }}
                      >
                        ACCESSORIES
                      </a>
                      <div className="glass-dropdown">
                        <a
                          // className="dropdown-item"
                          href="/product-list/Accessories?category=Contact Lens Case"
                        >
                          Contact Lens Case
                        </a>
                        <a
                          // className="dropdown-item"
                          href="/product-list/Accessories?disposable=Spectacle Case"
                        >
                          Spectacle Case
                        </a>
                        <a
                          // className="dropdown-item"
                          href="/product-list/Accessories?disposable=Sunglasses Case"
                        >
                          Sunglasses Case
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 col-md-8 col-sm-8 d-flex align-items-center cart-user-login mob-login">
                  <form
                    onSubmit={handleSearch}
                    className="form-inline mb-0 mx-auto search-hide-bar"
                  >
                    <input
                      className="form-control"
                      // type="search"
                      placeholder="Search"
                      onChange={(e) => {
                        setSearch(e.target.value.toLowerCase());
                        setIsClick(false);
                      }}
                      value={search}
                      aria-label="Search"
                    />
                    <button className="btn btn-success search-icon" type="submit">
                      <SearchIcon />
                    </button>
                  </form>
                  {/* <DehazeIcon /> */}
                  <div className="display d-none">
                    {!isClick &&
                      filteredProducts.map((product) => {
                        let val =
                          product.category +
                          " " +
                          product.type +
                          " " +
                          product.brand +
                          " " +
                          product.name;
                        return (
                          <div
                            onClick={() => handleSearchChange(val)}
                            key={product._id}
                            className="product"
                          >
                            <h6>{val}</h6>
                          </div>
                        );
                      })}
                  </div>
                  <div className="login-cartwrap d-flex align-items-center">
                    <div style={{ position: 'relative' }} className="cart-wrap px-1 ns">
                      <a className="py-lg-0" onClick={handleCart} style={{cursor:'pointer'}}>
                        {cartData.length > 0 && (
                          <span className="count">{cartItems}</span>
                        )}
                        <img src={Cart} alt="" className="mob-img d-none" />
                        <img src={carts} alt="" />
                      </a>
                    </div>
                    <div className="nav-account-wrap ps-2 mr-4 mr-lg-0 nav-icon">
                      {isLogin() ? (
                        <a href="/my-account" className="py-lg-0">
                          <img className="d-md-none d-sm-none user-none" src={UserSolid} alt="" />
                          <div className="user-name-center">
                            <span>
                              {sessionStorage.getItem("name")}
                            </span>
                          </div>
                        </a>
                      ) : (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => setOpenLogin(true)}
                          className="py-lg-0"
                        >
                          <img className="d-md-none d-none" src={UserSolid} alt="" />
                          {/* <img src={login} alt="" /> */}
                          <p>login/signup</p>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nvbar-bottom d-flex flex-wrap justify-content-between align-items-center">
            <div className="container tab-login">
              <div className="nav-search d-lg-none mob-devicewrap row">

                <form
                  onSubmit={handleSearch}
                  className="form-inline mb-0 mx-auto col-9"
                >

                  <input
                    className="form-control"
                    type="search"
                    placeholder="Search Glasses, Type, Brand Name"
                    onChange={(e) => {
                      setSearch(e.target.value.toLowerCase());
                      setIsClick(false);
                    }}
                    value={search}
                    aria-label="Search"
                  />
                  <button className="btn btn-success" type="submit">
                    Search
                  </button>
                </form>
                <div className="col-3 toggle-col">
                  <div className="toggle-bar-n toggle-icon">
                    <DehazeIcon onClick={() => setOpen(true)} className="lines-icon" />
                  </div>
                </div>
                <div className="display">
                  {!isClick &&
                    filteredProducts.map((product) => {
                      let val =
                        product.category +
                        " " +
                        product.type +
                        " " +
                        product.brand +
                        " " +
                        product.name;
                      return (
                        <div
                          onClick={() => handleSearchChange(val)}
                          key={product._id}
                          className="product"
                        >
                          <h6>{val}</h6>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Login openLogin={openLogin} onLoginClose={() => setOpenLogin(false)} />
    </>
  );
};

const mapStateToPros = (state) => ({
  filterData: state.products.filterProduct,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default withRouter(connect(mapStateToPros, mapDispatchToProps)(Header));
