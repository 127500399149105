import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import newBanner from '../../assets/images/new-banner.png';
import whyChashmaplanet1 from '../../assets/images/whyframecraft-1.svg';
import whyChashmaplanet2 from '../../assets/images/whyframecraft-2.svg';
import whyChashmaplanet3 from '../../assets/images/whyframecraft-3.png';
import favourites from '../../assets/images/favourites.png';
import iconMail from '../../assets/images/icon-mail.png';
import iconPhone from '../../assets/images/icon-telephone.png';
import contactBg from '../../assets/images/contact-bg.jpg';
import './ApplyFranchise.css';
import MapContainer from '../helper/MapContainer';
import { fetchStoreLocatorStart, saveContactUsStart } from '../../store/actions/StoreLocatorAction';

const defaultProps = {
    lat: 28.7041,
    lng: 77.1025
}

const ApplyFranchise = (props) => {
    const [stores, setStores] = useState([]);
    const [franchiseData, setFranchiseData] = useState({
        name: '',
        city: '',
        phone_number: '',
        email: '',
        propertyType: ''
    });

    function fetchStores(lat, lng) {
        props.dispatch(
          fetchStoreLocatorStart({
            latitude: lat,
            longitude: lng,
          })
        );
    }

    useEffect(() => {
        if (!props.storeLocation.loading) {
            setStores([...props.storeLocation.data])
        }
    }, [!props.storeLocation.loading])

    useEffect(() => {
        fetchStores(defaultProps.lat, defaultProps.lng);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFranchiseData((prevState) => ({ ...prevState, [name]: value }))
    }

    const clearForm = () => {
        setFranchiseData({ name: '',
        city: '',
        phone_number: '',
        email: '',
        propertyType: '' });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        await props.dispatch(
            saveContactUsStart({
              name: franchiseData.name,
              city: franchiseData.city,
              phone_number: franchiseData.phone_number,
              email: franchiseData.email,
              propertyType: franchiseData.propertyType,
              isFranchise: true    
            })
          );
        clearForm();
    }
    return (
        <>
            <div className="topBanner" style={{ backgroundImage: `url(${newBanner})` }}>
                <div className="bannerInfo custom-paddingx">
                    <h4 className="fs-45 fw-medium">Join The Fastest Growing</h4>
                    <h2 className="fs-65 fw-bold mb-0">Eye Wear Brand In India</h2>
                </div>
            </div>
            <section className="centerInfoSection custom-py-60 custom-paddingx text-center">
                <h4 className="fs-24 fc-blue fw-semibold mb-2 mt-3">OWN A SUCCESSFUL BUSINESS</h4>
                <h2 className="fs-45 fw-bold mb-3 mb-lg-4">Proven Business model with 28 profitable operational</h2>
                <p className="fs-20 fw-medium">Chashmaplanet.com is lead by Industry veterans carrying 20 years of hard core eye wear industry experience. It offer wide range of international and in-house eyewear brands like lenses, sunglasses and spectacles. The brand is currently expanding rapidly through FOCO Model and aiming to reach 100 OUTLETS in coming few months.</p>
                <p className="fs-20 fw-medium mb-0">The eyewear industry is witnessing a shape shift to become an organised sector. Chashmaplanet product range, delightful customer experience, warranties and affordable pricing has helped the brand capturing the bigger market share and acquiring customers</p>
            </section>
            <section className="whyFramecraft custom-py-60 custom-paddingx">
                <h2 className="fs-45 fw-bold text-center mb-5 mt-3">WHY CHASHMA PLANET</h2>
                <div className="wfc-row pt-2">
                    <div className="wfc-wrap">
                        <img src={whyChashmaplanet1} />
                        <h4 className="fs-24 fc-blue fw-semibold mb-2">FRANCHISE FACTS</h4>
                        <p className="fs-22 fw-medium">Investment Starting 18 Lacs FOCO Model Payback 18 -24 Months Break-Even from First Day</p>
                    </div>
                    <div className="wfc-wrap">
                        <img src={whyChashmaplanet2} />
                        <h4 className="fs-24 fc-blue fw-semibold mb-2">SUPPORT</h4>
                        <p className="fs-22 fw-medium">Site Evaluation Investment in Inventory/ Stock Marketing and Day to Day Operations State of the Art Technology Trained and Certified Store Staff</p>
                    </div>
                    <div className="wfc-wrap">
                        <img src={whyChashmaplanet3} />
                        <h4 className="fs-24 fc-blue fw-semibold mb-2">CHOOSE Chashma Planet</h4>
                        <p className="fs-22 fw-medium">Managed by Company Business Model Huge Variety & Stylish Designs Affordable Pricing Led by Industry Experts Operating Expenses Managed by chashmaplanet</p>
                    </div>
                </div>
            </section>
            <section className="journyform custom-py-60 custom-paddingx">
                <div className="row mx-0">
                    <div className="col-lg-6 pe-lg-5 mb-3">
                        <MapContainer
                            stores={stores}
                            mapHeight={`88vh`}
                            customPadding={'15px'}
                            customMarginTop={'14px'}
                        />
                    </div>
                    <div className="col-lg-6 ps-lg-5 mb-3">
                        <h4 className="fs-24 fc-blue fw-semibold mb-4 mt-4">READY TO START YOUR GROWTH JOURNEY?</h4>
                        <form action="">
                            <input type="text" placeholder="Name" name='name' value={franchiseData.name} onChange={handleInputChange} />
                            <input type="email" placeholder="Email" name='email' value={franchiseData.email} onChange={handleInputChange} />
                            <input type="text" placeholder="Phone Number" name='phone_number' value={franchiseData.phone_number} onChange={handleInputChange} />
                            <input type="text" placeholder="City" name='city' value={franchiseData.city} onChange={handleInputChange} />
                            <div className="propertyType">
                                <p className="fs-22 fw-medium mb-0">What property type will you open the store at?</p>
                                <div className="crb-wrap">
                                    <label className="price-card">
                                        <input name="plan" value="shared" className="radio" type="radio" checked={franchiseData.propertyType === 'Owner'} onChange={() => setFranchiseData((prevState) => ({ ...prevState, propertyType: 'Owner' }))} />
                                        <span className="plan-details">
                                            Owner
                                        </span>
                                    </label>
                                    <label className="price-card">
                                        <input name="plan" value="shared" className="radio" type="radio" checked={franchiseData.propertyType === 'Rented/Leased'} onChange={() => setFranchiseData((prevState) => ({ ...prevState, propertyType: 'Rented/Leased' }))} />
                                        <span className="plan-details">
                                            Rented/Leased
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <button onClick={handleSubmit} type="submit" className="btn btn-primary custom-yellow-btn w-100">Submit Request</button>

                        </form>
                    </div>
                </div>
            </section>
            <div className="custom-call2 row mb-5" style={{ backgroundImage: `url(${contactBg})` }}>
                <div className="col-md-6 left ">
                    <img src={favourites} />
                    <p className="fs-22 fw-medium">Create a parallel income without<br />leaving your job/ existing business</p>
                </div>
                <div className="col-md-6 right" >
                    <a title="franchiese@chashmaplanet.com" href="mailto:franchiese@chashmaplanet.com">
                        <img src={iconMail} />franchiese@chashmaplanet.com
                    </a>
                    <a href="tel:+91 9310229604" title="tel:+91 9310229604">
                        <img src={iconPhone} />+91 9310229604
                    </a>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    storeLocation: state.storeLocator.storeLocatorData
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplyFranchise);
