/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { fetchSingleProductStart } from "../../store/actions/ProductAction";
import star from "../../assets/images/Icon awesome-star.svg";
import reviewstar from "../../assets/images/review-star.svg";
import { createNotification } from "react-redux-notify";
import { getErrorNotificationMessage } from "./NotificationMessage";
import { useDispatch } from "react-redux";
import SelectLensModal from "./SelectLensModal";
import { addItem } from "../../utils";
import BifocalModal from "./BifocalModal";
import ImageZoom from "./ImageZoom";

const reviewData = [
  {
    id: 1,
    username: "RADHA. banglore",
    reviewText:
      "Tried it for the first time and totally in love with the experience. Economical, wide range of options & impeccable QUALITY!",
  },
  {
    id: 2,
    username: "SHRADDHA R. PUNE",
    reviewText:
      "Great price, great quality, great styles, great customer service. First-grade products with affordable prices. I’ve already recommended chashmaplanet to three friends.",
  },
  {
    id: 3,
    username: "ABHIRAM D. VIJAYAWADA",
    reviewText:
      "My friend had recommended me to chashmaplanet & I totally agree with her statement - Stylish products & budget friendly!",
  },
  {
    id: 4,
    username: "SIMRAN D. HYDERABAD",
    reviewText: `Perfect spot for purchasing spectacles! Staff was kind enough to let me try hell lot of frames(Yeah! I'm fussy!). You guys are bomb!`,
  },
];

const SingleProduct = (props) => {
  const location = useLocation();
  const hideMagnifier = window.innerWidth <= 760;
  const dispatch = useDispatch();
  const lensId = location.pathname.split("/")[2];
  const [showPower, setShowPower] = useState(false);
  const [type, setType] = useState("");
  const [powerType, setPowerType] = useState("");
  const [showLensModal, setShowlensModal] = useState(false);
  const [colorIndex, setColorIndex] = useState(0);
  const [index, setIndex] = useState(0);


  const handleSelectLens = (event) => {
    event.preventDefault();
    if (!type) {
      const notificationMessage = getErrorNotificationMessage(
        "Please Select Frame or Lens"
      );
      dispatch(createNotification(notificationMessage));
    } else if (type === "Only Frame") {
      let products = [];
      if (sessionStorage.getItem("products")) {
        addItem(props.singleLens.data, powerType, true);
      } else {
        sessionStorage.setItem("products", JSON.stringify(products));
        addItem(props.singleLens.data, powerType, true);
      }
      props.history.push("/cart");
    } else if (type === "Frame + Lens") {
      if (!powerType) {
        const notificationMessage = getErrorNotificationMessage(
          "Please Select Power Type"
        );
        dispatch(createNotification(notificationMessage));
      } else {
        setShowlensModal(true);
      }
    }
  };

  useEffect(() => {
    props.dispatch(
      fetchSingleProductStart({
        lensId: lensId,
      })
    );
  }, []);
  return (
    <>
      <div className="customPadding">
        <div className="product-detail">
          <div className="detail-left">
            {props.singleLens.data?.color && (
              <>
                <div className="image-container">
                  {hideMagnifier ?
                    <img
                      src={props.singleLens.data?.color[colorIndex].images[index]}
                      height={400}
                      width={400}
                      alt=""
                    />
                    :
                    <ImageZoom
                      width={600}
                      height={400}
                      zoomScale={3}
                      img={props.singleLens.data?.color[colorIndex].images[index]}
                    />
                  }
                </div>
                <div className="small-images-container">
                  {props.singleLens.data?.color[colorIndex].images.map(
                    (image, i) => (
                      <img
                        key={i}
                        src={image}
                        className={
                          i === index
                            ? "small-image selected-image"
                            : "small-image"
                        }
                        onClick={() => {
                          setIndex(i)
                        }}
                        alt=""
                      />
                    )
                  )}
                </div>
              </>
            )}
          </div>
          {/* <div className="detail-left">
            {props.singleLens.data?.color && (
              <>
                {props.singleLens.data?.color[colorIndex].images.map(
                  (image, index) => (
                    <a key={index} href="#" className="detail-img">
                      <img
                        onClick={() => {
                          setShowImageModal(true);
                          setImageUrl(image);
                        }}
                        src={image}
                        alt=""
                      />
                    </a>
                  )
                )}
              </>
            )}
          </div> */}

          <div className="detail-right">
            <div className="heading-wrap">
              <h6>{props.singleLens.data.name}</h6>
              <h2>
                {props.singleLens.data.type
                  ? props.singleLens.data?.material +
                  " " +
                  props.singleLens.data?.type +
                  " " +
                  props.singleLens.data?.shape +
                  " " +
                  props.singleLens.data?.category
                  : ""}
              </h2>
            </div>
            {props.singleLens.data?.discount_price && (
              <div className="price">
                <span>{props.singleLens.data?.price > 0 && props.singleLens.data?.price !== null ? `₹${props.singleLens.data?.price}` : ''}</span>
                {`₹${props.singleLens.data?.discount_price}`}
              </div>
            )}
            {props.singleLens.loading ||
              props.singleLens.data?.category === "Accessories" ||
              props.singleLens.data?.category === "Contact Lens" ? null : (
              <>
                <div className="color-wrap">
                  <p>
                    Color :
                    <span className="color-dot-wrap">
                      {props.singleLens.data?.color &&
                        props.singleLens.data.color.map((color, index) => (
                          <a
                            href="#"
                            onClick={() => setColorIndex(index)}
                            className={color.color}
                          ></a>
                        ))}
                    </span>
                  </p>
                </div>
                <div className="btn-group">
                  <Link
                    to="#"
                    onClick={() => {
                      setShowPower(false);
                      setType("Only Frame");
                    }}
                    className={
                      type === "Only Frame" ? "frame-btn active" : "frame-btn"
                    }
                  >
                    Only Frame
                  </Link>
                  <Link
                    to="#"
                    onClick={() => {
                      setShowPower(true);
                      setType("Frame + Lens");
                    }}
                    className={
                      type === "Frame + Lens" ? "frame-btn active" : "frame-btn"
                    }
                  >
                    Frame + Lens
                  </Link>
                </div>
              </>
            )}
            <div className="select-div-wrap">
              {showPower && (
                <>
                  <p>Select Power Type</p>
                  <div className="btn-group">
                    <Link
                      to="#"
                      onClick={() => setPowerType("Single Vision")}
                      className={
                        powerType === "Single Vision"
                          ? "frame-btn active"
                          : "frame-btn"
                      }
                    >
                      Single Vision
                    </Link>
                    <Link
                      to="#"
                      onClick={() => setPowerType("Zero Power")}
                      className={
                        powerType === "Zero Power"
                          ? "frame-btn active"
                          : "frame-btn"
                      }
                    >
                      Zero Power
                    </Link>
                    <Link
                      to="#"
                      onClick={() => setPowerType("Bifocal/Progressive")}
                      className={
                        powerType === "Bifocal/Progressive"
                          ? "frame-btn active"
                          : "frame-btn"
                      }
                    >
                      Bifocal/Progressive
                    </Link>
                  </div>
                </>
              )}

              {props.singleLens.data?.category === "Accessories" ||
                props.singleLens.data?.category === "Contact Lens" ? (
                <a
                  href="#"
                  onClick={() => {
                    let products = [];
                    if (sessionStorage.getItem("products")) {
                      addItem(props.singleLens.data, powerType,true);
                    } else {
                      sessionStorage.setItem(
                        "products",
                        JSON.stringify(products)
                      );
                      addItem(props.singleLens.data, powerType ,true);
                    }
                    props.history.push("/cart");
                  }}
                  className="select-btn"
                  data-toggle="modal"
                  data-target="#select-lenses"
                >
                  Add to cart
                  <br />
                  <span>Upload Reports After The Payment</span>
                </a>
              ) : (
                <a
                  href="#"
                  onClick={handleSelectLens}
                  className="select-btn"
                  data-toggle="modal"
                  data-target="#select-lenses"
                >
                  {type === "Only Frame" ? "Add to cart" : "Select Lenses"}
                  <br />
                  <span>Upload Reports After The Payment</span>
                </a>
              )}
            </div>

            {/* <!-- other-info --> */}
            <div className="other-info-wrapper">
              <div id="otherinfo-accordion" className="accordion">
                <div className="card">
                  <div className="card-header p-0" id="info1">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapseInfo1"
                        aria-expanded="true"
                        aria-controls="collapseInfo1"
                      >
                        Technical Information
                      </button>
                    </h5>
                  </div>

                  <div
                    id="collapseInfo1"
                    className="collapse show"
                    aria-labelledby="info1"
                    data-parent="#otherinfo-accordion"
                  >
                    {props.singleLens.data?.technical_infornation &&
                      props.singleLens.data.technical_infornation.includes(
                        "<"
                      ) ? (
                      <div className="card-body">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: props.singleLens.data.technical_infornation,
                          }}
                        ></p>
                      </div>
                    ) : (
                      <div className="card-body">
                        <p>{props.singleLens.data.technical_infornation}</p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="card">
                  <div className="card-header p-0" id="info4">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapseInfo4"
                        aria-expanded="true"
                        aria-controls="collapseInfo4"
                      >
                        Review(128)
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseInfo4"
                    className="collapse"
                    aria-labelledby="info4"
                    data-parent="#otherinfo-accordion"
                  >
                    <div className="card-body">
                      <h4 className="rate-text">Reviews & Ratings</h4>
                      <div className="rating-row">
                        <div className="rating-wrap">
                          <h2>
                            4.9 <img src={star} alt="" />{" "}
                          </h2>
                          <p>128+ Buyers</p>
                        </div>
                        <div className="range-rating">
                          <div className="slide-row">
                            <div className="text-wrap">5</div>
                            <div className="middle-range">
                              <div className="bar-5"></div>
                            </div>
                            <div className="right-text">150</div>
                          </div>
                          <div className="slide-row">
                            <div className="text-wrap">4</div>
                            <div className="middle-range">
                              <div className="bar-4"></div>
                            </div>
                            <div className="right-text">100</div>
                          </div>
                          <div className="slide-row">
                            <div className="text-wrap">3</div>
                            <div className="middle-range">
                              <div className="bar-3"></div>
                            </div>
                            <div className="right-text">15</div>
                          </div>
                          <div className="slide-row">
                            <div className="text-wrap">2</div>
                            <div className="middle-range">
                              <div className="bar-2"></div>
                            </div>
                            <div className="right-text">5</div>
                          </div>
                          <div className="slide-row">
                            <div className="text-wrap">1</div>
                            <div className="middle-range">
                              <div className="bar-1"></div>
                            </div>
                            <div className="right-text">1</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- review section --> */}
        <div className="bottom-review-wrapper">
          <h1>Reviews</h1>
          {reviewData.map((data) => {
            return (
              <div key={data.id} className="review-row">
                <div className="review-img">
                  {props.singleLens.data.imgCollection && (
                    <img src={props.singleLens.data.imgCollection[0]} alt="" />
                  )}
                  {props.singleLens.data.imgCollection && (
                    <img src={props.singleLens.data.imgCollection[0]} alt="" />
                  )}
                </div>
                <div className="pro-head">
                  <h6>{data.username}</h6>
                  <img src={reviewstar} alt="" />
                </div>
                <div className="pro-content">{data.reviewText}</div>
              </div>
            );
          })}
        </div>
      </div>
      {/* <ImageModal
        openImage={showImageModal}
        closeImage={setShowImageModal}
        imageUrl={ImageUrl}
      /> */}
      {powerType === "Bifocal/Progressive" ? (
        <BifocalModal
          powerType={powerType}
          showLensModal={showLensModal}
          setShowlensModal={() => setShowlensModal(false)}
        />
      ) : (
        <SelectLensModal
          powerType={powerType}
          showLensModal={showLensModal}
          setShowlensModal={() => setShowlensModal(false)}
        />
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  singleLens: state.products.singleProduct,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default withRouter(
  connect(mapStateToPros, mapDispatchToProps)(SingleProduct)
);
