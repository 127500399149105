import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import reviewstar from "../../assets/images/review-star.svg";
import ApiMethods from "../../utils/ApiMethods";
import { createNotification } from "react-redux-notify";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "./NotificationMessage";

const Membership = (props) => {
  window.scrollTo(0, 0);
  const membershipPrice = 300;
  const membershipTax = 18;
  const tax = (membershipPrice / 100) * membershipTax;
  const dispatch = useDispatch();
  const history = useHistory();
  const handlePayment = async () => {
    const response = await ApiMethods.postMethod("user/razor/order", {
      amount: membershipPrice.toString(),
    });
    const { data } = response;
    const options = {
      key: process.env.REACT_APP_RAZORPAY,
      // key: 'rzp_test_euDMQDvp43A8QQ',
      name: "Chashmaplanet.com",
      description: "",
      amount: membershipPrice * 100,
      order_id: data.id,
      handler: async (response) => {
        try {
          const captureResponse = await ApiMethods.postMethod(
            `user/add/membership`,
            {
              price: membershipPrice.toString(),
              paymentToken: response.razorpay_payment_id,
            }
          );
          if (captureResponse?.data.user) {
            const notificationMessage = getSuccessNotificationMessage(
              captureResponse.data.msg
            );
            dispatch(createNotification(notificationMessage));
            sessionStorage.setItem('isMembership', true);
            history.push("/");
          } else {
            const notificationMessage = getErrorNotificationMessage(
              "Something went wrong"
            );
            dispatch(createNotification(notificationMessage));
          }
        } catch (err) {
          console.log(err);
        }
      },
      theme: {
        color: "#686CFD",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };
  return (
    <div className="cart-page">
      <div className="product-detail">
        <div className="cart-left">
          <div id="cart-accordion" className="accordion">
            <div className="card">
              <div className="card-body">
                <div className="cart-row">
                  <div className="pro-detail-block">
                    <div className="eql-pro">
                      <div className="pro-left">
                        <h2>One Year Elite Membership</h2>
                        <p className="rating-text">
                          5.0 Rating <img src={reviewstar} alt="" />
                        </p>
                      </div>
                      <div className="pro-right">
                        <p>
                          <span className="blkclor">₹{membershipPrice} </span>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* payment info */}
            <div className="card">
              <div className="card-body">
                <div className="col-md-12">
                  <button
                    style={{ float: "right" }}
                    type="submit"
                    className="btn btn-primary custom-border-btn"
                    onClick={handlePayment}
                  >
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cart-right ">
          <div className="pro-list-innerblock">
            <h4>Membership Details</h4>
            <p>
              Membership Price <span>₹{membershipPrice}</span>{" "}
            </p>

            <p className="total-text">
              Total Amount Payable
              <span>₹{membershipPrice}</span>{" "}
              <h6 style={{ position: 'absolute', marginTop: '50px', fontSize: '12px' }}>{`(total tax incl.): ₹${tax}`}</h6>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Membership;
