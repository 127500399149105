import React, { useState, useEffect } from "react";
import moment from "moment";
import call from "../../assets/images/call.svg";
import time from "../../assets/images/time.svg";
import MapContainer from "./MapContainer";
import { connect } from "react-redux";
import {
  fetchStoreLocatorStart,
  saveContactUsStart,
} from "../../store/actions/StoreLocatorAction";
import Autocomplete from "react-google-autocomplete";
import ApiMethods from '../../utils/ApiMethods'

const initialState = {
  name: "",
  city: "",
  phone_number: "",
  email: "",
};

const defaultProps = {
  lat: 28.7041,
  lng: 77.1025
}

const StoreLocator = (props) => {
  const [{ name, city, phone_number, email }, setContactInputData] =
    useState(initialState);
  const [places, setPlaces] = useState({});
  const [stores, setStores] = useState([]);
  const [emailError, setEmailError] = useState("");
  const handleContactInputChange = (event) => {
    const { value, name } = event.currentTarget;
    setContactInputData((prevState) => ({ ...prevState, [name]: value }));


    if (name === "email") {
      const isValidEmail = /\S+@\S+\.\S+/.test(value);
      setEmailError(isValidEmail ? "" : "Please enter a valid email address");
    }
  };

  const clearForm = () => {
    setContactInputData({ ...initialState });
  };

  const handleContactSubmit = (event) => {
    event && event.preventDefault();


    if (!emailError) {
      props.dispatch(
        saveContactUsStart({
          name: name,
          city: city,
          phone_number: phone_number,
          email: email,
          isFranchise: false,
        })
      );
      clearForm();
    }
    // props.dispatch(
    //   saveContactUsStart({
    //     name: name,
    //     city: city,
    //     phone_number: phone_number,
    //     email: email,
    //     isFranchise: false,
    //     // propertyType : property
    //   })
    // );
    // clearForm();
  };

  async function fetchNearByStore(lat, lng) {
    const result = await ApiMethods.postMethod('user/shop/locate', {
      latitude: lat,
      longitude: lng,
    });
    if (result.data.success) {
      setStores([...result.data.data]);
    }
  }

  function fetchStores(lat, lng) {
    props.dispatch(
      fetchStoreLocatorStart({
        latitude: lat,
        longitude: lng,
      })
    );
  }

  useEffect(() => {
    if (!props.storeLocation.loading) {
      setStores([...props.storeLocation.data])
    }
  }, [!props.storeLocation.loading])

  useEffect(() => {
    fetchStores(defaultProps.lat, defaultProps.lng);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="customPadding">
      <div className="store-locator">
        <div className="store-left">
          <div className="card">
            <div className="card-header">
              <h4>STORE LOCATOR</h4>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label>We Will Search Nearest Store From You!</label>
                <Autocomplete
                  // apiKey="AIzaSyAqC53c90t-5Y39VsCofC4pqWwxgnubzlQ"
                  apiKey="AIzaSyAhUofS7MWXOh11xkj37N-wllbEWiECqSo"
                  style={{ width: "90%" }}
                  onPlaceSelected={(place) => {
                    if (place?.formatted_address) {
                      setPlaces(place);
                    }
                  }}
                  options={{
                    types: ["(regions)"],
                    componentRestrictions: { country: "in" },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="card address-card">
            <div className="card-header">
              <h4>STORE LOCATOR</h4>
            </div>
            <div className="card-body">
              {props.storeLocation.loading
                ? "Loading..."
                : stores.map((data) => (
                    <div key={data._id} className="address-wrap">
                      <h4>{data.name}</h4>
                      <p>{data.address}</p>
                      {data?.link && (
                      <a href={data.link}>link</a>
                      )}
                      <p>
                        {" "}
                        <img src={call} alt="" />
                        {data.phone_number}
                      </p>
                      <p>
                        {" "}
                        <img src={time} alt="" />{" "}
                        {moment(data.open_time).format("h:mm a")} To{" "}
                        {moment(data.close_time).format("h:mm a")}
                      </p>
                    </div>
                  ))}
            </div>
          </div>
        </div>
        <div id="contact-us-form" className="container">
        <div className="enquirey-form text-center">
          <h4>FRANCHISE <span>ENQUIRY</span></h4>
          <p>
            ENTER YOUR DETAILS BELOW.
            <br /> OUR TEAM WILL CONTACT YOU WITHIN 24 HOURS.
          </p>
          <form onSubmit={handleContactSubmit}>
            <div className="form-group">
              <input
                type="text"
                name="name"
                className="form-control"
                id="name"
                placeholder="Name"
                onChange={handleContactInputChange}
                value={name}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="city"
                className="form-control"
                id="city"
                placeholder="City"
                onChange={handleContactInputChange}
                value={city}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="phone_number"
                className="form-control"
                id="phone"
                placeholder="Phone Number"
                maxLength="10"
                pattern="\d{10}" 
                title="Please enter exactly 10 digits"
                onChange={handleContactInputChange}
                value={phone_number}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email Address"
                onChange={handleContactInputChange}
                value={email}
                required
              />
                {emailError && <p className="error-message" style={{color:'red'}}>{emailError}</p>}
            </div>
            <button
              disabled={props.contactData.loading}
              type="submit"
              className="btn btn-primary custom-yellow-btn"
            >
              Submit
            </button>
          </form>
        </div>
        <div className="store-map">
          <MapContainer
            getStores={fetchNearByStore}
            places={places}
            stores={stores}
          />
        </div>
      </div>
        
      </div>
      {/* <div id="contact-us-form" className="container">
        <div className="enquirey-form text-center">
          <h4>FRANCHISE ENQUIRY</h4>
          <p>
            ENTER YOUR DETAILS BELOW.
            <br /> OUR TEAM WILL CONTACT YOU WITHIN 24 HOURS.
          </p>
          <form onSubmit={handleContactSubmit}>
            <div className="form-group">
              <input
                type="text"
                name="name"
                className="form-control"
                id="name"
                placeholder="Name"
                onChange={handleContactInputChange}
                value={name}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="city"
                className="form-control"
                id="city"
                placeholder="City"
                onChange={handleContactInputChange}
                value={city}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="phone_number"
                className="form-control"
                id="phone"
                placeholder="Phone Number"
                maxLength="10"
                pattern="\d{10}" 
                title="Please enter exactly 10 digits"
                onChange={handleContactInputChange}
                value={phone_number}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email Address"
                onChange={handleContactInputChange}
                value={email}
                required
              />
                {emailError && <p className="error-message" style={{color:'red'}}>{emailError}</p>}
            </div>
            <button
              disabled={props.contactData.loading}
              type="submit"
              className="btn btn-primary custom-yellow-btn"
            >
              Submit
            </button>
          </form>
        </div>
      </div> */}
     
    </div>
  );
};

const mapStateToPros = (state) => ({
  storeLocation: state.storeLocator.storeLocatorData,
  contactData: state.storeLocator.contactUs,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(StoreLocator);
