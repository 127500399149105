import React from "react";
import {
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_ERROR,
} from "react-redux-notify";
import success from "../../assets/images/check-mark.svg";
import error from "../../assets/images/notification-warning.png";

export const getSuccessNotificationMessage = (message) => {
  return {
    message: message,
    type: NOTIFICATION_TYPE_SUCCESS,
    duration: 3000,
    canDismiss: true,
    icon: (
      <img
        src={success}
        alt="Success"
        width="40"
        height="40"
        style={{ display: "block", padding: "4px", boxSizing: "border-box", width: '100%', height: '100%', objectFit: 'contain', padding: '0px 2px 10px' }}
      />
    ),
  };
};

export const getErrorNotificationMessage = (message) => {
  return {
    message: message,
    type: NOTIFICATION_TYPE_ERROR,
    duration: 3000,
    canDismiss: true,
    icon: (
      <img
        src={error}
        alt="Error"
        style={{ display: "block", padding: "4px", boxSizing: "border-box", width: '100%', height: '100%', objectFit: 'contain', padding: '0px 2px 10px' }}
      />
    ),
  };
};
