import React, { useState } from "react";
import { withRouter } from "react-router";
import n1 from "../../assets/images/01.jpg"
import goldbg from "../../assets/images/gold-textured-background.png";
import rupixen from "../../assets/images/02.jpg";
import arun from "../../assets/images/03.jpg";
import youngguy from "../../assets/images/04.jpg";
import frontview from "../../assets/images/06.jpg";
import portraithappy from "../../assets/images/05.jpg";
import parcelsdelivery from "../../assets/images/07.jpg";
import bgshape from "../../assets/images/bg-shape.png";
import logo from "../../assets/images/new-logo.svg";
import Login from "../Login/Login";
import { isLogin, isMember } from "../../utils";

const EliteMembership = (props) => {
  const [openLogin, setOpenLogin] = useState(false);
  return (
    <div className="elite-wrapper">
      <div className="banner-wrap">
        <div className="img-wrapper">
          <img src={bgshape} alt="Perfect Shape" title="Perfect Shape" />
        </div>
        <div className="text-overlay">
          <div className="content-wrap">
            <a className="navbar-brand p-0 m-0" href="/" target="_blank">
              {/* <h4>Chashma Planet</h4> */}
              {/* <img src={logo} alt="" /> */}
            </a>
            <div className="page-heading">
              <h3>Chashma Planet</h3>
              <h1>Elite Membership Programme</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="benefit-section">
        <h2>EXTRA BENEFITS AND OFFERS</h2>
        <div className="custom-container">
          <div className="row">
            <div className="col-md-6 left-div">
              <img src={n1} alt="" />
              <div className="golden-text-wrap">
                <img src={goldbg} alt="" />
                <div className="text-wrap">
                  Buy 1 Get 1 On Eyeglasses, Sunglasses & Contact Lenses For One
                  Year.
                </div>
              </div>
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-6"></div>
            <div className="col-md-6 right-div">
              <img src={rupixen} alt="" />
              <div className="golden-text-wrap">
                <img src={goldbg} alt="" />
                <div className="text-wrap">
                  Loyality Points Everytime You Purchase Something
                </div>
              </div>
            </div>

            <div className="col-md-6 left-div">
              <img src={arun} alt="" />
              <div className="golden-text-wrap">
                <img src={goldbg} alt="" />
                <div className="text-wrap">
                  Free Optical Services For Spectacles Life Time.
                </div>
              </div>
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-6"></div>
            <div className="col-md-6 right-div">
              <img src={youngguy} alt="" />
              <div className="golden-text-wrap">
                <img src={goldbg} alt="" />
                <div className="text-wrap">
                  Hassle Free Warranty And Claim Process For FRAME BREAKAGE
                  ISSUES.
                </div>
              </div>
            </div>
            <div className="col-md-6 left-div">
              <img src={portraithappy} alt="" />
              <div className="golden-text-wrap">
                <img src={goldbg} alt="" />
                <div className="text-wrap">
                  Goodies, Accessories Up To Worth Rs. 1000 Free For Minimum 4
                  Repeat Purchases In A Year.
                </div>
              </div>
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-6"></div>
            <div className="col-md-6 right-div">
              <img src={frontview} alt="" />
              <div className="golden-text-wrap">
                <img src={goldbg} alt="" />
                <div className="text-wrap">
                  NO Question Asked, If Other Competitor Company Spectacle’s
                  Service Is Required To Be Done.
                </div>
              </div>
            </div>
            <div className="col-md-6 left-div">
              <img src={parcelsdelivery} alt="" />
              <div className="golden-text-wrap">
                <img src={goldbg} alt="" />
                <div className="text-wrap">
                  Free Delivery Across India And Shared Charges For
                  International Deliveries.
                </div>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
          {isMember() ? null : (
            <div className="btn-wrapper">
              <a
                onClick={() => {
                  if (!isLogin()) {
                    setOpenLogin(true);
                  } else {
                    props.history.push("/join-membership");
                  }
                }}
                className="custom-btn"
                style={{ cursor: "pointer" }}
              >
                JOIN MEMBERSHIP NOW
              </a>
            </div>
          )}
          <div className="term-section">
            <h6>*Terms & Conditions</h6>
            <ul>
              <li>
                FRAME BREAKAGE WARRANTY Is Limited To Order Number Only. If One
                Buys Single Frame Then Only Single Frame Will Be Covered. If One
                Buys Two Together Then Both Will Be Covered.
              </li>
              <li>
                Warranty Claim For Additional Frames Would Require Rs.100
                Additional Charges Per Frame.
              </li>
              <li>
                Friends And Family Who Will Come With Reference Of An Elite
                Member Will Enjoy Only Discount Offerings Not Warranty Claim
                Facility. For Enjoying The Facility Of The Same, Friends And
                Family Have To Be An Elite Member By Paying Rs.299.
              </li>
              <li>
                *If Spectacles Gets Broken In 3 Months, Claim Ratio Company To
                Elite Member Is 80:20
                <br />
                *If Spectacles Gets Broken In 6 Months, Claim Ratio Company To
                Elite Member Is 70:30 <br />
                *If More Than 6 Months And With In A Year Of Membership, Flat
                50% For Both Company And Elite Member. <br />
                *Frame Brands Excluded In Membership Programme: All
                International Brands Like Rayban, Oakley, Pierre Cardin,
                Carrera, Tommy Hilfiger, FCUK, Stepper, Guess, Fossil, Hugo
                Boss, Mark Jacobs, Emporio Armani. <br />
                *Lens Branded Excluded In Membership Programme: Carl Zeiss,
                Essilor, Hoya, Nikon. <br />
                *Claim Value Will Be On One Complete Spectacle Value Only, Not
                On Order Value.
                <br />
                *NO EXTRA DISCOUNT ON ORDER VALUE TO AVAIL BENEFITS OF
                Chashma Planet ELITE MEMBERSHIP*
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Login openLogin={openLogin} onLoginClose={() => setOpenLogin(false)} />
    </div>
  );
};

export default withRouter(EliteMembership);
