import {
    REGISTER_START,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,
    LOGIN_START,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    SAVE_USER_ADDRESS_START,
    SAVE_USER_ADDRESS_SUCCESS,
    SAVE_USER_ADDRESS_FAILURE,
    FETCH_USER_ADDRESS_START,
    FETCH_USER_ADDRESS_SUCCESS,
    FETCH_USER_ADDRESS_FAILURE,
    DELETE_USER_ADDRESS_START,
    DELETE_USER_ADDRESS_SUCCESS,
    DELETE_USER_ADDRESS_FAILURE,
    UPDATE_USER_ADDRESS_START,
    UPDATE_USER_ADDRESS_SUCCESS,
    UPDATE_USER_ADDRESS_FAILURE,
    FETCH_USER_PROFILE_START,
    FETCH_USER_PROFILE_SUCCESS,
    FETCH_USER_PROFILE_FAILURE,
    SOCIAL_LOGIN_START,
    SOCIAL_LOGIN_SUCCESS,
    SOCIAL_LOGIN_FAILURE,
} from '../actions/ActionConstants';

const initialState = {
    registerInputData: {
        data: {},
        inputData: {},
        loading: false,
        error: false,
    },
    loginInputData: {
        data: {},
        inputData: {},
        loading: false,
        error: false,
    },
    saveUserAddress: {
        data: {},
        inputData: {},
        error: false,
        loading: false
    },
    userAddress: {
        data: {},
        inputData: {},
        error: false,
        loading: false
    },
    deleteUserAddress: {
        data: {},
        inputData: {},
        error: false,
        loading: false
    },
    updateUserAddress: {
        data: {},
        inputData: {},
        error: false,
        loading: false
    },
    userProfile: {
        data: {},
        inputData: {},
        error: false,
        loading: false,
    },
    socialData: {
        data: {},
        inputData: {},
        error: false,
        loading: false,
    }
};

const userReducer = (state = initialState, action) => {
    switch(action.type) {
        case REGISTER_START:
            return {
                ...state,
                registerInputData: {
                    data: {},
                    inputData: action.data,
                    loading: true,
                    error: false,
                    isSuccess: false
                }
            };
        case REGISTER_SUCCESS:
            return {
                ...state,
                registerInputData: {
                    data: action.data,
                    inputData: {},
                    loading: false,
                    error: false,
                    isSuccess: true
                },
            };
        case REGISTER_FAILURE:
            return {
                ...state,
                registerInputData: {
                    data: {},
                    inputData: {},
                    loading: false,
                    error: action.data,
                    isSuccess: false
                },
            };
        case LOGIN_START:
            return {
                ...state,
                loginInputData: {
                    data: {},
                    inputData: action.data,
                    loading: true,
                    error: false,
                    isSuccess: false
                },
            };
          
        case LOGIN_SUCCESS:
            return {
                ...state,
                loginInputData: {
                    data: action.data,
                    inputData: {},
                    loading: false,
                    error: false,
                    isSuccess: true
                },
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                loginInputData: {
                    data: {},
                    inputData: {},
                    loading: false,
                    error: action.data,
                    isSuccess: false
                },
            };
        case SAVE_USER_ADDRESS_START:
            return {
                ...state,
                saveUserAddress: {
                    data: {},
                    inputData: action.data,
                    loading: true,
                    error: false,                    
                },
            };
        case SAVE_USER_ADDRESS_SUCCESS:
            return {
                ...state,
                saveUserAddress: {
                    data: action.data,
                    inputData: {},
                    loading: false,
                    error: false,                    
                },
            };
        case SAVE_USER_ADDRESS_FAILURE:
            return {
                ...state,
                saveUserAddress: {
                    data: {},
                    inputData: {},
                    loading: false,
                    error: action.error,                    
                },
            };
        case FETCH_USER_ADDRESS_START:
            return {
                ...state,
                userAddress: {
                    data: {},
                    inputData: action.data,
                    loading: true,
                    error: false,                    
                },
            };
        case FETCH_USER_ADDRESS_SUCCESS:
            return {
                ...state,
                userAddress: {
                    data: action.data,
                    inputData: {},
                    loading: false,
                    error: false,                    
                },
            };
        case FETCH_USER_ADDRESS_FAILURE:
            return {
                ...state,
                userAddress: {
                    data: {},
                    inputData: {},
                    loading: false,
                    error: action.error,                    
                },
            };
        case DELETE_USER_ADDRESS_START:
            return {
                ...state,
                deleteUserAddress: {
                    data: {},
                    inputData: action.data,
                    error: false,
                    loading: true
                }
            };
        case DELETE_USER_ADDRESS_SUCCESS:
            return {
                ...state,
                deleteUserAddress: {
                    data: action.data,
                    inputData: {},
                    error: false,
                    loading: false
                }
            };
        case DELETE_USER_ADDRESS_FAILURE:
            return {
                ...state,
                deleteUserAddress: {
                    data: {},
                    inputData: {},
                    error: action.data,
                    loading: false
                }
            };
        case UPDATE_USER_ADDRESS_START:
            return {
                ...state,
                updateUserAddress: {
                    data: {},
                    inputData: action.data,
                    error: false,
                    loading: true
                }
            };
        case UPDATE_USER_ADDRESS_SUCCESS:
            return {
                ...state,
                updateUserAddress: {
                    data: action.data,
                    inputData: {},
                    error: false,
                    loading: false
                }
            };
        case UPDATE_USER_ADDRESS_FAILURE:
            return {
                ...state,
                updateUserAddress: {
                    data: {},
                    inputData: {},
                    error: action.data,
                    loading: false
                }
            };
        case FETCH_USER_PROFILE_START:
            return {
                ...state,
                userProfile: {
                    data: {},
                    inputData: action.data,
                    error: false,
                    loading: true,
                }
            };
        case FETCH_USER_PROFILE_SUCCESS:
            return {
                ...state,
                userProfile: {
                    data: action.data,
                    inputData: {},
                    error: false,
                    loading: false,
                }
            };
        case FETCH_USER_PROFILE_FAILURE:
            return {
                ...state,
                userProfile: {
                    data: {},
                    inputData: {},
                    error: action.data,
                    loading: false,
                }
            };
        case SOCIAL_LOGIN_START:
            return {
                ...state,
                socialData: {
                    data: {},
                    inputData: action.data,
                    error: false,
                    loading: true,
                }
            }
        case SOCIAL_LOGIN_SUCCESS:
            return {
                ...state,
                socialData: {
                    data: action.data,
                    inputData: {},
                    error: false,
                    loading: false,
                }
            }
        case SOCIAL_LOGIN_FAILURE:
            return {
                ...state,
                socialData: {
                    data: {},
                    inputData: {},
                    error: action.data,
                    loading: false,
                }
            }
        default:
            return state;
    }
};

export default userReducer;