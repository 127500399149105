import {
    FETCH_PRODUCT_START,
    FETCH_PRODUCT_SUCCESS,
    FETCH_PRODUCT_FAILURE,
    FETCH_PRODUCT_SINGLE_START,
    FETCH_PRODUCT_SINGLE_SUCCESS,
    FETCH_PRODUCT_SINGLE_FAILURE,
    PRODUCT_FILTER_START,
    PRODUCT_FILTER_SUCCESS,
    PRODUCT_FILTER_FAILURE,
} from '../actions/ActionConstants';

// fetch category wise products
export function fetchProductStart(data) {
    return {
        type: FETCH_PRODUCT_START,
        data
    }
}

export function fetchProductSuccess(data) {
    return {
        type: FETCH_PRODUCT_SUCCESS,
        data
    }
}

export function fetchProductFailure(data) {
    return {
        type: FETCH_PRODUCT_FAILURE,
        data
    }
}

// fetch single lens detail
export function fetchSingleProductStart(data) {
    return {
        type: FETCH_PRODUCT_SINGLE_START,
        data
    }
}

export function fetchSingleProductSuccess(data) {
    return {
        type: FETCH_PRODUCT_SINGLE_SUCCESS,
        data
    }
}

export function fetchSingleProductFailure(data) {
    return {
        type: FETCH_PRODUCT_SINGLE_FAILURE,
        data
    }
}

// product filter 
export function filterProductStart(data) {
    return {
        type: PRODUCT_FILTER_START,
        data
    }
}

export function filterProductSuccess(data) {
    return {
        type: PRODUCT_FILTER_SUCCESS,
        data
    }
}

export function filterProductFailure(data) {
    return {
        type: PRODUCT_FILTER_FAILURE,
        data
    }
}