import { all, takeLatest, select, put } from "redux-saga/effects";
import { STORE_LOCATOR_START, CONTACT_US_START } from '../actions/ActionConstants';
import ApiMethods from "../../utils/ApiMethods";
import { createNotification } from "react-redux-notify";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage
} from "../../components/helper/NotificationMessage";
import {
    fetchStoreLocatorSuccess,
    fetchStoreLocatorFailure,
    saveContactUsSuccess,
    saveContactUsFailure,
} from '../actions/StoreLocatorAction';

function* fetchStoreLocatorAPI() {
    try {
      const response = yield ApiMethods.getMethod("user/all/shop/locate");
  
      if (response.data.success) {
        yield put(fetchStoreLocatorSuccess(response.data.data));
      } else {
        yield put(fetchStoreLocatorFailure(response.data));
        const notificationMessage = getErrorNotificationMessage('Something went wrong');
        yield put(createNotification(notificationMessage));
      }
    } catch {}
  }

function* saveContactusAPI() {
  try{
    const contactData = yield select((state) => state.storeLocator.contactUs.inputData);
    const response = yield ApiMethods.postMethod("user/contact/us", contactData);

    if (response.data.success) {
      yield put(saveContactUsSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(response.data.msg);
      yield put(createNotification(notificationMessage));
    } else {
      yield put(saveContactUsFailure(response.data));
      const notificationMessage = getErrorNotificationMessage('Something went wrong');
      yield put(createNotification(notificationMessage));
    }
  } catch { }
}


export default function* pageSaga() {
    yield all([
      yield takeLatest(STORE_LOCATOR_START, fetchStoreLocatorAPI),
      yield takeLatest(CONTACT_US_START, saveContactusAPI),
    ]);
}
  