import oval1 from './oval/oval1.png';
import oval2 from './oval/oval2.png';
import oval3 from './oval/oval3.png';
import oval4 from './oval/oval4.png';
import oval5 from './oval/oval5.png';
import oval6 from './oval/oval6.png';
import round1 from './round/round1.png';
import round2 from './round/round2.png';
import round3 from './round/round3.png';
import square1 from './square/square1.png';
import square2 from './square/square2.png';
import square3 from './square/square3.png';
import square4 from './square/square4.png';
import heart1 from './heart/heart1.png';
import heart2 from './heart/heart2.png';
import heart3 from './heart/heart3.png';
import heart4 from './heart/heart4.png';
import heart5 from './heart/heart5.png';
import heart6 from './heart/heart6.png';

export const FrameData = [
    {
        type: 'Oval',
        description: 'Oval glasses are a fantastic halfway point between square glasses and round glasses. They have a unique, wide shape and are a great.',
        images: [oval1, oval2, oval3, oval4, oval5, oval6]
    },
    {
        type: 'Round',
        description: 'Round frames are some of the smallest eyeglasses and they are also quite narrow. These smaller frames perfectly complement the longer and squarer face types.',
        images: [round1, round2, round3]
    },
    {
        type: 'Square',
        description: 'Square eyeglass frames have an unmistakable iconic appeal. This classic style features sharp angles and a solid silhouette that makes them perfect for highlighting the upper features of your face.',
        images: [square1, square2, square3, square4]
    },
    {
        type: 'Heart',
        description: 'Heart shape faces are widest at the forehead, have high cheekbones, and taper down to a narrow chin.',
        images: [heart1, heart2, heart3, heart4, heart5, heart6]
    }
];