import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { saveCareerStart } from "../../store/actions/CareerAction";

const initialState = {
  name: "",
  phone_number: "",
  city: "",
  email: "",
  resume: "",
};

const Career = (props) => {
  const [{ name, phone_number, city, email }, setCareerData] =
    useState(initialState);
  const [resume, setResume] = useState("");
  const [fileName, setFileName] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;
    setCareerData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = async (event) => {
    const fileType = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'application/pdf']
    if (event.target.files[0]) {
      if (!fileType.includes(event.target.files[0].type)) {
        alert('Only .pdf, .doc and .docx Files are allowed.')
        return;
      }
      setFileName(event.target.files[0].name);
      let formData = new FormData();
      formData.append("images", event.target.files[0]);
      let result = await axios.post(
        `${process.env.REACT_APP_API_URL}admin/addPic`,
        formData
      );
      if (result.data.status) {
        setResume(result.data.data[0]);
      }
    }
  };

  const handleSubmit = (event) => {
    event && event.preventDefault();
    props.dispatch(
      saveCareerStart({
        name: name,
        phone_number: phone_number,
        city: city,
        email: email,
        resume: resume,
      })
    );
    setCareerData({ ...initialState });
    setResume("");
    setFileName("");
  };
  return (
    <div className="career customPadding">
      <div className="container">
        <div className="career-heading">
          <h1>Chashma Planet <span>CAREERS</span></h1>
        </div>
        <div className="enquirey-form text-center">
          <h4>BE A PART OF OUR TEAM</h4>
          <p>
            ENTER YOUR DETAILS BELOW.
            <br /> OUR TEAM WILL GET BACK TO YOU SOOOON!
          </p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="Name"
                value={name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="city"
                name="city"
                placeholder="City"
                value={city}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="phone"
                name="phone_number"
                placeholder="Phone Number"
                maxLength="10"
                pattern="\d{10}"
                title="Please enter exactly 10 digits"
                value={phone_number}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email Address"
                value={email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group custom-upload">
              <div className="custom-file mb-3">
                {fileName.length > 0 ? (
                  <label style={{color: 'black'}} className="custom-file-label">{fileName}</label>
                ) : (
                    <>
                      <input
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/pdf"
                        className="custom-file-input"
                        id="customFile"
                        name="resume"
                        // value={resume}
                        onChange={handleFileChange}
                        required
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        Upload Resume
                    </label>
                    </>
                  )}
              </div>
            </div>

            <button type="submit" className="btn btn-primary custom-yellow-btn">
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  careerData: state.career.careerData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Career);
