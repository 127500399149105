import React from 'react';
import Notify from "react-redux-notify/lib/components/Notify";
import Header from './Header';
import Footer from './Footer';

const MainLayout = ({
    children
}) => {
    return (
        <>
        <Header />
        <Notify />
        {React.cloneElement(children)}
        <Footer />
        </>
    );
}

export default MainLayout;