import React, { useState } from "react";
import axios from "axios";
import { Modal, Toast } from "react-bootstrap";
import { useDispatch } from "react-redux";
import logo from "../../assets/images/new-logo.svg";
import whatsapp from "../../assets/images/whatsapp-icon.svg";
import PasswordChangeModal from "../helper/PasswordChangeModal";
import { createNotification } from "react-redux-notify";
import OtpInput from "react-otp-input";
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/NotificationMessage";

const PasswordReset = (props) => {
  
  const dispatch = useDispatch();
  const { openPasswordReset, closePasswordReset } = props;
  const [passwordChange, setPasswordChange] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const handleOtpChange = async (otp) => {
    setOtp(otp);
  }

  const handleGetOTP = async () => {
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}user/send/otp/verification`,
      {
        phone: mobileNumber,
      }
    );
    if (result.data?.success) {
      setIsSuccess(true);
      const notificationMessage = getSuccessNotificationMessage(
        result.data?.messgae
      );
      dispatch(createNotification(notificationMessage));
    } else {
      const notificationMessage = getErrorNotificationMessage(result.data?.msg);
      dispatch(createNotification(notificationMessage));
    }
  };

const handleResetPassword = async (e) => {
  e.preventDefault();

  if (!otp) {
    alert('Please Enter OTP.') // Set error message if OTP is empty
    return;
  }

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}user/verify/otp`,
      { phone: mobileNumber, otp: otp }
    );

    if (isSuccess) {
      sessionStorage.setItem("otp", otp);
      sessionStorage.setItem("ResetMobile", mobileNumber);
      closePasswordReset();
      setPasswordChange(true);
      setOtp("");
      setMobileNumber("")
    } else {
      const notificationMessage = getSuccessNotificationMessage(
        result.data?.msg
      );
      dispatch(createNotification(notificationMessage))
      setOtp("");
      setMobileNumber("")
    }
  } catch (error) {
    const notificationMessage = getErrorNotificationMessage(error.data?.msg);
    dispatch(createNotification(notificationMessage));
    setOtp("");
  }
};
  return (
    <>
      <Modal
        className="custom-dialog"
        size="xl"
        centered
        show={openPasswordReset}
        onHide={closePasswordReset}
      >
        <div className="modal-content">
          <div className="modal-body">
            <div className="row m-0">
              <div className="col-md-5 p-0">
                <div className="modal-left">
                  <div className="logo-wrap">
                    <a href="/">
                      <h4 style={{color:'white'}}>Chashma Planet</h4>
                      {/* <img
                        src={logo}
                        alt="Framskraft.com"
                        title="Framskraft.com"
                      /> */}
                    </a>
                  </div>
                  <div className="text-wrap">
                    <a href="https://api.whatsapp.com/send/?phone=919859424242&text&app_absent=0">
                      <h2>
                        Get Expert Help Now Available On{" "}
                        <span>
                          <img src={whatsapp} alt="whatsapp" />
                          Whatsapp{" "}
                        </span>
                      </h2>
                    </a>
                    <h3>Elite Membership Programme</h3>
                    <p>Get Special Offers And Benifits</p>
                    <a
                      href="/elite-membership"
                      alt="KNOW MORE"
                      title="KNOW MORE"
                    >
                      KNOW MORE <i> <ArrowForwardIosIcon/></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-7 p-0">
                <i
                  onClick={closePasswordReset}
                  style={{ float: "right", cursor: "pointer" }}
               
                >
                  <CloseIcon/>
                  </i>
                <div className="modal-right">
                  <div className="custom-form-wrapper">
                    <h4>Reset Password</h4>
                    <form onSubmit={handleResetPassword} className="reset-form">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          maxLength="10"
                          pattern="\d{10}"
                          title="Please enter exactly 10 digits"
                          placeholder="Mobile Number"
                          value={mobileNumber}
                          onChange={(e) => setMobileNumber(e.target.value)}
                          required
                        />
                        <a
                          href="#"
                          onClick={() => {
                            if (mobileNumber) {
                              handleGetOTP();
                            }
                          }}
                          className="send-btn"
                        >
                          Send
                        </a>
                      </div>
                      <div className="opt-wrap">
                        <p>OTP</p>
                        <div className="otp-input">
                          <OtpInput
                            value={otp}
                            onChange={handleOtpChange}
                            numInputs={4}
                            separator={<span>-</span>}
                            isInputNum={true}
                          />
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary custom-btn"
                      >
                        Reset Password
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <PasswordChangeModal
        openPasswordChange={passwordChange}
        closePasswordChange={() => setPasswordChange(false)}
      />
    </>
  );
};

export default PasswordReset;





