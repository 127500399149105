import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import AddCoatingModal from "./AddCoatingModal";
import LensBuyInstructionModal from "./LensBuyInstructionModal";

const SelectLensModal = (props) => {
  const { showLensModal, setShowlensModal, powerType } = props;
  const [showCoating, setShowCoating] = useState(false);
  const [showLensIntsModal, setShowLensIntsModal] = useState(false);
  const [lensTypePrice, setLensTypePrice] = useState(0);
  return (
    <>
    <Modal
      className="custom-dialog"
      size="xl"
      centered
      show={showLensModal}
      onHide={setShowlensModal}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h4>Select Lenses for {powerType}</h4>
        </div>
        <div className="modal-body">
          <div className="row m-0">
            <div className="col-md-6 p-0">
              <div className="frame-box-wrapper">
                <div className="top-box-wrap">
                  <h4>BLUE CUT</h4>
                  <h2>LENS PRICE - ₹2575</h2>
                  <p>1 Year Warranty</p>
                </div>
                <a
                  href="#"
                  className="blue-btn"
                  data-toggle="modal"
                  data-target="#add-coating"
                  onClick={() => {
                    setShowlensModal();
                    setShowLensIntsModal(true);
                    if (powerType === 'Bifocal/Progressive') {
                        setShowCoating(true);
                    }
                    setLensTypePrice(2575);
                  }}
                >
                  Add to cart
                </a>
                <div className="bottom-list">
                  <ul>
                    <li>Blue Protection</li>
                    <li>Double Antiglare</li>
                    <li>Crack Resistant</li>
                    <li>Smudge Resistance</li>
                    <li>Water Repellent</li>
                    <li>Dust Repellent</li>
                    <li>UV Protection</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-6 p-0">
              <div className="frame-box-wrapper">
                <div className="top-box-wrap">
                  <h4>ANTI-GLARE</h4>
                  <h2>LENS PRICE - ₹1500</h2>
                  <p>1 Year Warranty</p>
                </div>
                <a
                  href=""
                  className="blue-btn"
                  data-toggle="modal"
                  data-target="#add-coating"
                  onClick={() => {
                    setShowlensModal();
                    setShowLensIntsModal(true);
                    if (powerType === 'Bifocal/Progressive') {
                        setShowCoating(true);
                    }
                    setLensTypePrice(1500);
                  }}
                >
                  Add to cart
                </a>
                <div className="bottom-list">
                  <ul>
                    <li>No Blue Protection</li>
                    <li>Double Antiglare</li>
                    <li>No Crack Resistant</li>
                    <li>No Smudge Resistance</li>
                    <li>No Water Repellent</li>
                    <li>No Dust Repellent</li>
                    <li>UV Protection</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    {powerType === 'Bifocal/Progressive' ? (
        <AddCoatingModal lensPrice={lensTypePrice} openCoatingModal={showCoating} onOpenCoatingModal={() => setShowCoating(false)} />
    ) : (
        <LensBuyInstructionModal lensPrice={lensTypePrice} powerType={powerType} openLensModal={showLensIntsModal} onOpenLensModal={() => setShowLensIntsModal(false) } />
    )}
    </>
  );
};

export default SelectLensModal;
