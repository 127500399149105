import {
    SAVE_CAREER_START,
    SAVE_CAREER_SUCCESS,
    SAVE_CAREER_FAILURE,
} from '../actions/ActionConstants';

const initialState = {
    careerData: {
        data: {},
        inputData: {},
        loading: false,
        error: false,
    }
}

const CareerReducer = (state = initialState, action) => {
    switch(action.type) {
        case SAVE_CAREER_START:
            return {
                ...state,
                careerData: {
                    inputData: action.data,
                    data: {},
                    loading: true,
                    error: false
                }
            };
        case SAVE_CAREER_SUCCESS:
            return {
                ...state,
                careerData: {
                    inputData: {},
                    data: action.data,
                    loading: false,
                    error: false
                }
            };
        case SAVE_CAREER_FAILURE:
            return {
                ...state,
                careerData: {
                    inputData: {},
                    data: {},
                    loading: false,
                    error: action.data
                }
            };
        default:
            return state;
    }
};

export default CareerReducer;