import { useState } from "react";
import ProductList from "./ProductList";
import SearchFilter from "./SearchFilter";

const SearchResult = (props) => {
  const lensData = props.history.location.state;
  const [data, setData] = useState(lensData);
  const handleFrameType = (value) => {
    if (value) {
      const _data = lensData && lensData.filter((d) => d.type === value);
      setData(_data);
    } else {
      setData(lensData);
    }
  };

  const handleFrameShape = (value) => {
    if (value) {
      const _data = lensData && lensData.filter((d) => d.type === value);
      setData(_data);
    } else {
      setData(lensData);
    }
  };

  const handleGender = (value, check) => {
    if (check) {
      const _data = lensData && lensData.filter((d) => d.type === value);
      setData(_data);
    } else {
      setData(lensData);
    }
  };

  const handleBrand = (value, check) => {
    if (check) {
      const _data = lensData && lensData.filter((d) => d.type === value);
      setData(_data);
    } else {
      setData(lensData);
    }
  };

  const handleMaterial = (value, check) => {
    if (check) {
      const _data = lensData && lensData.filter((d) => d.type === value);
      setData(_data);
    } else {
      setData(lensData);
    }
  };

  const handleColor = (value, check) => {
    // colorValues.push(value);
    if (check) {
      //   setColorArray((prevState) => ([ ...prevState, ...colorValues]));
    } else {
      //   const temp = colorArray.filter((val) => val !== value)
      //   setColorArray((prevState) => ([ ...prevState, ...temp]));
    }
  };

  const handlePrice = (value, check) => {
    if (check) {
      //   setFilterLens((prevState) => ({
      //     ...prevState,
      //     price: {
      //       minimum_price: value.split(",")[0],
      //       maximum_price: value.split(",")[1],
      //     },
      //   }));
    } else {
      //   setFilterLens((prevState) => ({ ...prevState, price: {} }));
    }
  };

  const handleDisposable = (value, check) => {
    if (check) {
      //   setFilterLens((prevState) => ({ ...prevState, disposables: [value] }));
    } else {
      //   setFilterLens((prevState) => ({ ...prevState, disposables: [] }));
    }
  };
  return (
    <div className="product-listing page-pdding">
      <SearchFilter
        onSelectFrameType={handleFrameType}
        onSelectFrameShape={handleFrameShape}
        onSelectGender={handleGender}
        onSelectColor={handleColor}
        onSelectPrice={handlePrice}
        onSelectBrand={handleBrand}
        onSelectMaterial={handleMaterial}
        onSelectDisposable={handleDisposable}
      />
      <div className="product-content">
        <ProductList lens={data} />
      </div>
    </div>
  );
};

export default SearchResult;
