import React from "react";
import Slider from "react-slick";

const MySlider = (props) => {
  const { Slidedata } = props;
  const categorySettings = {
    arrows: false,
    infinite: Slidedata.length > 4 ? true : false,
    speed: 500,
    autoplay: true,
    slidesToShow: 4,
    // fade: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <div className="product-slider">
      <Slider {...categorySettings}>
        {Slidedata.length > 0 &&
          Slidedata.map((data) => (
            <div
              key={data._id}
              className="product-wrap"
            >
              <a
                href={`/product/${data._id}`}
                className="img-wrap banner-hover-effect"
                tabIndex="0"
              >
                <span className="product-new-label">new</span>
                <img
                  src={data.imgCollection[0]}
                  alt=""
                />
              </a>
              <div className="product-info-wrap">
                <h2>{data.name}</h2>
                <div className="ratting star5"></div>
                <div className="price">
                <span>{data.price > 0 && data.price !== null ? `₹${data.price}` : ''}</span>
                    ₹{data?.discount_price}
                </div>
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default MySlider;
