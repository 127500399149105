import { all, takeLatest, select, put } from "redux-saga/effects";
import { 
  FETCH_PRODUCT_START,
  FETCH_PRODUCT_SINGLE_START,
  PRODUCT_FILTER_START,
 } from '../actions/ActionConstants';
import ApiMethods from "../../utils/ApiMethods";
import { createNotification } from "react-redux-notify";
import {
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchProductSuccess,
  fetchProductFailure,
  fetchSingleProductSuccess,
  fetchSingleProductFailure,
  filterProductSuccess,
  filterProductFailure,
} from '../actions/ProductAction';

const categoryArr = ['Eyeglasses', 'Sunglasses', 'Computer Glasses', 'Contact Lens', 'Accessories'];
const genderArr = ['men', 'women', 'kids'];


function* fetchProductAPI() {
    try {
        const productData = yield select((state) => state.products.productData.inputData);
        let response;
        if (categoryArr.includes(productData.category)) {
          response = yield ApiMethods.getMethod(`user/lens/category/${productData.category}`);
        } else if (genderArr.includes(productData.category)) {
          response = yield ApiMethods.getMethod(`user/lens/gender/${productData.category}`);
        }

        if (response.data.success) {
          yield put(fetchProductSuccess(response.data.data));
        } else {
          yield put(fetchProductFailure(response.data));
          const notificationMessage = getErrorNotificationMessage('Something went wrong');
          yield put(createNotification(notificationMessage));
        }
    } catch {}
}

function* fetchSingleProductAPI() {
  try {
    const singleProduct = yield select((state) => state.products.singleProduct.inputData);
    const response = yield ApiMethods.getMethod(`user/lens/detail/page/${singleProduct.lensId}`);

    if (response.data.success) {
      yield put(fetchSingleProductSuccess(response.data.data));
    } else {
      yield put(fetchSingleProductFailure(response.data));
      const notificationMessage = getErrorNotificationMessage('Something went wrong');
      yield put(createNotification(notificationMessage));
    }
  } catch {}
}

function* filterProductAPI() {
  try {
    const filterProduct = yield select((state) => state.products.filterProduct.inputData);
    const response = yield ApiMethods.postMethod('user/lens/filters', filterProduct);
    if (response.data.success) {
      yield put(filterProductSuccess(response.data.data));
    } else {
      yield put(filterProductFailure(response.data));
      const notificationMessage = getErrorNotificationMessage('Something went wrong');
      yield put(createNotification(notificationMessage));
    }
  } catch {}
}



export default function* pageSaga() {
    yield all([
      yield takeLatest(FETCH_PRODUCT_START, fetchProductAPI),
      yield takeLatest(FETCH_PRODUCT_SINGLE_START, fetchSingleProductAPI),
      yield takeLatest(PRODUCT_FILTER_START, filterProductAPI),
    ]);
}