import React from "react";
import { Link, withRouter } from "react-router-dom";

const MyAccountTab = (props) => {
  const { history } = props;

  const handleLogout = (event) => {
    event.preventDefault();
    sessionStorage.removeItem('access');
    sessionStorage.removeItem('refresh_token');
    sessionStorage.removeItem('user_id');
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('products');
    sessionStorage.removeItem('isMembership');
    window.location.href = '/';
  }
  return (
    <div className="product-sidebar">
      <Link
        className={history.location.pathname === "/my-account" ? "active" : ""}
        to="/my-account"
        alt="My Orders"
        title="My Orders"
      >
        My Orders
      </Link>
      <Link
        className={history.location.pathname === "/profile" ? "active" : ""}
        to="/profile"
        alt="Profile Information"
        title="Profile Information"
      >
        Profile Information
      </Link>
      <Link
        className={history.location.pathname === "/address" ? "active" : ""}
        to="/address"
        alt="Manage Addresses"
        title="Manage Addresses"
      >
        Manage Addresses
      </Link>
      <Link to="#" onClick={(event) => handleLogout(event)} alt="Log Out" title="Log Out">
        Log Out
      </Link>
    </div>
  );
};

export default withRouter(MyAccountTab);
