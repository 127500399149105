import {
    REGISTER_START,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,
    LOGIN_START,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    SAVE_USER_ADDRESS_START,
    SAVE_USER_ADDRESS_SUCCESS,
    SAVE_USER_ADDRESS_FAILURE,
    FETCH_USER_ADDRESS_START,
    FETCH_USER_ADDRESS_SUCCESS,
    FETCH_USER_ADDRESS_FAILURE,
    DELETE_USER_ADDRESS_START,
    DELETE_USER_ADDRESS_SUCCESS,
    DELETE_USER_ADDRESS_FAILURE,
    UPDATE_USER_ADDRESS_START,
    UPDATE_USER_ADDRESS_SUCCESS,
    UPDATE_USER_ADDRESS_FAILURE,
    FETCH_USER_PROFILE_START,
    FETCH_USER_PROFILE_SUCCESS,
    FETCH_USER_PROFILE_FAILURE,
    SOCIAL_LOGIN_START,
    SOCIAL_LOGIN_SUCCESS,
    SOCIAL_LOGIN_FAILURE,
} from './ActionConstants';

// user registration actions
export function userRegistrationStart(data) {
    return {
        type: REGISTER_START,
        data
    }
}

export function userRegistrationSuccess(data) {
    return {
        type: REGISTER_SUCCESS,
        data
    }
}

export function userRegistrationFailure(data) {
    return {
        type: REGISTER_FAILURE,
        data
    }
}

// user login actions
export function userLoginStart(data) {
    return {
      type: LOGIN_START,
      data,
    };
}
  
export function userLoginSuccess(data) {
    return {
      type: LOGIN_SUCCESS,
      data,
    };
}
  
export function userLoginFailure(data) {
    return {
      type: LOGIN_FAILURE,
      data,
    };
}

// save user address
export function saveUserAddressStart(data) {
  return {
    type: SAVE_USER_ADDRESS_START,
    data,
  };
}

export function saveUserAddressSuccess(data) {
  return {
    type: SAVE_USER_ADDRESS_SUCCESS,
    data,
  };
}

export function saveUserAddressFailure(data) {
  return {
    type: SAVE_USER_ADDRESS_FAILURE,
    data,
  };
}

// fetch user address
export function fetchUserAddressStart(data) {
  return {
    type: FETCH_USER_ADDRESS_START,
    data,
  };
}

export function fetchUserAddressSuccess(data) {
  return {
    type: FETCH_USER_ADDRESS_SUCCESS,
    data,
  };
}

export function fetchUserAddressFailure(data) {
  return {
    type: FETCH_USER_ADDRESS_FAILURE,
    data,
  };
}

// delete user address

export function deleteUserAddressStart(data) {
  return {
    type: DELETE_USER_ADDRESS_START,
    data,
  }
}

export function deleteUserAddressSuccess(data) {
  return {
    type: DELETE_USER_ADDRESS_SUCCESS,
    data,
  }
}

export function deleteUserAddressFailure(data) {
  return {
    type: DELETE_USER_ADDRESS_FAILURE,
    data,
  }
}


// update user address

export function updateUserAddressStart(data) {
  return {
    type: UPDATE_USER_ADDRESS_START,
    data,
  }
}

export function updateUserAddressSucess(data) {
  return {
    type: UPDATE_USER_ADDRESS_SUCCESS,
    data,
  }
}

export function updateUserAddressFailure(data) {
  return {
    type: UPDATE_USER_ADDRESS_FAILURE,
    data,
  }
}

// fetch user profile
export function fetchUserProfileStart(data) {
  return {
    type: FETCH_USER_PROFILE_START,
    data,
  }
}

export function fetchUserProfileSuccess(data) {
  return {
    type: FETCH_USER_PROFILE_SUCCESS,
    data,
  }
}

export function fetchUserProfileFailure(data) {
  return {
    type: FETCH_USER_PROFILE_FAILURE,
    data,
  }
}

// social login action
export function socialLoginStart(data) {
  return {
    type: SOCIAL_LOGIN_START,
    data
  }
}

export function socialLoginSuccess(data) {
  return {
    type: SOCIAL_LOGIN_SUCCESS,
    data
  }
}

export function socialLoginFailure(data) {
  return {
    type: SOCIAL_LOGIN_FAILURE,
    data
  }
}