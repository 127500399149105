import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import crossmark from "../../assets/images/cross-mark.svg";
import c1 from "../../assets/images/c1.png";
import c2 from "../../assets/images/c2.png";
import checkmark from "../../assets/images/check-mark.svg";
import LensBuyInstructionModal from "./LensBuyInstructionModal";

const AddCoatingModal = (props) => {
    const { openCoatingModal, onOpenCoatingModal, lensPrice } = props;
    const [showLensIntsModal, setShowLensIntsModal] = useState(false);
    const [isCoatingApply, setIsCoatingApply] = useState(false);
  return (
    <>
    <Modal
      className="custom-dialog"
      size="xl"
      centered
      show={openCoatingModal}
      onHide={onOpenCoatingModal}
    >
      <div className="modal-content">
        <div className="modal-header text-center">
          <h4>Add Coating to your Lenses (Optional)</h4>
        </div>
        <div className="modal-body">
          <div className="coating-wrap">
            <h4>
              Photo-chromatic coating (For power below 6)
              <br />
              <span> ₹ 1000</span>
            </h4>
            <div className="coating-img">
              <div className="text-img-wrap">
                <h3>
                  <a href="" className="close-icon">
                    {" "}
                    <img src={crossmark} alt="" />
                  </a>{" "}
                  Without Coating
                </h3>
                <img src={c1} alt="" />
              </div>
              <div className="text-img-wrap">
                <h3>
                  <a href="">
                    {" "}
                    <img src={checkmark} alt="" />
                  </a>{" "}
                  With Coating
                </h3>
                <img src={c2} alt="" />
              </div>
            </div>
            <div className="bottom-btn-wrap">
              <a
                href="#"
                className="blue-btn m-2"
                data-toggle="modal"
                data-target="#go-to-cart"
                onClick={() => {
                    onOpenCoatingModal();
                    setShowLensIntsModal(true);
                    setIsCoatingApply(true);
                }}
              >
                Apply Coating
              </a>
              <a href="#" onClick={() => {
                    onOpenCoatingModal();
                    setShowLensIntsModal(true);
                }} className="whitebg-btn">
                Skip
              </a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <LensBuyInstructionModal lensPrice={lensPrice} isCoatingApply={isCoatingApply} openLensModal={showLensIntsModal} onOpenLensModal={() => setShowLensIntsModal(false)} />
    </>
  );
};

export default AddCoatingModal;
