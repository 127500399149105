import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import BaseApp from "./components/Base/BaseApp";
import store from "./store";
import { createBrowserHistory as createHistory } from "history";
import "react-redux-notify/dist/ReactReduxNotify.css";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const history = createHistory();

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <BaseApp history={history}/>
      </Router>
    </Provider>
  );
}

export default App;
