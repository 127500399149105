import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ApiMethods from "../../utils/ApiMethods";
import MyAccountTab from "./MyAccountTab";
import { createNotification } from "react-redux-notify";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";
import box from "../../assets/images/box.svg";
import OrderCard from "./OrderCard";

const MyOrders = (props) => {
  const { isTracking = false } = props;
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    async function fetchOrderData() {
      const response = await ApiMethods.getMethod("user/orders");
      if (response.data?.success) {
        setOrders([...response.data.data]);
      } else {
        const notificationMessage = getErrorNotificationMessage(
          "Something went wrong"
        );
        dispatch(createNotification(notificationMessage));
      }
    }
    fetchOrderData();
  }, []);
  return (
    <div className="my-account page-pdding">
      <div className="top-account-bar text-center">
        <h4>My Account</h4>
      </div>
      <div className="product-listing">
        {!isTracking && <MyAccountTab />}
        <div className="product-content">
          <div className="account-header">
            <div className="order-left">
              <img src={box} alt="" />
              <h2>My Orders</h2>
            </div>
          </div>
          {orders.length > 0 &&
            orders.map((order, index) => (
              <OrderCard order={order} index={index} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default MyOrders;
