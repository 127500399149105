import {
    SAVE_CAREER_START,
    SAVE_CAREER_SUCCESS,
    SAVE_CAREER_FAILURE,
} from '../actions/ActionConstants';


// save career action
export function saveCareerStart(data) {
    return {
        type: SAVE_CAREER_START,
        data
    };
}

export function saveCareerSuccess(data) {
    return {
        type: SAVE_CAREER_SUCCESS,
        data
    };
}

export function saveCareerFailure(data) {
    return {
        type: SAVE_CAREER_FAILURE,
        data
    };
}