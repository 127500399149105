/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import { addItem } from "../../utils";
import eyepower from "../../assets/images/eye-power.svg";

const LensBuyInstructionModal = (props) => {
  const { openLensModal, onOpenLensModal, isCoatingApply, lensPrice, powerType } = props;
  let cartData = useSelector((state) => state.products.singleProduct);
  cartData.data.isCoatingApplied = isCoatingApply;
  cartData.data.lensPrice = lensPrice;
  
  return (
    <>
      <Modal
        className="custom-dialog blue-header"
        size="xl"
        centered
        show={openLensModal}
        onHide={onOpenLensModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <img src={eyepower} alt="" />
            <h4>
              What About My Eye Power?
              <br /> Submit right at the end after your order is placed
            </h4>
          </div>
          <div className="modal-body">
            <div className="power-list">
              <ul>
                <li>
                  You can submit your eye power after Payment step.
                </li>
                <li>
                  There’s no hurry! Power can be submitted within 7 days of
                  order placement.
                </li>
                <li>
                  No additional charges for high / complex power.
                </li>
                <li>
                  Our optical experts will be in touch with you to help you with your prescription.
                </li>
              </ul>
              <div className="bottom-btn-wrap">
                <a
                  onClick={() => {
                    onOpenLensModal();
                      let products = [];
                      if (sessionStorage.getItem("products")) {
                        addItem(cartData.data,powerType) ;
                      } else {
                        sessionStorage.setItem(
                          "products",
                          JSON.stringify(products)
                        );
                        addItem(cartData.data,powerType);
                      }
                      props.history.push("/cart");
                  }}
                  className="blue-btn orang-border"
                >
                  Continue
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default withRouter(LensBuyInstructionModal);
