import axiosInstance from "./AxiosInstance";

const ApiMethods = {
    postMethod: async (action, object) => {
        try {
            const response = await axiosInstance.post(action, object);
            return response;
        } catch(error) {
            return error;
        }
    },
    getMethod: async (action) => {
        try {
            const response = await axiosInstance.get(action);
            return response;
        } catch(error) {
            return error;
        }
    }
};

export default ApiMethods;