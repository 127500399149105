import { all, takeLatest, select, put, call } from "redux-saga/effects";
import { SAVE_CAREER_START } from '../actions/ActionConstants';
import ApiMethods from "../../utils/ApiMethods";
import { createNotification } from "react-redux-notify";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage
} from "../../components/helper/NotificationMessage";
import {
    saveCareerSuccess,
    saveCareerFailure,
} from '../actions/CareerAction';

function* saveCareerAPI() {
    try {
        const inputCarrer = yield select(
            (state) => state.career.careerData.inputData
          );
          const response = yield call(ApiMethods.postMethod, 'user/joinTeam', inputCarrer);
        if (response.data.success) {            
            yield put(saveCareerSuccess(response.data));
            const notificationMessage = getSuccessNotificationMessage(
                response.data.msg
            );
            yield put(createNotification(notificationMessage));
        } else {
            yield put(saveCareerFailure(response.data));            
            const notificationMessage = getErrorNotificationMessage(
                response.data.errors.email[0]
            );
            yield put(createNotification(notificationMessage));
        }
    } catch {}
}

export default function* pageSaga() {
    yield all([
      yield takeLatest(SAVE_CAREER_START, saveCareerAPI),
    ]);
}