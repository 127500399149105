import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchProductStart,
  filterProductStart,
} from "../../store/actions/ProductAction";
import ProductFilter from "./ProductFilter";
import ProductList from "./ProductList";

const shopByGender = {
  men: "Male",
  women: "Female",
  kids: "kids",
};

const ProductListContainer = (props) => {
  let initialState;
  let colorValues = [];
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  const [colorArray, setColorArray] = useState([]);
  const disposability = location.search && location.search.includes('disposable') ? location.search.split('=')[1] : '';
  const accessoriesCategory = location.search && location.search.includes('category') ? decodeURI(location.search.split('=')[1]) : '';
  const [lensData, setLensData] = useState([]);
  if (Object.keys(shopByGender).includes(path)) {
    initialState = {
      type: [],
      shape: [],
      brand: [],
      material: [],
      price: {},
      gender: [shopByGender[path]],
      color: "",
      category: [],
      disposables: [],
      accessoriesCategory: [],
    };
  } else {
    initialState = {
      type: [],
      shape: [],
      brand: [],
      material: [],
      price: {},
      gender: [],
      color: "",
      category: [path],
      disposables: disposability ? [disposability] : [],
      accessoriesCategory: accessoriesCategory ? [accessoriesCategory] : [],
    };
  }
  const [filterLens, setFilterLens] = useState(initialState);
  const handleFrameType = (value) => {
    if (value) {
      setFilterLens((prevState) => ({ ...prevState, type: [value] }));
    } else {
      setFilterLens((prevState) => ({ ...prevState, type: [] }));
    }
  };

  const handleFrameShape = (value) => {
    if (value) {
      setFilterLens((prevState) => ({ ...prevState, shape: [value] }));
    } else {
      setFilterLens((prevState) => ({ ...prevState, shape: [] }));
    }
  };

  const handleGender = (value, check) => {
    if (check) {
      setFilterLens((prevState) => ({ ...prevState, gender: [value] }));
    } else {
      setFilterLens((prevState) => ({ ...prevState, gender: [] }));
    }
  };

  const handleBrand = (value, check) => {
    if (check) {
      setFilterLens((prevState) => ({ ...prevState, brand: [value] }));
    } else {
      setFilterLens((prevState) => ({ ...prevState, brand: [] }));
    }
  };

  const handleMaterial = (value, check) => {
    if (check) {
      setFilterLens((prevState) => ({ ...prevState, material: [value] }));
    } else {
      setFilterLens((prevState) => ({ ...prevState, material: [] }));
    }
  };

  const handleColor = (value, check) => {
    colorValues.push(value);
    if (check) {
      setColorArray((prevState) => ([ ...prevState, ...colorValues]));
    } else {
      const temp = colorArray.filter((val) => val !== value)
      setColorArray((prevState) => ([ ...prevState, ...temp]));
    }
  };

  const handlePrice = (value, check) => {
    if (check) {
      setFilterLens((prevState) => ({
        ...prevState,
        price: {
          minimum_price: value.split(",")[0],
          maximum_price: value.split(",")[1],
        },
      }));
    } else {
      setFilterLens((prevState) => ({ ...prevState, price: {} }));
    }
  };

  const handleDisposable = (value, check) => {
    if (check) {
      setFilterLens((prevState) => ({ ...prevState, disposables: [value] }));
    } else {
      setFilterLens((prevState) => ({ ...prevState, disposables: [] }));
    }
  };

  useEffect(() => {
    props.dispatch(filterProductStart(filterLens));
  }, [
    filterLens.type,
    filterLens.shape,
    filterLens.gender,
    filterLens.color,
    filterLens.price,
    filterLens.brand,
    filterLens.material,
    filterLens.disposables,
  ]);

  useEffect(() => {
    props.dispatch(
      fetchProductStart({
        category: path,
      })
    );
  }, []);

  useEffect(() => {
    if (!props.filterData.loading) {
      setLensData([...props.filterData.data]);
    } 
  }, [props.filterData.loading]);

  useEffect(() => {
    if (colorArray.length > 0) {
      const lenses = props.filterData.data.filter((val) => {
        const allColors = val.color.map(col => col.color);
        return colorArray.includes(allColors.join());
      });
      setLensData([...lenses]);
    }
  }, [colorArray]);
  return (
    <div className="product-listing page-pdding">
      {path === "Accessories" ? (
        ""
      ) : (
        <ProductFilter
          path={path}
          onSelectFrameType={handleFrameType}
          onSelectFrameShape={handleFrameShape}
          onSelectGender={handleGender}
          onSelectColor={handleColor}
          onSelectPrice={handlePrice}
          onSelectBrand={handleBrand}
          onSelectMaterial={handleMaterial}
          onSelectDisposable={handleDisposable}
        />
      )}
      <div
        className={
          path === "Accessories"
            ? "product-content-accessories"
            : "product-content"
        }
      >
        <div className="product-topbar">
          <h4>
            Home /{" "}
            {path === "Eyeglasses"
              ? "Eye Glasses"
              : path === "Sunglasses"
              ? "Sun Glasses"
              : path === "Readingglasses"
              ? "Reading Glasses"
              : path}{" "}
          </h4>
        </div>
        {!props.filterData.loading ? (
          <ProductList lens={lensData} />
        ) : (
          "loading..."
        )}
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  productData: state.products.productData,
  filterData: state.products.filterProduct,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(ProductListContainer);
