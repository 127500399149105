// all actions will be watched in this file means fork will be added.
import { all, fork } from "redux-saga/effects";

import UserSaga from './UserSaga';
import StoreLocatorSaga from './StoreLocatorSaga';
import HomeSaga from './HomeSaga';
import ProductSaga from './ProductSaga';
import CareerSaga from './CareerSaga';

export default function* rootSaga() {
    yield all([fork(UserSaga)]);
    yield all([fork(StoreLocatorSaga)]);
    yield all([fork(HomeSaga)]);
    yield all([fork(ProductSaga)]);
    yield all([fork(CareerSaga)]);
}